import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../hooks';
import IconButton from '../iconButton';

const HeaderUser = ({ title, imageURL, classNameArrow, classNameTitle, paddingLeft = false, showButtonback = false }: HeaderUserProps) => {
  // Hooks
  const { updateContext } = useAppContext();
  const navigate = useNavigate();

  // Methods
  const handleOpenSideBar = () => {
    updateContext(old => ({ ...old, showSideBarMobile: true }));
  };

  return (
    <div className='flex w-full mb-4 lg:mb-0'>
      <div className={`${classNameArrow && classNameArrow} lg:hidden`}>
        <IconButton name='arrow_back_ios' onClick={handleOpenSideBar} className='text-primary' shadow={false}/>
      </div>
      <div className={`${classNameTitle && classNameTitle} flex items-center gap-5`}>
        <div className='bg-primary rounded-full w-11 h-11 p-2 lg:hidden'>
          <img src={imageURL} className='w-full h-full'/>
        </div>
        <div className='lg:flex lg:justify-center lg:items-center lg:gap-4'>
          {showButtonback &&
            <IconButton
              name='arrow_back'
              onClick={() => navigate(-1)}
              size='3xl'
              className='hidden bg-primary rounded-full text-white md:block'
              sizeContainer='w-8 h-8'
            />
          }
          <h1 className={`text-primary text-left text-[25px] lg:text-[40px] ${paddingLeft ? paddingLeft : 'lg:ml-10'}`}>{title}</h1>
        </div>
      </div>
    </div>
  );
};

interface HeaderUserProps {
  title: string,
  imageURL: string,
  classNameArrow?: string
  classNameTitle?: string,
  paddingLeft?: boolean
  showButtonback?: boolean
}

export default HeaderUser;
