import { useEffect, useState } from 'react';
import { calculateWithDiscountSubscription, roundToXDigits, roundToXDigitsString } from '../../../helpers/productHelper';
import { shippingTypeForm } from '../../../interfaces/checkout';
import { editSubscriptionUserForm, subscriptionUserComplete } from '../../../interfaces/subscription';
import ProgressShippingFree from '../progressShippingFree';
import moment from 'moment';
import { shippingDiscountCoupon } from '../../../interfaces/payment';
import { workCities } from '../../../interfaces/store';

const ResumenSuscriptionSection = ({ form, setForm, subscription, valueDiscountShipping, workCitieData }: ResumenSuscriptionSectionProps) => {
  const [isChekedShipping, setIsChekedShipping] = useState(subscription.general_order.metadata.details_payments.deliveryPrice === 0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, { price: 0, type: 'gratis' }, form.subscriptions, form.coupon_detail, form.numberShipping, valueDiscountShipping));
  const [partialPrice, setPartialPrice] = useState(0);
  const [price, setPrice] = useState(form.transaction_amount);

  useEffect(() => {
    if (!isChekedShipping && form.minCostShipping !== 0)
      setIsChekedShipping((calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, { price: 0, type: 'gratis' }, form.subscriptions, form.coupon_detail, form.numberShipping, valueDiscountShipping)) > form.minCostShipping);
  }, [form.numberShipping, form.minCostShipping]);

  useEffect(() => {
    const priceNew = calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, { price: 0, type: 'gratis' }, form.subscriptions);
    setPriceWithDiscount(priceNew);
    const shippingType: shippingTypeForm = (isChekedShipping || (priceNew > form.minCostShipping)) ? { price: 0, type: 'gratis' } : { price: workCitieData ? Number(workCitieData.shipping_price) : 90, type: 'estandar' };
    /// TODO: Refactor
    // const shippingType: shippingTypeForm = (isChekedShipping || (priceNew > form.minCostShipping)) ? { price: 0, type: 'gratis' } : { price: 90, type: 'estandar' };
    setForm(old => ({ ...old,
      shippingType,
      details_payments: { ...old.details_payments, purchaseShippingRef: shippingType.price, totalPayment: price, purchasePaymentRef: priceNew },
    }));
  }, [form.products, form.subscriptions.percen_discount, form.numberShipping, form.minCostShipping, form.subscriptions, isChekedShipping, form.coupon_detail, price]);

  useEffect(() => {
    const price = calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, form.shippingType, form.subscriptions, form.coupon_detail, undefined, valueDiscountShipping);
    setPartialPrice(price);
    setForm(old => ({ ...old, details_payments: { ...old.details_payments, deliveryPrice: form.shippingType.price * form.numberShipping } }));
  }, [form.shippingType, form.numberShipping]);

  useEffect(() => {
    setForm(old => ({ ...old, transaction_amount: price }));
    setPrice(roundToXDigits(calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, form.shippingType, form.subscriptions, form.coupon_detail, form.numberShipping, valueDiscountShipping), 2));
  }, [form.numberShipping, partialPrice, form.shipping_frequencies_id, form.subscriptions_id, form.coupon_detail, price]);

  useEffect(() => {
    setIsChekedShipping(valueDiscountShipping.percentDiscount === 100);
  }, [valueDiscountShipping]);

  return (
    <div className={`bg-white fixed bottom-0 -left-[2px] rounded-[20px] flex flex-col pb-3 lg:py-6 md:static w-full z-100
      shadow-subcsription py-4
    `}>
      <div className='px-6 flex flex-col gap-7'>
        <ProgressShippingFree
          value={priceWithDiscount * form.numberShipping}
          valueMin={form.minCostShipping}
          isChekedShipping={isChekedShipping}
        />
        <div className='flex flex-col font-sanzSemiBold text-sm gap-6'>

          <ItemResumenSubscription title='Frecuencia de pago' text={`${form.subscriptions.title}`}/>
          <ItemResumenSubscription title='Frecuencia de entrega' text={`${form.shipping_frequencies.title} `}/>
          <ItemResumenSubscription title='Día de entrega' text={moment(form.dueDate).format('dddd')}/>
          <ItemResumenSubscription title='Fecha de renovación' text={moment(form.dueDate).format('YYYY-MM-DD')}/>
          {form.shippingType.price !== 0 &&
            <ItemResumenSubscription title='Envío por entrega' text={`$${form.shippingType.price}`}/>
          }
          <ItemResumenSubscription title='Subtotal' text={`${priceWithDiscount} `}/>
          <ItemResumenSubscription title='Total por entrega' text={`${partialPrice} `}/>
          <ItemResumenSubscription title='Total entregas' text={`${form.numberShipping} `}/>
          {form.couponId?.id &&
           <ItemResumenSubscription title='Descuento' text={`${form.couponId.amount} `}/>
          }
          {/* <RadioField
            changeState={() => setIsChekedShipping(!isChekedShipping) }
            currentState={isChekedShipping}
            handleRadioChange={() => {}}
            name='checkShippingFree'
            value={true}
            label='Envío gratis'
            iconCheckClassName='text-pixieLightBlue'
          /> */}
        </div>
        <div className='flex flex-col gap-1 font-titles text-base'>
          <div className='bg-[#D9EEEF] bg-opacity-80 py-3 px-5 flex justify-between text-pixieLightBlue rounded-[10px]'>
            <span>Total</span>
            <span>{roundToXDigitsString(price, 2)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ResumenSuscriptionSectionProps {
  form: editSubscriptionUserForm,
  setForm: React.Dispatch<React.SetStateAction<editSubscriptionUserForm>>,
  subscription: subscriptionUserComplete,
  valueDiscountShipping: shippingDiscountCoupon
  workCitieData?: workCities
}

const ItemResumenSubscription = ({ text, title }: ItemResumenSubscriptionProps) => (
  <div className='flex justify-between'>
    <span>{title}</span>
    <span>{text}</span>
  </div>
);

interface ItemResumenSubscriptionProps {
  title: string,
  text: string,
}

export default ResumenSuscriptionSection;
