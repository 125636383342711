import { useEffect, useRef } from 'react';

export function useOuterClick(callback: any) {
  const callbackRef = useRef<any>(); // Initialize mutable ref, which stores callback
  const innerRef = useRef<any>(); // Returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(e: any) {
      if (innerRef.current && callbackRef.current &&
        !innerRef.current.contains(e.target)
      ) callbackRef.current(e);
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []); // No dependencies -> stable click listener

  return innerRef; // Convenience for client (doesn't need to init ref himself)
}

export default useOuterClick;
