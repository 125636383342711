import { Product, ProductsFavoriteResponse } from '../../../interfaces/product';
import api from '../../axios';

export class FavoriteService {
  init() {}

  getFavoriteByUser = (): Promise<ProductsFavoriteResponse[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/favorites', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.favorites);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  saveFavorite = (form: Product): Promise<any> => {
    const token = window.localStorage.getItem('token');

    const productData = {
      product_id: form.id,
      product: {
        ...form
      }
    };

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-customers/api/favorites',
        headers: { Authorization: token ? token : '' },
        data: {
          ...productData
        }
      })
        .then(res => {
          resolve(res.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  deleteFavorite = (product: Product): Promise<any> => {
    const token = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api({ method: 'delete', url: `pixie-customers/api/favorites/${product.id}`,
        headers: { Authorization: token ? token : '' },
      }).then(res => {
        resolve(res.data);
      }).catch(error => {
        reject(error.response.data.message);
      }
      );
    });
  };
}

export default new FavoriteService();

