export const questions = {
  q1Header: 'Frequent questions',
  q1Description: 'Doubts? Check our frequently asked questions on Pixie. Useful information for an informed purchase.',
  q1Title: 'FAQ',
  q1Text: 'Does Pixie replaces completely a kibble-based diet?',
  q1Answer: `The answer is yes. Pixie has been developed under globally recognized nutritional standards,
   such as the National Research Council (NRC) and/or The Association of American Feed Control Officials (AAFCO).
   In addition, our team of veterinarians and zootechnicians have calibrated and certified, through laboratory tests,
   that our natural food has all the nutritional requirements that our pets need.`,

  q2Text: 'How many times a day should my dog eat?',
  q2AnswerFrag1: 'Adult:',
  q2AnswerFrag2: 'At least 2 times a day.',
  q2AnswerFrag3: 'Puppy:',
  q2AnswerFrag4: 'Between 3-4 times a day.',
  q2AnswerFrag5: 'We invite you to use our',
  q2AnswerFrag6: 'portion calculator',
  q2AnswerFrag7: 'If your dog suffers of gastritis please contact us',

  // eslint-disable-next-line quotes
  q3Text: "Does my pet's food vary according to age? ",
  q3AnswerFrag1: `Of course, each physiological stage of our pets (puppy, adult, senior) requires different nutritional
  characteristics. For this reason, Pixie offers you a variety of options for each of its stages.`,
  q3AnswerFrag2: 'We invite you to use our',
  q3AnswerFrag3: 'portion calculator',

  q4Text: 'Can I leave Pixie served on the plate all day?',
  q4Answer: `As Pixie is free from preservaties, once the product is opened, it must be consumed in the shortest possible time. For this reason,
  we recommend that you freeze what you will use in the following days and refrigerate what you
  will use during the day.`,

  q5Text: 'Does Pixie have medicated options?',
  q5AnswerFrag1: 'Our recipes are not medicated, however we do have recipes that help support the following medical conditions:',
  q5AnswerFrag2: 'Liver diseases ',
  q5AnswerFrag3: 'Obesity',
  q5AnswerFrag4: 'Allergies',
  q5AnswerFrag5: 'Sensitive stomach',
  q5AnswerFrag6: 'If your dog suffers one of this medical conditions please contact us',

  q6Text: 'Why is PIXIE better than kibble?',
  q6Answer: `Pet food should be food, the key to our pets' well being is to feed them with real food.
  It is a food made with fresh and natural ingredients, we do not use any type of preservatives, coloring or flavoring;
  its palatability is very high since it provides a delicious flavor. And what better than feeding our pets with a food
  created anatomically and physiologically ready to eat?`,

  q7Text: 'How do I thaw and store PIXIE?',
  q7AnswerFrag1: 'Frozen::',
  q7AnswerFrag2: '6 months',
  q7AnswerFrag3: 'Refrigerated:',
  q7AnswerFrag4: '7 days',
  q7AnswerFrag5: `Once opened, it should be stored in an airtight container and consumed as soon as possible.
  Ideally, you keep the product frozen and go to the refrigerator as you need it. Although the
  product is designed to be kept refrigerated (Max. 39.2 ◦F), the home refrigerator usually opens a
  lot, preventing that temperature from being maintained. Therefore, freeze it and keep
  refrigerated only the day of use.`,

  q8Text: 'Why should I feed my Pet with a Pixie Recipe?',
  q8AnswerFrag1: '',
  q8AnswerFrag2: `Because it not only contains
  the right balance of vitamins and nutrients to support your pet´s wellbeing but
  the benefits of feeding your loved one with fresh and human grade ingredients
  are immense. By Feeding your dog or cat with Pixie, you will see a healthy and long-lived
  pet with a`,
  q8AnswerFrag3: 'shiny coat',
  q8AnswerFrag4: 'a',
  q8AnswerFrag5: 'better digestion',
  q8AnswerFrag6: 'and metabolism of food,',
  q8AnswerFrag7: 'better gastrointestinal transit',
  q8AnswerFrag8: 'and',
  q8AnswerFrag9: 'greater muscle development',
  q8AnswerFrag10: 'with less body fat and a better attitude when eating. Pixie helps',
  q8AnswerFrag11: 'strengthen the immune system,',
  q8AnswerFrag12: 'detoxifying its biological system and reducing the number of stools, thus giving your',
  q8AnswerFrag13: 'loved one the life deserved',

  q9Text: 'Is Pixie BARF?',
  q9AnswerFrag1:
    'No, Pixie is not BARF.  B.A.R.F is an acronym that stands for: Biologically Appropriate Raw Food.',
  q9AnswerFrag2: `Pixie is a fresh natural baked food: IT IS NOT BARF!
  At Pixie we only use lean cuts for our recipes, while  the industrial options of BARF available uses waste from the meat
  industry and grind up bones in the preparation of their diets. BARF makes its products based on the idea that dogs are strict
  carnivores, which is why they go overboard with non-digestible protein, which in the long term causes liver and/or kidney
  problems.`,

  q10Text: 'Is it necessary to make a transition when starting Pixie?',
  q10AnswerFrag1: `We do recommend making the transition from the food you currently consume to Pixie, to avoid digestive
   problems due to the change of food. Ideally, during the changeover period, we recommend you only provide one of our recipes,
  then you can alternate the recipes without a problem.`,
  q10AnswerFrag2: 'For the transition there are two options, you can choose the one you consider best:',
  q10AnswerFrag3: `The first one which is specially recommended for pets with sensitive stomachs, is to make a preparation
  of rice and the protein of animal origin that you choose, being 50% protein and 50% rice, all cooked only with water.
  Taking into account the ration suggested in our calculator and providing it for 3 days. This is a detox diet that allows
  the digestive system to adapt.`,
  q10AnswerFrag4:
    'The second way is to decrease the ration of the kibble and to include Pixie proportionally as shown below:',
  q10AnswerFrag5: 'Day 1-2: 75% current food and 25% of the ration suggested by Pixie.',
  q10AnswerFrag6: 'Day 3-4: 50% current food and 50% Pixie.',
  q10AnswerFrag7: 'Day 5-6: 25% current food and 75% Pixie.',
  q10AnswerFrag8: 'Day 7: You can totally start with Pixie now.',

  q11Text: 'Are the snacks also for kittens?',
  q11AnswerFrag1: `Of course! Although on the packaging it is indicated for dogs due to its ingredients and nutritional
  composition, you can also give it to kittens without any problem.`,
  q11AnswerFrag2: 'Note: Remember to validate the size of the snack and cut them into smaller portions',

  q12Text: 'Can I alternate the recipes?',
  q12AnswerFrag1: `Yes you can alternate them and thus always maintain their interest at meal time. However, we do
  not recommend mixing two different recipes in the same meal.`,
  q12AnswerFrag2: 'If your furry one is sensitive to the stomach, contact us to give you personalized advice',
};
