import { cdmxCities, cmx002Codes, cmx003Codes, cmx004Codes, cmx005Codes, cmx006Codes, cmx007Codes, cmx008Codes, cmx009Codes, cmx010Codes, cmx011Codes, cmx012Codes, cmx013Codes, cmx014Codes, cmx015Codes, cmx016Codes, cmx017Codes, EDMX001codes, EDMX002codes, EDMX003codes, EDMX004codes, EDMX005codes, EDMXCities, quepostalCode, queretaroCities } from '../@fake/statesFake';
import { SelectItem } from '../components/form/selectField';
import { addressObject } from '../interfaces/checkout';
import { editUserSubscription, orderEditSubscription } from '../interfaces/subscription';

export const getCities = (value: string): SelectItem[] => {
  switch (value) {
    case 'MX-QUE':
      return queretaroCities;
    case 'MX-CMX':
      return cdmxCities;
    case 'MX-EDMX':
      return EDMXCities;
    default:
      return [];
  }
};

/// export const getPostalCode = (valueCity: string): SelectItem[] => {
//   // Quereataro
//   if (valueCity.includes('MX-QUE'))
//     return quepostalCode;
//   if (valueCity.includes('MX-EDMX'))
//     return getEDMXCode(valueCity);

//   switch (valueCity) {
//     // Ciudad de mexico
//     case 'MX-CMX-002':
//       return cmx002Codes;
//     case 'MX-CMX-003':
//       return cmx003Codes;
//     case 'MX-CMX-004':
//       return cmx004Codes;
//     case 'MX-CMX-005':
//       return cmx005Codes;
//     case 'MX-CMX-006':
//       return cmx006Codes;
//     case 'MX-CMX-007':
//       return cmx007Codes;
//     case 'MX-CMX-008':
//       return cmx008Codes;
//     case 'MX-CMX-009':
//       return cmx009Codes;
//     case 'MX-CMX-010':
//       return cmx010Codes;
//     case 'MX-CMX-011':
//       return cmx011Codes;
//     case 'MX-CMX-012':
//       return cmx012Codes;
//     case 'MX-CMX-013':
//       return cmx013Codes;
//     case 'MX-CMX-014':
//       return cmx014Codes;
//     case 'MX-CMX-015':
//       return cmx015Codes;
//     case 'MX-CMX-016':
//       return cmx016Codes;
//     case 'MX-CMX-017':
//       return cmx017Codes;
//     default:
//       return [];
//   }
// };

export const getPostalCode = (valueCity: string): SelectItem[] => {
  // Quereataro
  if (valueCity === '4')
    return quepostalCode;

  return [{ value: '', label: '' }];
};

/// const getEDMXCode = (valueCity: string): SelectItem[] => {
//   switch (valueCity) {
//     case 'MX-EDMX-001':
//       return EDMX001codes;
//     case 'MX-EDMX-002':
//       return EDMX002codes;
//     case 'MX-EDMX-003':
//       return EDMX003codes;
//     case 'MX-EDMX-004':
//       return EDMX004codes;
//     case 'MX-EDMX-005':
//       return EDMX005codes;
//     default:
//       return [];
//   }
// };

// Organice address to send JSONObject with all information
export const organiceAddress = (editData: orderEditSubscription | editUserSubscription): string => {
  const address: addressObject = {
    address: editData.address,
    houseNumber: editData.houseNumber,
    apartament: editData.apartament,
    reference: editData.reference,
    zipCode: editData.zipCode.value,
    colony: editData.colony,
  };

  return JSON.stringify(address);
};
