import { useEffect, useState, useRef } from 'react';
import { car } from '../../../assets/json';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { confeti } from '../../../assets/gifts';
import { roundToXDigitsString } from '../../../helpers/productHelper';

const ProgressShippingFree = ({ value, valueMin, isChekedShipping = false }: ProgressShippingFreeProps) => {
  const [porcent, setPorcent] = useState(0);
  const [minValue, setMinValue] = useState(isChekedShipping ? 0 : valueMin);
  const refAnimation = useRef<LottieRefCurrentProps | null>(null);
  refAnimation.current?.setSpeed(0.3);

  useEffect(() => {
    setMinValue(valueMin);
  }, [valueMin]);

  useEffect(() => {
    refAnimation.current?.setDirection(1);
    setPorcent((value * 100) / minValue);
    handleChangeDirection();
  }, [value, minValue]);

  useEffect(() => {
    const withPorcent = document.getElementById('withShipping');
    if (withPorcent)
      withPorcent.style.width = `${porcent > 100 ? 100 : porcent}%`;
  }, [porcent]);

  useEffect(() => {
    if (isChekedShipping)
      setMinValue(0);
    else
      setMinValue(valueMin);
  }, [isChekedShipping, valueMin]);

  const handleChangeDirection = () => {
    refAnimation.current?.stop();
    refAnimation.current?.play();
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='relative'>
        <div className='bg-grayText bg-opacity-40 h-2 absolute rounded-[3px] w-full'></div>
        {value !== 0 &&
          <div id='withShipping' className='animation-progress-transition'>
            <div className={`h-2 animation-progress rounded-[3px] relative ${value >= minValue ? 'bg-pixieLightBlue' : 'bg-primary'}`}>
              <div className='-right-12 absolute -top-[1.4rem] animation-car-shipping z-20'>
                { value >= minValue
                  ?
                  <div className='absolute right-[1.9rem] w-14 h-14'>
                    <img src={confeti} className='w-14 h-14'/>
                  </div>
                  :
                  <div className='w-20 h-20'>
                    <Lottie
                      animationData={car}
                      loop={false}
                      lottieRef={refAnimation}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
      <div className='flex text-sm text-primary w-full justify-between font-titles'>
        {value < minValue ?
          <span className='text-xs '>¡Te faltan ${roundToXDigitsString(minValue - value, 2)} para completar el envío gratis!</span>
          :
          <span className='text-xs text-pixieLightBlue'>¡Bien hecho! Ahora tu envío será gratis</span>
        }
        <span className={`w-1/2 text-right ${value >= minValue && 'text-pixieLightBlue'}`}>${roundToXDigitsString(valueMin, 2)}</span>
      </div>
    </div>
  );
};

interface ProgressShippingFreeProps {
  value: number,
  valueMin: number,
  isChekedShipping?: boolean
}
export default ProgressShippingFree;

