import { useEffect, useState } from 'react';

import Button from '../../../components/common/button';
/// import MyTestimonials from './myTestimonials';
import CreateTestimonials from './createTestimonial';

import orderService from '../../../services/userService/orderService';
import { Testimonial } from '../../../interfaces/user/orders';
import PageUser from '../../../components/layout/pageUser';
import HeaderUser from '../../../components/common/headerUser';
import { rateReview } from '../../../assets/vectors';
import { useTranslation } from 'react-i18next';
import MyTestimonials from './myTestimonials';
import { useHastParams } from '../../../hooks';

const UserTestimonials = () => {
  const { t } = useTranslation();
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [showCreateTestimonials, setShowCreateTestimonials] = useState(false);
  const [showCreateTestimonialsMobil, setShowCreateTestimonialsMobil] = useState(false);
  const { hash } = useHastParams();

  useEffect(() => {
    getUserTestimonials();
  }, []);

  useEffect(() => {
    if (hash && 'testimonio' in hash) setShowCreateTestimonials(true);
  }, [hash]);

  const getUserTestimonials = () => {
    orderService.getTestimonialsByUser().then(res => {
      if (res.length === 0)
        return setShowCreateTestimonials(true);

      setTestimonials(res);
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <PageUser
      className='bg-[#D9EEEF] lg:bg-sixth'
      classNameChildren='relative flex flex-col justify-center items-center pt-5 relative lg:pr-[5rem] lg:pl-[6rem] lg:bg-sixth'
    >
      <div className='flex justify-between w-full'>
        <HeaderUser
          imageURL={rateReview}
          title={t('userMenuTestimonials')}
          classNameArrow='w-1/6'
          classNameTitle='w-5/6'
          paddingLeft={true}
        />
        <div className='hidden w-full lg:flex justify-end py-2'>
          <Button
            className='font-sanzBold text-white bg-primary text-lg'
            padding='px-10'
            onClick={() => setShowCreateTestimonials(true)}
          >
            Nueva reseña
          </Button>
        </div>
      </div>
      <div className='bg-sixth lg:bg-transparent flex rounded-t-[20px] flex-col items-start w-full font-subTitles gap-10 py-14'>
        <div className={`flex justify-center items-center w-full lg:hidden ${showCreateTestimonialsMobil ? 'hidden' : ''}`}>
          <Button
            className='font-sanzBold text-white bg-primary text-lg'
            padding='px-10 py-2'
            onClick={() => setShowCreateTestimonialsMobil(true)}
          >
            Nueva reseña
          </Button>
        </div>
        <div className={`${showCreateTestimonialsMobil ? 'hidden' : ''} px-10 lg:px-0`}>
          <MyTestimonials
            testimonials={testimonials}
          />
        </div>
        {showCreateTestimonials &&
          <CreateTestimonials
            goToBack={() => setShowCreateTestimonials(false)}
            reloadData={getUserTestimonials}
          />
        }
        {showCreateTestimonialsMobil &&
          <CreateTestimonials
            goToBack={() => setShowCreateTestimonialsMobil(false)}
            reloadData={getUserTestimonials}
            type='mobil'
          />
        }
      </div>
    </PageUser>
  );
};

export default UserTestimonials;
