export const checkSubmissionForm = {
  checkSubmissionTitleForm: 'Información de contacto',
  checkSubmissionEmail: 'Correo electrónico*',
  checkSubmissionPhone: 'Celular (10 dígitos)',
  checkSubmissionNew: 'Enviarme novedades y ofertas por correo electrónico',
  checkSubmissionDeliveryTitle: 'Direccion de envío',
  checkSubmissionFirstName: 'Nombre*',
  checkSubmissionLastName: 'Apellido*',
  checkSubmissionStreetName: 'Nombre de la calle*',
  checkSubmissionExteriorNumber: 'Número exterior, Casa o Edificio*',
  checkSubmissioninteriorNumber: 'Número Interior ( Ej: Piso, Oficina, Dpto)',
  checkSubmissionColony: 'Colonia*',
  checkSubmissionCountry: 'País*',
  checkSubmissionAddressSaved: 'Direcciones guardadas',
  checkSubmissionState: 'Estado*',
  checkSubmissionCity: 'Ciudad*',
  checkSubmissionBetweenStreets: 'Entre calles (Referencias)',
  checkSubmissionDelegation: 'Delegación / Municipio*',
  checkSubmissionZip: 'Código postal*',
  checkSubmissionSaveinformation: 'Guardar mi información y consultar más rápidamente la próxima vez',
  checkSubmissionTermsAndConditionPart1: 'Confirmo que he leído, entiendo y acepto los ',
  checkSubmissionTermsAndConditionPart2: 'términos y condiciones',
  checkSubmissionTermsAndConditionPart3: ' y la ',
  checkSubmissionTermsAndConditionPart4: 'política de manejo de datos*',
  checkSubmissionBack: 'Volver a la canasta',
  checkSubmissionContinue: 'Seguir con envios',
  checkSubmissionsShowResumePart1: 'Mostrar resumen del ',
  checkSubmissionsShowResumePart2: 'pedido',
  checkConstancyBusinessName: 'Nombre o razón social',
  checkConstancyRfc: 'RFC',
  checkConstancyRfcTooltip: 'Registro Federal de Contribuyentes (limite de 700kb)',
  checkConstancyPDF: 'Cargar la constancia de situación fiscal (Opcional)',
  checkConstancyPostalCode: 'Código postal ',
  checkConstancyRegimen: 'Régimen'
};
