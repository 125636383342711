import TableDatesSubscription from './tableSection';

import Button from '../../../../../components/common/button';
import SelectField from '../../../../../components/form/selectField';
import { subscriptionUserComplete } from '../../../../../interfaces/subscription';

const EditDatesSubscriptionSection = ({ subscription, closePopup }: EditDatesSubscriptionSectionProps) => {
  const closeAll = () => {
    closePopup();
  };

  return (
    <div
      className={`flex flex-col max-h-[80vh] lg:w-[43rem] lg2:w-[52rem] xl2:w-[58rem] overflow-y-auto scroll-blue py-4 px-6
      relative text-sm gap-6 bg-white rounded-b-[20px]`}
    >

      <div className='flex justify-between'>
        <div className='flex gap-3'>
          <SelectField
            name='filter'
            onChange={() => {}}
            options= {[]}
            placeholder='Filtrar por'
            borderRadius={true}
            backgroundColor='#D9EEEF'
            dropdownIndicatorColor='#33B5A9'
          />
          {/* <SearchCreate
            createFunction={() => {}}
            placeholderSearch='Buscar por número, fecha, mes'
            showCreate={false}
          /> */}
        </div>
      </div>

      <TableDatesSubscription
        subscription={subscription}
        showEdit={true}

      />
      <div className='flex justify-end gap-2 text-base mt-8 font-sanzSemiBold h-[31px]'>
        <Button
          className='bg-primary text-[#fad7b1]'
          padding='px-7 py-1'
          onClick={closeAll}
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
};

interface EditDatesSubscriptionSectionProps {
  subscription: subscriptionUserComplete,
  closePopup: () => void
}

export default EditDatesSubscriptionSection;
