import moment from 'moment';
import { useEffect, useState } from 'react';

import Button from '../../../../../components/common/button';
import WarningSubscription from '../../../../../components/common/warningSubscription';
import PopupDecisionUser from '../../../../../components/layout/popupDecisionUser';
import LeftSectionEditSubscripcion from './leftSectionEditSubscripcion';
import RightSectionEditSubscripcion from './rightSectionEditSubscripcion';

import { useAppContext, useForm } from '../../../../../hooks';
import { editSubscriptionUserForm, subscriptionUserComplete } from '../../../../../interfaces/subscription';
import subscriptionService from '../../../../../services/subscriptionService';
import storeService from '../../../../../services/storeService';
import { workCities } from '../../../../../interfaces/store';

const EditSubscriptionData = ({ subscription, setClosePopup }: EditSubscriptionDataProps) => {
  const { toast, updateContext } = useAppContext();
  const [showConfirmChange, setShowConfirmChange] = useState(false);
  const [workCitieData, setWorkCitieData] = useState<workCities>();
  const { form, setForm, onSubmit, handleRadioChange } = useForm<editSubscriptionUserForm>({
    details_payments: {
      ...subscription.general_order.metadata.details_payments,
    },
    dueDate: moment(subscription.due_date).format('YYYY-MM-DD'),
    dueDateRef: moment(subscription.due_date).format('YYYY-MM-DD'),
    products: subscription.general_order.metadata.products,
    shipping_frequencies_id: subscription.shipping_frequencies_id,
    status: subscription.status,
    subscriptions_id: subscription.subscriptions_id,
    transaction_amount: subscription.transaction_amount,
    coupon_detail: subscription.coupon_detail,
    couponId: subscription.general_order.metadata.couponId?.id ? subscription.general_order.metadata.couponId :
      {
        amount: 0,
        code: '',
        id: '',
        internalCouponName: '',
        internalCouponNumber: '',
      },
    shipping_frequencies: subscription.shipping_frequencies,
    subscriptions: subscription.subscriptions,
    shippingType: { price: 0, type: 'gratis' },
    couponsSelect: subscription.coupon_detail ? [subscription.coupon_detail.id] : [],
    dayOrder: '',
    minCostShipping: 0,
    minCostShippingRef: 0,
    numberShipping: subscription.general_order.metadata.details_payments.numberShipping ? subscription.general_order.metadata.details_payments.numberShipping : 1,
    first_shipping_day: `${moment().format('YYYY')}-${moment().format('MM')}-${moment().format('DD')}`
  }, () => showPopupConfirm());

  useEffect(() => getPreData(), []);

  useEffect(() => {
    if (form.first_shipping_day)
      getDatesShipping();
  }, [form.shipping_frequencies_id, form.subscriptions_id]);

  const getPreData = () => {
    subscriptionService.getordersBySubscription(subscription.id)
      .then(res => {
        const date = moment(res[0].delivery_date);
        setForm(old => ({ ...old, dayOrder: date.format('dddd') }));
      })
      .catch(error => {
        console.log(error);
      });

    subscriptionService.getMinShippingPrice('mex')
      .then(res => {
        if (subscription)
          setForm(old => ({ ...old, minCostShipping: res.price, minCostShippingRef: res.price }));
      })
      .catch();

    storeService.getOneWorkCity(subscription.general_order.city_id).then(res => {
      setWorkCitieData(res);
    }).catch(error => {
      console.log(error);
    });
  };

  const getDatesShipping = () => {
    subscriptionService.getDatesShippingSubscription(form)
      .then(res => {
        setForm(old => ({ ...old, numberShipping: res, minCostShipping: old.minCostShippingRef * res }));
      });
  };

  const showPopupConfirm = () => {
    setShowConfirmChange(true);
  };

  const handleSubmit = () => {
    subscriptionService.editSubscriptionData(subscription.id, form, subscription)
      .then(res => {
        toast.fire({
          icon: 'success',
          title: 'Suscripción editada correctamente.',
        });
        updateContext(old => ({ ...old, reloadData: true }));
        setShowConfirmChange(false);
      }).catch(error => {
        console.log(error);
      });
  };

  const closeAll = () => {
    setClosePopup();
  };

  const handleClosePopup = () => {
    setShowConfirmChange(false);
  };

  return (
    <form className={`flex flex-col max-h-[80vh] lg:w-[43rem] lg2:w-[52rem] xl2:w-[58rem]
    relative gap-6`}
    onSubmit={onSubmit}
    >
      <div className='flex flex-col bg-white py-6 px-6'>
        <WarningSubscription/>
        <div className='flex gap-16'>
          <div className='w-1/2'>
            <LeftSectionEditSubscripcion
              form={form}
              handleRadioChange={handleRadioChange}
              setForm={setForm}
              subscription={subscription}
            />
          </div>
          <div className='w-1/2'>
            <RightSectionEditSubscripcion
              form={form}
              setForm={setForm}
              subscription={subscription}
              workCitieData={workCitieData}
            />
          </div>
        </div>
      </div>
      {showConfirmChange &&
        <PopupDecisionUser
          handleAccept={handleSubmit}
          handleDeny={handleClosePopup}
          textAccept='Cambiar'
          textDeny='Cancelar'
          type='question'
          titleHTML={
            <div className='flex flex-col'>
              <p>¿Estás seguro que quieres cambiar los datos de pedido?</p>
              <p>El cambio se hará efectivo en tu próximo débito</p>
            </div>
          }
        />
      }

      <div className='flex justify-end gap-2 text-base mt-8 font-sanzSemiBold h-[31px]'>
        <Button className='border border-primary text-primary' padding='px-8 py-1' onClick={closeAll}>Cancelar</Button>
        <Button className='bg-primary text-[#fad7b1]' padding='px-7 py-1' type='submit'>Guardar</Button>
      </div>
    </form>
  );
};

interface EditSubscriptionDataProps {
  subscription: subscriptionUserComplete,
  setClosePopup: () => void
}

export default EditSubscriptionData;
