import { useCallback, useEffect } from 'react';
import { backgroundUserDashboard } from '../../../assets/images';
import { useAppContext } from '../../../hooks';

import NavBar from '../../layout/navBar';
import Loading from '../loading';
import SideBar from '../sidebar';
import useScrolled from '../../../hooks/useScrolled';

export const PageUser = ({ className, children, color, addPadding = true, classNameChildren, showBackgroundImage = true }: PageProps) => {
  // Hooks
  const { updateContext, showNavbar, showLoading, showSideBarMobile, isMobile } = useAppContext();

  const minimalNavbar = useCallback(() => {
    /// if (screen.width < 800) return 20;
    if (window.innerWidth < 800) return 20;

    return 20;
  }, [screen]);

  const changeResize = () => {
    if (window.innerWidth < 800)
      updateContext(old => ({ ...old, showMinimalNavbar: false, isMobile: true }));
    else
      updateContext(old => ({ ...old, showMinimalNavbar: true, isMobile: false }));
  };

  useEffect(() => {
    changeResize();
    window.addEventListener('resize', changeResize);
  }, []);

  useEffect(() => {
    changeResize();
    window.addEventListener('resize', changeResize);

    return () => {
      window.removeEventListener('resize', changeResize);
    };
  }, []);

  const {
    scrolledData: { isDown },
  } = useScrolled({
    gap: minimalNavbar(),
    callback: () =>
      isDown
        ? isMobile ? updateContext(old => ({ ...old, showMinimalNavbar: true })) : updateContext(old => ({ ...old, showMinimalNavbar: true }))
        : updateContext(old => ({ ...old, showMinimalNavbar: true })),
  });

  return (
    <>
      <div style={color ? { backgroundColor: color } : {}} className={`${className} absolute h-screen w-screen -z-10`} />
      <section
        className={`
        flex flex-col items-center justify-center 
        flex-grow relative w-full
        animate__animated animate__fadeIn animate__fast
        ${showNavbar && `pt-[5.5rem] ${addPadding ? 'md:pt-[4.5rem]' : 'md:pt-0'}`}
      `}
      >
        <div className='w-full z-[1001]'>
          <NavBar backgroundColor='lg:bg-white' />
        </div>
        <div className='w-full flex'>
          {/* sidebar mobile */}
          {showSideBarMobile &&
            <div className='w-full'>
              <SideBar/>
            </div>
          }
          {/* SideBar desktop */}
          <div className='hidden w-full lg:block lg:w-1/4'>
            <SideBar className='fixed h-full w-1/4 overflow-y-auto scroll-gray'/>
          </div>

          <div className={`w-full lg:w-3/4 lg:pl-11 lg:pr-11 z-10
            ${showSideBarMobile && 'hidden'}
            ${classNameChildren && classNameChildren}
          `} >
            {showBackgroundImage &&
            <img src={backgroundUserDashboard} className='absolute top-10 -z-10 hidden lg:block'/>
            }
            {showLoading && <Loading />}
            <>{children}</>
          </div>
        </div>
      </section>
    </>
  );
};

interface PageProps {
  className?: string;
  children: React.ReactNode;
  color?: string;
  addPadding?: boolean;
  classNameChildren?: string,
  showBackgroundImage?: boolean
}

export default PageUser;
