export const coupon = {
  couponCodePlaceholder: 'Enter a coupon code or referral code',
  couponApply: 'Apply',
  couponRemove: 'Remove',
  couponError1: '* It is not a valid promo code',
  couponError2: '* This code has already been redeemed',
  couponError3: '* This code is no longer available',
  couponError4: '* You must register to validate the coupon',
  couponError5: '* You must enter an email',
  couponError6: '* The user exceeded the uses of this coupon',
  couponError7: '* You have used this referral code',
  couponError8: '* You cannot use the same referral code',
  couponError9: '* The code is for subscription only',
  couponError10: '* The code is for normal purchase',
};
