import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../hooks';

const ItemSideBar = ({ text, imageURL, textClassName, isActive = false, navigateUrl, ClassNameContaninerImg }: ItemSideBarProps) => {
  const navigate = useNavigate();
  const { showSideBarMobile, updateContext } = useAppContext();

  const handleChangeUrl = () => {
    if (showSideBarMobile)
      updateContext(old => ({ ...old, showSideBarMobile: false }));
    navigate(navigateUrl);
  };

  return (
    <div className='bg-white rounded-[20px] py-5 w-full flex flex-col justify-center items-center gap-3 lg:bg-transparent lg:flex-row lg:justify-start lg:gap-6 lg:py-0' onClick={handleChangeUrl}>
      <div className={`rounded-full w-14 h-14 p-2 lg:w-10 lg:h-10 lg:p-1 ${isActive ? 'bg-primary' : 'bg-pixieLightBlue'} ${ClassNameContaninerImg && ClassNameContaninerImg}`}>
        <img src={imageURL} className='w-full h-full'/>
      </div>
      <span className={`text-lg  text-center lg:text-left cursor-pointer
        ${textClassName && textClassName}
        ${isActive ? 'text-primary' : 'text-pixieLightBlue'}
        `}
      >
        {text}
      </span>
    </div>
  );
};

interface ItemSideBarProps {
  text: string,
  imageURL: string,
  textClassName?: string,
  ClassNameContaninerImg?: string,
  isActive?: boolean
  navigateUrl: string
}

export default ItemSideBar;
