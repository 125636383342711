export const payment = {
  paymentBillingTitle: 'Facturación',
  paymentBillingSame: 'No deseo factura',
  paymentBillingDifferent: 'Facturar a mi nombre',
  paymentBillingBack: 'Volver a envíos',
  paymentBillingPayment: 'Pagar',
  paymentRResultSuccess: '¡Tu pago ha sido exitoso!',
  paymentRResultFailed: '¡Ups! Tu pago ha sido rechazado',
  paymentRReturnHome: 'Volver al inicio',
  paymentRReturnBaket: 'Volver a la canasta',
  paymentRPaymentInformation: 'Datos de la transacción',
  paymentRPaymentValue: 'VALOR',
  paymentRDate: 'FECHA Y HORA DE PAGO',
  paymentRNumTrans: 'NÚMERO DE TRANSACCIÓN',
};
