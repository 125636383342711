/* eslint-disable curly */
import React, { useState, useRef } from 'react';
import Icon from '../icon';
import { uploadBox } from '../../../assets/vectors';
import IconButton from '../iconButton';
import { useAppContext } from '../../../hooks';

function DragAndDrop({ setDataFile, selectedFile }: DragAndDropProps) {
  const [dragging, setDragging] = useState(false);
  /// const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { toast } = useAppContext();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setDataFile(file);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.type.includes('video')) {
        if (file.size > 25000000) {
          toast.fire({
            icon: 'warning',
            title: 'El maximo tamaño de un video es de 25 MB',
          });
          handleFileDelete();
          return;
        }
      } else if (file.size > 5000000) {
        toast.fire({
          icon: 'warning',
          title: 'El maximo tamaño de una imagen es de 5 MB',
        });
        handleFileDelete();
        return;
      }

      setDataFile(file);
    }
  };

  const handleFileDelete = () => {
    setDataFile(null);
    if (inputRef.current)
      inputRef.current.value = '';
  };

  return (
    <div className='flex flex-col gap-4'>
      <div
        className={`border border-pixieLightBlue rounded-[20px] py-7 cursor-pointer ${dragging ? 'bg-[#D9EEEF]' : 'bg-[#D9EEEF]'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        <div className='flex flex-col justify-center items-center'>
          <div className={`mb-4 ${dragging ? 'text-gray-600' : 'text-grayText'}`}>
            <div className='flex flex-col gap-2 justify-center items-center'>
              <img src={uploadBox} />
              <span className='text-pixieLightBlue font-sanzBold'>Adjunta fotos o un video</span>
              {dragging ?
                <span>
                  Suelta tu documento aquí
                </span> :
                <span>
                  Muéstranos a tu peludito disfrutando de su Pixie
                </span>
              }
            </div>
          </div>
          <input
            type='file'
            className='hidden'
            ref={inputRef}
            accept='image/png, image/jpeg, video/*'
            onChange={handleFileSelect}
          />
        </div>
      </div>

      {selectedFile && (
        <div className='text-sm flex items-center'>
          <div className='flex-1 truncate'>
            <div className='flex items-center gap-4 text-pixieLightBlue font-sanzSemiBold'>
              <span>
                <Icon
                  name='attach_file'
                  className='text-grayText rotate-45'
                />
              </span>
              {selectedFile.name}
            </div>
          </div>
          <IconButton
            name='delete'
            onClick={handleFileDelete}
            shadow={false}
            color='#7AC5BE'
            sizeContainer='w-6 h-6 lg:w-9 lg:h-9'
            className='text-white'
            size='sm'
            classNameIcon='lg:text-xl'
          />
        </div>
      )}
    </div>
  );
}

interface DragAndDropProps {
  setDataFile: (file: File | null) => void;
  selectedFile: File | null
}

export default DragAndDrop;
