export const home = {
  homeHeader: 'Wet pet Food for Dogs and Cats',
  homeDescription: 'Pixie - Wet Pet Food for Cats and Dogs: Discover a wide variety of 100% natural wet pet food options. Complete nutrition for your beloved companions.',
  homeCanonical: 'https://us.pixie.pet/',
  // Home - Cards
  homeBannerText1: 'real',
  homeBannerText2: 'food',
  homeBannerText3: 'for real',
  homeBannerText4: 'love',
  homeBannerText5: 'Natural everyday food',
  homeCardsTitle: 'Your 4-legged friend should eat as healthy as the rest of the family',

  card1Title: 'Directly to your home:',
  card1Descr: 'We will ship fresh food directly to your door',

  card2Title: 'Better and less poop:',
  card2Descr: 'Given pixies’ high digestibility, dogs and cats will absorb more nutrients resulting in less poop',

  card3Title: 'Real baked food:',
  card3Descr:
    'We are committed to the well-being of our pets so we ensure that only the best human-grade ingredients are used in Pixie',

  card4Title: 'Developed by veterinary nutritionists:',
  card4Descr: 'Without preservatives and with the necessary nutrients for a balanced and healthy daily diet',

  card5Title: 'Variety of products:',
  card5Descr: 'You can choose a Pixie acording to your pet’s age and physical condition',

  // Home - Products
  homeProductsTitle: 'Our products',
  homeCatalogueButton: 'See the store',

  // Home - Calculator
  homeCalcFrag1: 'Which is the ideal pixie',
  homeCalcFrag2: 'for my dog or',
  homeCalcFrag3: 'cat?',
  homeCalcButton: 'Discover it here',
  loading: 'Loading',
  deny: 'Cancel',
  update: 'Update',
};
