export const products = {
  productsSoonButton: 'Soon',
  productsAddButton: 'Add to cart',
  productsCalcText: 'How much should I feed my dog?',
  productsCalcButton: 'Feeding Calculator',
  productsNutrTitle: 'Ingredients & Nutrition',
  productsInfoTitle: 'Benefits and Characteristics',
  productsInfoTable: 'Consumption table by daily grams:',
  productsInfoTableButton: 'Click here to zoom',
  productsInfoPuppiesRange: 'Stage: Puppies from 2 to 12 months',
  productsInfoAdultsRange: 'Stage: Adults from 1 to 6 years',
  productsInfoSeniorRange: 'Stage: Seniors from 7 years and up',
  productsInfoComp: 'Guaranteed Composition WB / DB',
  productsInfoCons: 'Product conservation',
  productsInfoGrams: 'grams per day',
  productsInfoIngr: 'Feeding Instructions',
  productX20: 'Box x20',
  productX202: '( Up to five different recipes )',
  productX20Button: 'Create',
  productBoxInfoX20: 'Box x20 units',
  productBoxInfoX40: 'Box x40 units',
  productSalesText: 'Sale license',
  productBread: 'Catalogue',
  productBreadHome: 'Home',
  successAdd: 'Product added to the cart',
  productsNoMore: 'No more units of this product'
};

export const shoppingCar = {
  carTitle: 'Order summary',
  carBasketButton: 'Check your cart',
  carShoppingButton: 'Continue shopping',
};
