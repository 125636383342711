import { paymentFormAdmin } from '../../../interfaces/checkout';
import { ReferenceCode, ReferenceCodeMine, ReferenceConfiguration, ReferenceHistory } from '../../../interfaces/user/references';
import { Address, createEditAddress, CustomersMPCard, editUser, User } from '../../../interfaces/user/user';
import api from '../../axios';

export class ProfileService {
  init() {}

  getAddressByUser = (token: string): Promise<Address[]> =>
    new Promise((resolve, reject) => {
      api.get('pixie-customers/api/addresses', {
        headers: {
          authorization: token
        }
      })
        .then(response => {
          resolve(response.data.address);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  createAddress = (form: createEditAddress): Promise<Address> => {
    const authorization = window.localStorage.getItem('token');
    const newData = {
      ...form,
      country_id: form.country_id.value,
      zip_code: form.zip_code.value,
      city: form.city.value,
      state: form.state.value
    };

    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/addresses', newData, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.address);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editAddress = (form: createEditAddress, idAddress: string): Promise<Address> => {
    const authorization = window.localStorage.getItem('token');
    const newData = {
      ...form,
      country_id: form.country_id.value,
      zip_code: form.zip_code.value,
      city: form.city.value,
      state: form.state.value
    };

    return new Promise((resolve, reject) => {
      api.put(`pixie-customers/api/addresses/${idAddress}`, newData, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.address);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  deleteAddress = (idAddress: string): Promise<any> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.delete(`pixie-customers/api/addresses/${idAddress}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editPerson = (personForm: editUser, idUser: string): Promise<User> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.put(`pixie-customers/api/users/${idUser}`, personForm, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  updateCode = (idUser: string, code: string) => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.put(`pixie-customers/api/users/${idUser}`, {
        code_number: code
      }, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  uploadProfileImage = (file: FileList, idUser:string): Promise<string> => {
    const authorization = window.localStorage.getItem('token');
    const formData = new FormData();
    const newName = `imageProfile-${idUser}`;
    formData.append('file', file[0], newName);
    formData.append('folder', 'imagenProfiles');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-customers/api/uploads',
        headers: {
          Authorization: authorization ? authorization : '',
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      })
        .then(response => {
          resolve(response.data.key);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  getCodeReferenceMine = (): Promise<ReferenceCodeMine> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/references-codes', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getMyHistoryCode = (code: string): Promise<ReferenceHistory[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-customers/api/references-history/${code}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getCodeReference = (code: string): Promise<ReferenceCode> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-customers/api/references-codes/${code}`)
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getHistoryCode = (code: string, email: string): Promise<ReferenceCodeMine[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-customers/api/references-history?code=${code}&email=${email}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getReferredConfiguration = (): Promise<ReferenceConfiguration> =>
    new Promise((resolve, reject) => {
      api.get('pixie-customers/api/references')
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  saveCard = (token: string, typeCard: {
    type: string;
    method: string;
}) => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/mercado-pago/customers-card', {
        token,
        payment_type_id: typeCard.type,
        payment_method_id: typeCard.method,
      }, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response);
        }
        );
    });
  };

  getDataCard = (): Promise<CustomersMPCard> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/mercado-pago/customers-card', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.customers);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  updateCard = (token: string, tarjetId: number, form: paymentFormAdmin) => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.put('pixie-customers/api/mercado-pago/customers-card', {
        id: tarjetId,
        token,
        cardholder: {
          name: form.personName,
          identification: {
            type: 'Otro',
            number: form.documentNumber
          }
        },
        expiration_month: Number(form.personDateRefPa.split('/')[0]),
        expiration_year: Number(`20${form.personDateRefPa.split('/')[1]}`)
      }, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };
}

export default new ProfileService();

