export const shipping = {
  shippingtitle: 'Delivery',
  shippinginfotitle: 'Delivery information',
  shippingInfoPart1: 'Deliveries are sent every Tuesday and arrive in 2 to 3 days',
  shippingInfoPart2: 'Free shipping',
  shippingback: 'Back to information',
  shippingContinuebutton: 'Continue with delivery',
  shippingOrderNote: 'Add a note to your order',
  shippingFreeShipment: 'Free shipment',
  shippingStandardShipping: 'Standard shipping'
};
