/* eslint-disable quotes */
export const myPets = {
  myPets: 'Mis mascotas',
  myPetsBackground: 'Escoge un color de fondo',
  myPetFormName: "¿Cómo se llama tu mascota?",
  mypetFormSpecie: 'Especie de tu mascota',
  myPetFormBorn: '¿Cuándo nació tu mascota?',
  myPetFormWeight: 'Peso',
  myPetFormActivity: 'Actividad física',
  myPetFormAllergies: 'Enfermedades y alergias',
  myPetFormYes: 'Si',
  myPetFormCancel: 'Cancelar',
  myPetFormLoading: 'Cargando...',
  myPetFormUpdate: 'Actualizar',
  myPetFormCreate: 'Crear',
  myPetNewPet: 'Nueva mascota',
  myPetEdit: 'Editar mascota',
  profile: 'Perfil',
  dog: 'Perro',
  cat: 'Gato',
  years: 'Años',
  year: 'Año',
  myPetsSeeProfile: 'Ver perfil',
  myPetSpecies: 'Especie',
  age: 'Edad',
  weight: 'Peso',
  PhysicalActivity: 'Actividad física',
  myPetsBirthday: 'Cumpleaños',
  myPetsDiseases: 'Enfermedades y alergias'
};
