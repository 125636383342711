export const calculator = {
  calcHeader: 'Calculadora, conoce la ración indicada',
  calcDescription: 'Calculadora Pixie: Descubre la ración ideal para tu mascota. Nutrición personalizada para perros y gatos, adaptada a sus necesidades únicas.',
  calcBannerFrag1: 'Cambia la forma en ',
  calcBannerFrag2: 'que alimentas a tu',
  calcBannerFrag3: 'mascota,',
  calcBannerFrag4: 'comienza aquí.',

  calcBannerFrag1Mobile: 'Cambia',
  calcBannerFrag2Mobile: 'la forma en',
  calcBannerFrag3Mobile: 'que alimentas',
  calcBannerFrag4Mobile: 'a tu mascota',
  calcBannerFrag5Mobile: 'Comienza aquí',

  calcBanner2Frag1: '¡Tu amigo de cuatro',
  calcBanner2Frag2: 'patas es único!',
  calcBanner2Frag3: 'Por eso en Pixie tenemos más de 12 opciones para satisfacer',
  calcBanner2Frag4: 'las distintas necesidades nutricionales de cada uno.',

  calcRecommended: 'Recomendados:',
  calcNoRecomended: 'No hay recomendaciones',
  calcAgainButton: 'Calcular de nuevo',

  calcRecommendedFragUSA1: 'Pixies',
  calcRecommendedFragUSA2: 'duran para ',
  calcRecommendedFragUSA3: 'días ',

  calcRecommendedFrag1: 'Dieta recomendada para 4 semanas',
  calcRecommendedFrag2: 'Pixies',
  calcRecommendedFrag3: '¡combínalas como tu quieras!',
  calcRecommendedFrag4: 'Selecciona y luego agrega al carrito.',

  calcSummary: 'Resumen',
  calcSummaryButton: 'Agregar',
  calcSummaryButtonDT: 'Agregar a la bolsa',
  calcSummaryButtonDTSubscribe: 'Suscríbete y ahorra',

  calcFormError: 'Hubo un error en el calculo, porfavor revise los datos.',
  calcFormErrorAdult: '¡Wow, tu amigo ha crecido! Si tu mascota tiene más de 12 meses ya es un adulto.',
  calcFormErrorPuppy: '¡Auch! Pixie está diseñado para cachorros a partir de los 2 meses.',
  calcFormErrorSenior:
    '¡Woow, tu amigo ya ha llegado a su etapa senior!, Si tu perrito tiene más de 7 años ya es considerado senior',
  calcFormErrorSeniorCat:
    '¡Woow, tu amigo ya ha llegado a su etapa senior!, Si tu michi tiene más de 8 años ya es considerado senior',

  calcFormErrorName: 'Debes escribir un nombre',
  calcFormErrorType: 'Se debe seleccionar un tipo de mascota',
  calcFormErrorAge: 'Se debe seleccionar una edad',
  calcFormErrorExactAge: 'Se debe seleccionar una edad correcta',
  calcFormErrorWeight: 'Se debe seleccionar un peso mayor a 0',
  calcFormErrorEx: 'Se debe seleccionar una actividad fisica',

  calcFormDog: 'perrito',
  calcFormCat: 'michi',

  calcFormTitleFrag1: 'Consiente a tu',
  calcFormTitleFrag2: 'con nuestra dieta rica en proteína satisfaciendo sus necesidades nutricionales.',

  calcFormMonths: 'meses',
  calcFormYears: 'años',
  calcFormWeightTitle: '¿Sabes el peso ideal de tu mascota?',
  calcFormWeightSubtitle: 'Revisa esta guía:',
  calcFormTableCat: 'Tabla peso ideal gato',
  calcFormTableDog: 'Tabla peso ideal perro',

  calcFormLabelName: '¿Cómo se llama tu mascota?*',
  calcFormLabelType: 'Tu mascota es un... *',
  calcFormLabelAge: 'Edad*',
  calcFormLabelExactAge: 'Edad exacta en',
  calcFormLabelWeight: 'Peso ideal en kilogramos*',
  calcFormLabelEx: 'Actividad fisica*',
  calcFormLabelAlergiesPart2: 'sufre de alguna de las siguientes alergias y enfermedades?',
  calcFormLabelYes: ' Si',
  calcFormLabelHepatic: 'Hepática',
  calcFormLabelRenal: 'Renal',
  calcFormLabelObesity: 'Obesidad',
  calcFormLabelAlergy: 'Alergia',
  calcFormLabelStomach: 'Estómago sensible',
  calcFormLabelOther: 'Otras',

  calcFormNextButton: 'Siguiente',
  calcFormBackButton: 'Anterior',
  calcFormConsultButton: 'Consultar',
  calcFormPlaceholderSelec: 'Selecciona',
  calcFormPlaceholderExactAge: 'Selecciona',

  calcResultTitleFrag1: 'debe consumir un total de ',
  calcResultTitleFrag2: 'gramos al día divididos en',
  calcResultTitleFrag3: 'porciones!',
  calcResultTitleFrag4: '',
  calcResultErrorFrag1: 'Te hacen falta',
  calcResultErrorFrag2: 'porciones para completar las 4 semanas',
  calcResultErrorUsa1: 'Para ',
  calcResultErrorUsa2box: 'caja ',
  calcResultErrorUsa2boxes: 'cajas ',
  calcResultErrorUsa3: 'de pixies durará ',
  calcResultErrorUsa4: 'días',
  calcResultMsgFrag1: 'Bien has seleccionado',
  calcResultMsgFrag2: 'porciones',
  calcResultPixieFor: 'Pixies por',
  calcResultPixieFor2: '',
  calcResultBox20: 'Arma tu caja con diferentes recetas x 20 rollos',

  calcResultSelectToast: 'Selecciona la cantidad deseada y agrega a la canasta.',
  calcResultObesityToast:
    'Estas son las dietas para obesidad pero recomendamos tomar una asesoría nutricional con nuestros veterinarios para evaluar mejor tu caso.',
  calcResultOnePurchase: 'Total compra única',
  calcResultSubscription: 'Total compra con suscripción',
  calcProductAdded: 'Productos agregado con éxito',
  calcProductQuantityIncomplete: 'La bodega no cuenta con la cantidad necesaria, algunos productos se agregaron con la cantidad incompleta',
};
