import { iconProfileDefault } from '../../../assets/vectors';
import { capitalize } from '../../../helpers/capitalize';
import { useAppContext } from '../../../hooks';
import Icon from '../icon';

const ProfileImage = ({ showName = true, img, handleChangeImg, name, id }: ProfileImageProps) => {
  const { toast } = useAppContext();
  const handleValidSize = (file: FileList | null) => {
    if ((file) && file[0].size > 4000000) {
      toast.fire({
        icon: 'warning',
        title: 'El archivo tiene demasiado peso',
      });

      return;
    }

    handleChangeImg(file);
  };

  return (
    <div className='flex flex-col justify-center items-center gap-5 lg:gap-3'>
      <input type='file' id={id}
        name='file'
        className='hidden'
        onChange={e => handleValidSize(e.target.files)}
        accept='image/*'
      ></input>
      <label
        htmlFor={id}
        id='labelarchivo'
        className=' bg-opacity-[0.4] rounded-full flex justify-center items-center w-[8.5rem] h-[8.5rem] lg:w-[6.2rem] lg:h-[6.2rem] relative cursor-pointer'
      >
        <div className={`bg-[#b3dedb] rounded-full flex justify-center items-center w-full h-full lg:w-full lg:h-full absolute hover:hidden ${img && 'p-0'}`}>
          <img src={img ? img : iconProfileDefault} className={`w-full h-full ${img && 'object-cover rounded-full'}`}/>
        </div>
        <div className='flex text-white bg-[#00000036] w-full h-full rounded-full flex-col justify-center items-center font-sanzBold'>
          <Icon name='edit' size='lg'/>
          <span>Subir una foto</span>
        </div>
      </label>
      {showName &&
        <p className='text-xl'>{capitalize(name ? name : '')}</p>
      }
    </div>
  );
};

interface ProfileImageProps {
  showName?: boolean,
  img?: string | null,
  handleChangeImg: (file: FileList | null) => void,
  name?: string,
  id: string
}

export default ProfileImage;
