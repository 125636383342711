import { useNavigate } from 'react-router-dom';

import useAppContext from './useAppContext';

export const useLogout = (callback?: () => void) => {
  // Hooks
  const { updateContext, toast } = useAppContext();
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = () => {
    updateContext(old => ({ ...old, isAuthenticated: false, user: undefined }));
    localStorage.removeItem('token');

    toast.fire({
      icon: 'success',
      title: 'Sesión cerrada correctamente',
    });

    navigate('/');

    if (callback) callback();
  };

  return handleLogout;
};

export default useLogout;
