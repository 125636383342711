const SwitchFiel = ({ value, setValue, className, colorTrueValue, colorFalseValue }:SwitchFielprops) => {
  // Change value
  const handleChange = () => setValue(!value);

  return (
    <div className={`text-xs text-white font-sanzBold px-[0.1rem] py-[0.1rem] w-12 rounded-[16px]
      ${value ? colorTrueValue ? colorTrueValue : 'bg-pixieLightBlue' : colorFalseValue ? colorFalseValue : 'bg-primary'}
      ${className}`}
    >
      {value ?
        <div className='flex items-center justify-between px-[0.1rem]'>
          <span>ON</span>
          <div className='w-[18px] h-[18px] bg-white shadow-sm rounded-full cursor-pointer animate__animated animate__fadeInLeft animate__faster' onClick={handleChange}></div>
        </div>
        :
        <div className='flex items-center justify-between pr-[0.1rem]'>
          <div className='w-[18px] h-[18px] bg-white shadow-sm rounded-full cursor-pointer animate__animated animate__fadeInRight animate__faster' onClick={handleChange}></div>
          <span>OFF</span>
        </div>
      }
    </div>
  );
};

interface SwitchFielprops {
  value: boolean,
  setValue: (value: boolean) => void,
  className?: string,
  colorTrueValue?: string,
  colorFalseValue?: string
}

export default SwitchFiel;
