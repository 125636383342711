export const createCombo = {
  comboTitleStep1: 'Elige la cantidad de sabores',
  comboTitleStep2: 'Elige los sabores',
  comboTitleStep3: 'Revisa tu combo',
  comboTitleStep4: 'Tu combo incluye',
  comboSelectPlaceholder: 'Elige tu sabor',
  comboBread: 'Catálogo',
  comboBread2: 'Crear combo',
  comboError: 'Debes completar minimo las 20 unidades o tu combo debe estar en multiplos de 20',
  comboButtonAdd: 'Agregar a la bolsa',
  comboHeader: 'Elige tu caja con los productos favoritos para tu mascota',
  comboDescription: 'Pet Box: selecciona una colección especial de los productos favoritos de tu mascota. ¡El regalo perfecto para tu compañero peludo!',
};
