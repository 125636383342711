import moment from 'moment';
import { useEffect, useState } from 'react';

import Button from '../../../components/common/button';
import Carrousel from '../../../components/common/carrousel';
import ContainerPopupUser from '../../../components/common/containerPopupUser';
import Icon from '../../../components/common/icon';
import { ItemOrderUser } from '../../../components/common/itemOrderUser';
import ProductCartMiniInfo from '../../../components/common/productCartMiniInfo';
import SwitchFiel from '../../../components/form/switchField';
import PopupDecision from '../../../components/layout/popupDecision';
import EditSubscription from './editSubscription';
import StatusSubscription from './statusSubscription';

import { capitalize } from '../../../helpers/capitalize';
import { useAppContext } from '../../../hooks';
import useScrolled from '../../../hooks/useScrolled';
import { SubscriptionOrders, subscriptionUserComplete } from '../../../interfaces/subscription';
import subscriptionService from '../../../services/subscriptionService';

moment.updateLocale('es', {
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});

const SubscriptionsSection = ({ subscription }: subscriptionsSectionProps) => {
  // Hooks
  const { scrollTo } = useScrolled();
  const { showMinimalNavbar, toast, updateContext } = useAppContext();
  const [status, setStatus] = useState<boolean>(subscription.status === '1');
  const [showPopupStatusSubscription, setShowPopupStatusSubscription] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [datesSubscriptions, setDatesSubscriptions] = useState<SubscriptionOrders[]>();
  const [showPopupActivateStatus, setShowPopupActivateStatus] = useState(false);

  const changeStatus = (value: boolean) => {
    scrollTo(0);
    if (value)
      setShowPopupActivateStatus(true);
    else
      setShowPopupStatusSubscription(!value);
  };

  useEffect(() => getDataDates(), []);

  const getDataDates = () => {
    subscriptionService.getordersBySubscription(subscription.id)
      .then(res => {
        setDatesSubscriptions(res);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getStatusDate = (data: SubscriptionOrders): 'wait' | 'done' | 'comming' => {
    const jsonDeliveryStatus = {
      PENDIENTE: 'wait',
      ENTREGADO: 'done',
      PROCESO: 'comming',
      CANCELADO: 'wait'
    };

    const status = jsonDeliveryStatus[data.purchases_orders.delivery_status.key as 'PENDIENTE' | 'ENTREGADO' | 'PROCESO' | 'CANCELADO'];
    return status as 'wait' | 'done' | 'comming';
  };

  const handleActivateSubscription = () => {
    subscriptionService.editStatusSubscriptionUser(subscription.id, subscription.status === '1' ? 0 : 1)
      .then(res => {
        toast.fire({
          icon: 'success',
          title: 'Suscripcion editadá correctamente.',
        });
        updateContext(old => ({ ...old, reloadData: true }));
        setStatus(res.status === '1');
        setShowPopupActivateStatus(false);
      })
      .catch(() => {
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: 'Error editando la suscripción.',
        });
      });
  };

  return (
    <div className='bg-white mt-5 mb-2 border border-fourth rounded-[20px] px-3 py-3 flex flex-col gap-3 lg:gap-4 lg:pt-6 lg:pb-20 lg:px-6'>
      <div className='flex justify-between items-center'>
        <p className='text-pixieLightBlue text-lg font-sanzBold'>Suscripción {subscription.internal_id}</p>
        <div className='flex gap-2'>
          <label> Estado: </label>
          <SwitchFiel
            setValue={changeStatus}
            value={status}
          />
        </div>
      </div>
      <div className='flex justify-between items-center'>
        <p className='text-pixieLightBlue text-lg'>Datos de la suscripción</p>
        {status &&
        <div className='flex-col gap-4 w-1/3 flex mt-2 items-end lg:gap-2'>
          <Button
            className='bg-primary text-white font-sanzBold text-lg'
            padding='px-9 py-2'
            onClick={() => setShowEdit(true)}
          >
            Editar suscripción
          </Button>
        </div>
        }
      </div>

      <div className='text-xs flex flex-col gap-3 lg:text-sm lg:gap-3'>
        <ItemOrderUser
          title='Frecuencia de pago:'
          text={subscription.subscriptions.title}
        />
        <ItemOrderUser
          title='Frecuencia de entrega:'
          text={`${subscription.shipping_frequencies.value_days} dias`}
        />
        <ItemOrderUser
          title='Día de entrega:'
          text={moment(subscription.due_date).format('dddd')}
        />
        {datesSubscriptions &&
          <ItemOrderUser
            title='Primera entrega:'
            text={datesSubscriptions.length > 0 ? moment(datesSubscriptions[0].delivery_date).format('DD MMMM YYYY') : ''}
          />
        }
        <ItemOrderUser
          title='Fecha de cobro:'
          text={moment(subscription.created_at).format('DD MMMM YYYY')}
        />

        <ItemOrderUser
          title='Fecha de renovación:'
          text={moment(subscription.due_date).format('DD MMMM YYYY')}
        />
        {datesSubscriptions &&
          <ItemOrderUser
            title='Total de entregas:'
            text={`${datesSubscriptions.length}`}
          />
        }
        <ItemOrderUser
          title='Productos:'
          text={`${subscription.general_order.metadata.products.map(prod => ` ${capitalize(prod.product.name)} ${prod.product.presentation} (x${prod.quantity})`)}`}
        />
      </div>
      <div className='relative lg:px-20'>
        {/* Carrousel */}
        <Carrousel
          className='pt-16 md:pt-10 pb-10 h-max max-w-[1440px]'
          classNameArrow='filter-blue-pixie'
          navigation={showMinimalNavbar}
          breakpoints={{
            300: {
              slidesPerView: 2.1,
              initialSlide: subscription.general_order.metadata.products.length > 3 ? 1 : 0,
            },
            460: {
              slidesPerView: 2.1,
              initialSlide: (subscription.general_order.metadata.products.length > 3 ? 1 : 0),
            },
            580: {
              slidesPerView: 2.2,
              initialSlide: 1,
            },
            720: {
              slidesPerView: 3,
              initialSlide: 1,
            },
            800: {
              slidesPerView: 3,
              spaceBetween: 30,
              initialSlide: 1,
            },
            900: {
              slidesPerView: 3.5,
              spaceBetween: 30,
              initialSlide: 1,
            },
            1020: {
              slidesPerView: 2,
              spaceBetween: 0,
              initialSlide: 1,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
              initialSlide: 1,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 10,
              initialSlide: 1,
            },
            1366: {
              slidesPerView: 4,
              spaceBetween: 25,
              initialSlide: 1,
            },
          }}
        >
          {subscription.general_order.metadata.products.map(product => (
            <ProductCartMiniInfo
              key={`${subscription.id}-${product.product.id}`}
              product={product}
            />
          ))}
        </Carrousel>
      </div>

      <div className='flex flex-col gap-4'>
        <p className='text-lg text-pixieLightBlue'>Entregas por pago</p>
        <p className='text-pixieLightBlue text-sm'>Debito 1</p>
        {datesSubscriptions?.map(item =>
          <Delivery
            key={item.id}
            date={moment(item.delivery_date).format('DD MMMM')}
            status={getStatusDate(item)}
          />
        )}
      </div>

      {/* Popups */}
      {showPopupStatusSubscription &&
        <ContainerPopupUser>
          <StatusSubscription
            setShowPopup={setShowPopupStatusSubscription}
            subscription={subscription}
            updateSwichStatus={setStatus}
          />
        </ContainerPopupUser>
      }
      {showEdit &&
        <EditSubscription
          subscription={subscription}
          setClosePopup={() => setShowEdit(false)}
        />
      }

      {showPopupActivateStatus &&
        <PopupDecision
          handleAccept={handleActivateSubscription}
          handleDeny={() => setShowPopupActivateStatus(false)}
          textAccept='Activar'
          textDeny='Cancelar'
          title='¿Seguro que deseas activar la suscripción?'
        />
      }
    </div>
  );
};

interface subscriptionsSectionProps {
  subscription: subscriptionUserComplete
}

const Delivery = ({ status, date }:deliveryProps) => (
  <div className={`flex gap-5 items-center ${status === 'wait' && 'text-[#CBCBCB]'}`}>
    <div className='flex gap-3 items-center text-sm'>
      {status === 'done' &&
        <Icon name='done'
          className='bg-[#A7C694] text-white rounded-full w-4 h-4 flex justify-center items-center'
          size='xs'
        />
      }
      {status === 'comming' &&
        <Icon
          name='av_timer'
          className='bg-[#EB8F00] text-white rounded-full w-4 h-4 flex justify-center items-center'
          size='xs'
        />
      }
      {status === 'wait' &&
        <Icon
          name='av_timer'
          className='bg-[#CBCBCB] text-white rounded-full w-4 h-4 flex justify-center items-center'
          size='xs'
        />
      }
      <p>Entrega 1: </p>
    </div>
    <p className='font-subTitles text-sm'>{date}</p>

  </div>
);

interface deliveryProps {
  status: 'wait' | 'done' | 'comming',
  date: string
}

export default SubscriptionsSection;
