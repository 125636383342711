import { lazy, Suspense, useEffect, useRef } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FadeScreen from '../components/layout/fadeScreen';

const NotFound = lazy(() => import('../views/notFound'));
const Catalogue = lazy(() => import('../views/Catalogue'));
const Home = lazy(() => import('../views/Home'));
const Detail = lazy(() => import('../views/Detail'));
const Calculator = lazy(() => import('../views/Calculator'));
const Basket = lazy(() => import('../views/Basket'));
const CheckOut = lazy(() => import('../views/Checkout'));
const FrequentQuestions = lazy(() => import('../views/FrequentQuestions'));
const DataPrivacy = lazy(() => import('../views/dataPrivacy'));
const TermsAndConditions = lazy(() => import('../views/termsAndConditions'));
const ResultPayment = lazy(() => import('../views/resultPayment'));
const CreateCombo = lazy(() => import('../views/createCombo'));
const Login = lazy(() => import('../views/auth/login'));
const Register = lazy(() => import('../views/auth/register'));
const RecoveryPassword = lazy(() => import('../views/auth/recoveryPassword'));
const ResetPassword = lazy(() => import('../views/auth/resetPassword'));
const Subscription = lazy(() => import('../views/subscription'));
const CreateSubscription = lazy(() => import('../views/createSubscription'));

import { useAppContext, useAuth } from '../hooks';
import Protected from './Protected';
import UserRoutes from './userRoutes';
import { useTranslation } from 'react-i18next';
import subscriptionService from '../services/subscriptionService';
import storeService from '../services/storeService';
import { FormCreateSubscription } from '../interfaces/subscription';
import { selectCountry } from '../@fake/countrySelects';
import { workCities } from '../interfaces/store';

const AppRouter = () => {
  // Hooks
  const { isAuthenticated, updateContext } = useAppContext();
  const { checkToken, old: token, isChecking, user } = useAuth();
  const isMounted = useRef(false);
  const currentUrl = window.location.href;
  /// const { getUserLocation } = useLocation();

  // Translate
  const { i18n } = useTranslation();

  // Check if user is authenticated
  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkToken();

      updateContext(old => ({
        ...old,
        isLoadingPreData: true
      }));

      if (isAuth)
        // Update auth state
        return updateContext(old => ({
          ...old,
          isAuthenticated: true,
          user,
        }));

      // Remove data from localStorage
      localStorage.removeItem('token');
    };

    if (!isAuthenticated && isMounted.current && token) verifyAuth();

    if (!isMounted.current) {
      isMounted.current = true;
      verifyAuth();
    }

    return () => { };
  }, [isMounted.current]);

  useEffect(() =>
    updateContext(old => ({
      ...old,
      user
    }))
  , [user]);

  useEffect(() => {
    if (currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_US as string)) {
      updateContext(old => ({ ...old, countrySelected: selectCountry[1] }));
      updateContext(old => ({ ...old, location: 'USA' }));
      updateContext(old => ({ ...old, showPopupGotoSite: false }));
      i18n.changeLanguage('en');
      localStorage.setItem('languaje', 'en');
      const workCities = localStorage.getItem('workCities');
      const isSavedCellar = localStorage.getItem('cellarSelected');
      if (isSavedCellar && workCities)
        getDataWorkCities(workCities);
      updateContext(old => ({ ...old, showPopupShippingCity: (!isSavedCellar && !workCities) }));
    } else if (currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_MX as string)) {
      updateContext(old => ({ ...old, countrySelected: selectCountry[0] }));
      updateContext(old => ({ ...old, location: 'MEX' }));
      const workCities = localStorage.getItem('workCities');
      const isSavedCellar = localStorage.getItem('cellarSelected');
      updateContext(old => ({ ...old, showPopupShippingCity: (!isSavedCellar && !workCities) }));
      if (isSavedCellar && workCities)
        getDataWorkCities(workCities);
      updateContext(old => ({ ...old, showPopupGotoSite: false }));
    }
  }, []);

  useEffect(() => {
    const prevLanguaje = localStorage.getItem('languaje');
    const currentUrl = window.location.href;
    if (prevLanguaje === null) {
      if (currentUrl === `${process.env.REACT_APP_REDIRECT_PROD_US}`) {
        i18n.changeLanguage('en');
        localStorage.setItem('languaje', 'en');
      }
    } else
      i18n.changeLanguage(prevLanguaje);
    return () => {};
  }, []);

  useEffect(() => {
    setSubscriptions();
    const prevDataSub = localStorage.getItem('subscriptionData');
    if (prevDataSub !== null) {
      const prevDataSubTransform: FormCreateSubscription = JSON.parse(prevDataSub);
      updateContext(old => ({ ...old, subscriptionData: prevDataSubTransform, isSubscriptionSelected: true }));
    }
  }, []);

  const setSubscriptions = () => {
    subscriptionService.getSubscriptions().then(res => {
      updateContext(old => ({ ...old, subscriptionsOptions: res }));
    }).catch(error => {
      console.log(error);
    });

    subscriptionService.getMinShippingPrice('mex').then(res => {
      updateContext(old => ({ ...old, minPriceSubscription: res.price }));
    }).catch(error => {
      console.log(error);
    });
  };

  const getDataWorkCities = (workCities: string) => {
    const workData: workCities = JSON.parse(workCities);
    storeService.getOneWorkCity(`${workData.city_id}`).then(res => {
      updateContext(old => ({ ...old, cellarSelected: res.cellar, workCities: res }));
    });
  };

  // Routes
  return (
    <>
      {isChecking && <FadeScreen />}
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            path='/catalogo'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Catalogue />
              </Suspense>
            }
          />

          <Route path='/catalogo/:type'>
            <Route index
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Catalogue />
                </Suspense>
              }/>
            <Route path='/catalogo/:type/:cat'>
              <Route index
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Catalogue />
                  </Suspense>
                }
              />
              <Route path='/catalogo/:type/:cat/detalle/:product'
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Detail />
                  </Suspense>
                }/>
            </Route>
            <Route path='/catalogo/:type/detalle/:product'
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Detail />
                </Suspense>
              }/>
          </Route>
          <Route path='/catalogo/detalle/:product'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Detail />
              </Suspense>
            }
          />

          <Route
            path='producto/detalle/:product'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Detail />
              </Suspense>
            }
          />

          <Route
            path='producto/crear/combo'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CreateCombo />
              </Suspense>
            }
          />

          <Route
            path='/calculadora'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Calculator />
              </Suspense>
            }
          />
          <Route
            path='/canasta'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Basket/>
              </Suspense>
            }
          />
          <Route
            path='/pago'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CheckOut/>
              </Suspense>
            }
          />
          <Route
            path='/pago/resultado'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResultPayment/>
              </Suspense>
            }
          />
          <Route
            path='/payment/result'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResultPayment/>
              </Suspense>
            }
          />
          <Route
            path='/preguntas-frecuentes'
            element={
              <Suspense fallback={<FadeScreen />}>
                <FrequentQuestions/>
              </Suspense>
            }
          />
          <Route
            path='/politica-privacidad'
            element={
              <Suspense fallback={<FadeScreen />}>
                <DataPrivacy/>
              </Suspense>
            }
          />
          <Route
            path='/terminos'
            element={
              <Suspense fallback={<FadeScreen />}>
                <TermsAndConditions />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/ingresar'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Login />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/registro'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Register />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/recuperar'
            element={
              <Suspense fallback={<FadeScreen />}>
                <RecoveryPassword />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/resetear'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path='/suscripcion'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Subscription />
              </Suspense>
            }
          />

          <Route path='/usuario/*' element={
            <Protected>
              <UserRoutes/>
            </Protected>
          }/>

          <Route
            path='/suscripcion/crear'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CreateSubscription />
              </Suspense>
            }
          />

          <Route path='*' element={
            <Suspense fallback={<FadeScreen />}>
              <NotFound />
            </Suspense>
          } />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
