/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable curly */
/* eslint-disable no-else-return */
/* eslint-disable no-case-declarations */
import { CartItem } from '../interfaces/basket';
import { addressObject, paymentForm, shippingTypeForm, SubmissionFormInterface } from '../interfaces/checkout';
import { couponComplete } from '../interfaces/coupon';
import { billingDetailsInterface, billingDetailsInterfaceMV, generatePaymentMP, itemsMP, paymentData, productsStripe, sendDataStripe, shippingDiscountCoupon } from '../interfaces/payment';
import { workCities } from '../interfaces/store';
import { FormCreateSubscription } from '../interfaces/subscription';
import { ReferenceCode, ReferenceConfiguration } from '../interfaces/user/references';
import { PixieContries } from './pixieApp';
import { calculateTotal, calculateTotalAccesories, calculateWithDiscountSubscription, roundToXDigits } from './productHelper';

// Organize the data to send them to the api
export const organiceInformationPaymentMP = (
  idCustomer: string,
  userData: SubmissionFormInterface,
  products: CartItem[],
  shippingData: shippingTypeForm,
  finalShippingData: shippingTypeForm,
  form: paymentForm,
  sameBillingAddress: boolean,
  coupon: couponComplete | undefined,
  dataFormCheckOut: SubmissionFormInterface,
  paymentValues: paymentData,
  referredCode: ReferenceCode | undefined,
  hubspotClientId: string | undefined,
  cellarId: string,
  countryId: string,
  cityId: string,
  originalShippingValue: number,
  workCities: workCities | undefined,
  deliveryDate: string,
// eslint-disable-next-line max-params
): generatePaymentMP => {
  let valueDiscountShipping: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };

  if (coupon?.feature && originalShippingValue)
    valueDiscountShipping = calculateDiscountPriceShipping(coupon.feature, originalShippingValue);
  const paymentData: generatePaymentMP = {
    items: organiceProductsMP(products, coupon?.feature ? shippingData : finalShippingData, coupon, referredCode, undefined, valueDiscountShipping),
    payer: organiceBillingDetailsMP(userData, form, sameBillingAddress),
    auto_return: 'approved',
    shipments: {
      cost: 0,
      mode: finalShippingData.type,
      receiver_address: {
        apartment: `${userData?.apartment}`,
        city_name: `${userData?.city}`,
        country_name: `${userData?.country.value}`,
        floor: `${userData?.houseNumber}`,
        state_name: `${userData?.state.value}`,
        street_name: `${userData?.address}`,
        street_number: `${userData?.reference}`,
        zip_code: `${userData?.zip_code}`
      }
    },
    metadata: {
      shippingDetails: {
        name: `${(userData?.name ? userData.name : '')} ${(userData?.last_name ? userData.last_name : '')}`,
        phone: userData?.phone ? userData?.phone : '',
        address1: organiceAddress(userData),
        city: userData?.city ? userData?.city.label : '',
        region: userData?.state.value ? userData?.state.label : '',
        country: userData?.country ? userData?.country.label : '',
        cityValue: userData?.city.value ? userData?.city.value : '',
        regionvalue: userData?.state.value ? userData?.state.value : '',
        countryValue: userData?.country.value ? userData?.country.value : ''
      },
      customer_id: idCustomer,
      delivery_note: dataFormCheckOut.delivery_note ? dataFormCheckOut.delivery_note : '',
      billingDetails: organiceBillingDetails(userData, form, sameBillingAddress),
      contactDetails: {
        email: userData?.email ? userData?.email : '',
        firstName: userData?.name ? userData?.name : '',
        lastName: userData?.last_name ? userData?.last_name : '',
        phoneNumber: userData?.phone ? userData?.phone : '',
      },
      details_payments: {
        subTotalNoIva: paymentValues.subTotalNoIva,
        deliveryPrice: finalShippingData.price,
        onlyIva: paymentValues.onlyIva < 0 ? 0 : paymentValues.onlyIva,
        totalPayment: calculateTotalPayment(products, finalShippingData, coupon, referredCode?.reference_data, valueDiscountShipping, workCities)
      },
      couponId: coupon && {
        amount: roundToXDigits(calculateTotal(products, false) - getPriceDescount(products, coupon, valueDiscountShipping), 2),
        id: coupon.id,
        code: coupon.claimCode,
        internalCouponName: coupon.internalCoupon.name,
        internalCouponNumber: coupon.internalCoupon.code,
        ...coupon.feature && {
          discountShipping: calculateDiscountPriceShipping(coupon.feature, originalShippingValue).valueDiscount
        }
      },
      referred: referredCode && {
        amount: roundToXDigits(calculateTotal(products, false) - getPriceDescountReferred(products, referredCode.reference_data), 2),
        code: referredCode.code,
        id: referredCode.id,
        email: referredCode.email
      },
      products,
      hubspot_client_id: hubspotClientId,
      cellar_id: cellarId ? cellarId : '',
      postal_code: userData?.zip_code.label
    },
    total_amount: calculateTotalPayment(products, finalShippingData, coupon, referredCode?.reference_data, valueDiscountShipping, workCities),
    back_urls: {
      failure: `${process.env.REACT_APP_REDIRECT_PROD === 'true' ? process.env.REACT_APP_REDIRECT_PROD_MX : process.env.REACT_APP_URL_BASE}pago/resultado`,
      pending: `${process.env.REACT_APP_REDIRECT_PROD === 'true' ? process.env.REACT_APP_REDIRECT_PROD_MX : process.env.REACT_APP_URL_BASE}pago/resultado`,
      success: `${process.env.REACT_APP_REDIRECT_PROD === 'true' ? process.env.REACT_APP_REDIRECT_PROD_MX : process.env.REACT_APP_URL_BASE}pago/resultado`,
    },
    payment_methods: {
      excluded_payment_types: [
        { id: 'ticket' },
        { id: 'bank_transfer' },
        { id: 'atm' }
      ],
    },
    cellar_id: cellarId ? parseInt(cellarId, 10) : '',
    country_id: countryId ? countryId : '',
    city_id: cityId ? cityId : '',
    ...(deliveryDate && { delivery_date: deliveryDate })
  };

  return paymentData;
};

export const organiceProductsMP = (
  products: CartItem[],
  shippingData: shippingTypeForm,
  coupon: couponComplete | undefined,
  referredConfiguration: ReferenceCode | undefined,
  couponSubscriptionValue: number | undefined,
  valueDiscountShipping: shippingDiscountCoupon
) : Array<itemsMP> => {
  /// console.log(finalShippingData);
  // console.log(shippingData);

  const productsArray = products.map(item => {
    const productItem: itemsMP = {
      id: item.product.id,
      description: {
        presentation: item.product.presentation,
        age: item.product.age
      },
      picture_url: item.product.url_image,
      unit_price: Number(item.product.price),

      title: item.product.name,
      quantity: item.quantity,
    };
    return productItem;
  });
  // Add shipping cost
  if (shippingData.price !== 0) {
    const productItem: itemsMP = {
      id: '0',
      description: {
        presentation: 'envio',
        age: 'envio'
      },
      picture_url: '',
      unit_price: shippingData.price,
      title: 'Envio',
      quantity: 1,
    };
    productsArray.push(productItem);
  }

  if (coupon && !couponSubscriptionValue) {
    const productItemdiscount: itemsMP = {
      id: '0',
      description: {
        presentation: 'descuento',
        age: 'descuento'
      },
      picture_url: '',
      unit_price: (getPriceDescount(products, coupon, valueDiscountShipping) - calculateTotal(products, false)),
      title: 'descuento',
      quantity: 1,
    };
    productsArray.push(productItemdiscount);
  } else if (coupon && couponSubscriptionValue) {
    const productItemdiscount: itemsMP = {
      id: '0',
      description: {
        presentation: 'descuento',
        age: 'descuento'
      },
      picture_url: '',
      unit_price: couponSubscriptionValue,
      title: 'descuento',
      quantity: 1,
    };
    productsArray.push(productItemdiscount);
  } else if (referredConfiguration) {
    const productItemdiscount: itemsMP = {
      id: '0',
      description: {
        presentation: 'referido',
        age: 'referido'
      },
      picture_url: '',
      unit_price: (getPriceDescountReferred(products, referredConfiguration.reference_data) - calculateTotal(products, false)),
      title: 'referido',
      quantity: 1,
    };
    productsArray.push(productItemdiscount);
  }

  return productsArray;
};

const organiceBillingDetailsMP = (userData: SubmissionFormInterface | undefined, form:paymentForm, sameBillingAddress: boolean) => {
  // Data base
  const dataIsFromForm: billingDetailsInterfaceMV = {
    name: `${(userData?.name ? userData.name : '')}`,
    surname: `${(userData?.last_name ? userData.last_name : '')}`,
    phone: {
      area_code: 'MX',
      number: `${userData?.phone ? userData?.phone : ''}`
    },
    address: {
      street_name: organiceAddress(userData),
      zip_code: `${userData?.zip_code ? userData?.zip_code : ''}`,
      street_number: 0,
    },
    email: `${userData?.email ? userData.email : ''}`
  };
  return dataIsFromForm;
};

// If the person send other billing details is change here
const organiceBillingDetails = (userData: SubmissionFormInterface | undefined, form:paymentForm, sameBillingAddress: boolean) => {
  // Data base
  const dataIsFromForm: billingDetailsInterface = {
    name: `${(userData?.name ? userData.name : '')} ${(userData?.last_name ? userData.last_name : '')}`,
    phone: userData?.phone ? userData?.phone : '',
    address1: organiceAddress(userData),
    city: userData?.city ? userData?.city.label : '',
    region: userData?.state.value ? userData?.state.label : '',
    country: userData?.country ? userData?.country.label : '',
    cityValue: userData?.city.value ? userData?.city.value : '',
    regionvalue: userData?.state.value ? userData?.state.value : '',
    countryValue: userData?.country.value ? userData?.country.value : ''
  };

  return dataIsFromForm;
};

// Organice address to send JSONObject with all information
const organiceAddress = (userData:SubmissionFormInterface | undefined): string => {
  const address: addressObject = {
    address: userData?.address,
    houseNumber: userData?.houseNumber,
    apartament: userData?.apartment,
    reference: userData?.reference,
    zipCode: userData?.zip_code.value,
    colony: userData?.colony,
  };

  return JSON.stringify(address);
};

/**
 * Calculate the total with the shipping price and iva
 * @param productsCar product saved in car
 * @param shippingData data to calculate the shipping value
 * @param withIva false = calculate the product witoutiva 0.16
 * @param coupon data coupon to calculate total payment with discount
 * @param country 1 = mexico, 2 = USA
 * @returns final value
 */
export const calculateTotalPayment = (
  productsCar: CartItem[],
  shippingData: shippingTypeForm,
  coupon: couponComplete | undefined,
  referredConfiguration: ReferenceConfiguration | undefined,
  valueDiscountShipping: shippingDiscountCoupon | undefined,
  workCities: workCities | undefined
): number => {
  let totalProduct = calculateTotal(productsCar, false);
  /// let totalProductWithShipping = totalProduct + shippingData.price;
  let valueDiscountShippingInside: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };
  if (valueDiscountShipping && valueDiscountShipping?.finalPrice > 0) {
    valueDiscountShippingInside = valueDiscountShipping;
    totalProduct += valueDiscountShippingInside.originalValueShipping;
  }

  if (coupon)
    switch (coupon?.couponType.key) {
      case 'percent':
        const valueCoupon = (totalProduct * coupon.discount / 100);

        const value = (valueCoupon > totalProduct ? totalProduct : valueCoupon) + valueDiscountShippingInside.valueDiscount;

        totalProduct = roundToXDigits((totalProduct - value), 2);
        break;
      case 'discount':
        const valueDis = (coupon.discount > totalProduct ? totalProduct : coupon.discount) + valueDiscountShippingInside.valueDiscount;
        totalProduct = roundToXDigits(totalProduct - valueDis, 2);
        break;
      default:
        break;
    }
  else if (referredConfiguration)
    switch (referredConfiguration.type_reference_discount_data.key) {
      case 'percent':
        const valueCoupon = (totalProduct * referredConfiguration.discount_referred / 100);
        totalProduct = roundToXDigits(totalProduct - valueCoupon, 2);
        break;
      case 'discount':
        const valueDis = referredConfiguration.discount_referred > totalProduct ? totalProduct : referredConfiguration.discount_referred;
        totalProduct = roundToXDigits(totalProduct - valueDis, 2);
        break;
      default:
        break;
    }

  if (valueDiscountShipping && valueDiscountShipping?.finalPrice > 0) {
    totalProduct += valueDiscountShippingInside.originalValueShipping;
  }

  // If the product cost > 750 the shipping is free
  if (totalProduct > (workCities ? Number(workCities.shipping_price_free_one_purchase) : 750))
    return roundToXDigits(totalProduct - shippingData.price, 2);

  let finalValue = 0;
  if (valueDiscountShipping && valueDiscountShipping.finalPrice > 0)
    finalValue = roundToXDigits((totalProduct), 2);
  else
    finalValue = roundToXDigits((totalProduct + shippingData.price), 2);

  return finalValue < 0 ? 0 : finalValue;
};

/**
 *
 * @param productsCar Products cart saved
 * @param coupon object coupon complete to calculate discount
 * @returns discount price
 */
export const getPriceDescount = (productsCar: CartItem[], coupon: couponComplete, valueDiscountShipping: shippingDiscountCoupon): number => {
  const totalProduct = calculateTotal(productsCar, false);

  switch (coupon?.couponType.key) {
    case 'percent':
      const valueCoupon = (totalProduct * coupon.discount / 100);

      const value = (valueCoupon > totalProduct ? totalProduct : valueCoupon) + valueDiscountShipping.valueDiscount;
      return roundToXDigits(totalProduct - value, 2);
    case 'discount':
      const valueDis = (Number(coupon.discount) > totalProduct ? totalProduct : Number(coupon.discount)) + valueDiscountShipping.valueDiscount;
      return roundToXDigits(totalProduct - valueDis, 2);
    default:
      break;
  }

  return 0;
};

export const getPriceDescountReferred = (productsCar: CartItem[], referredConfiguration: ReferenceConfiguration): number => {
  const totalProduct = calculateTotal(productsCar, false);

  switch (referredConfiguration.type_reference_discount_data.key) {
    case 'percent':
      const valueCoupon = (totalProduct * referredConfiguration.discount_referred / 100);

      const value = valueCoupon > totalProduct ? totalProduct : valueCoupon;
      return roundToXDigits(totalProduct - value, 2);
    case 'discount':
      const valueDis = referredConfiguration.discount_referred > totalProduct ? totalProduct : referredConfiguration.discount_referred;
      return roundToXDigits(totalProduct - valueDis, 2);
    default:
      break;
  }

  return 0;
};

export const isFormComplete = (data: SubmissionFormInterface, acceptConditions: boolean) => {
  let isFull = true;
  if (data.address === '')
    isFull = false;
  if (data.city?.value === '')
    isFull = false;
  if (data.colony === '')
    isFull = false;
  if (data.country?.value === '')
    isFull = false;
  if (data.email === '')
    isFull = false;
  if (data.houseNumber === '')
    isFull = false;
  if (data.last_name === '')
    isFull = false;
  if (data.name === '')
    isFull = false;
  if (data.phone === '')
    isFull = false;
  if (data.state?.value === '')
    isFull = false;
  if (data.zip_code?.value === '')
    isFull = false;
  if (!acceptConditions)
    isFull = false;
  return isFull;
};

// eslint-disable-next-line max-params
export const organiceDataStripe = (
  products: CartItem[],
  email?: string,
  coupon?: couponComplete,
  customer?: string,
  referredCode?: ReferenceCode | undefined,
  cellar_id?: number
): sendDataStripe => {
  const valueDiscountShipping: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };
  const dataSendStripe: sendDataStripe = {
    customer: customer ? customer : '',
    email: email ? email : '',
    metadata: {
      couponId: coupon && {
        amount: (roundToXDigits((calculateTotal(products, false) - getPriceDescount(products, coupon, valueDiscountShipping)), 2)),
        id: coupon.id,
        code: coupon.claimCode,
        internalCouponName: coupon.internalCoupon.name,
        internalCouponNumber: coupon.internalCoupon.code
      },
      details_payments: {
        totalPayment: `${calculateTotal(products, false)}`
      },
      referred: referredCode && {
        amount: roundToXDigits(calculateTotal(products, false) - getPriceDescountReferred(products, referredCode.reference_data), 2),
        code: referredCode.code,
        id: referredCode.id,
        email: referredCode.email
      },
      products
    },
    shipping: 0,
    products: organiceProductSt(products),
    cellar_id: cellar_id ? cellar_id : ''
  };

  return dataSendStripe;
};

export const organiceProductSt = (productCart: CartItem[]): productsStripe[] => {
  const finalProducts: productsStripe[] = [];
  productCart.forEach(item => {
    if (item.product.productsInside)
      item.product.productsInside.forEach(productInside => {
        const productStripe: productsStripe = {
          amount: productInside.price,
          id: productInside.id,
          name: productInside.name,
          quantity: productInside.quantity,
          stripeId: productInside.stripeId ? productInside.stripeId : '',
          description: JSON.stringify({
            presentation: item.product.presentation,
            age: item.product.age
          }),
          picture_url: item.product.url_image
        };

        finalProducts.push(productStripe);
      });
    else {
      const productStripe: productsStripe = {
        amount: item.product.price,
        id: item.product.id,
        name: item.product.name,
        quantity: (item.product.kind_pet.toLocaleLowerCase() === 'cat' || item.product.category === 'Snacks') ? item.quantity * 40 : item.quantity * 20,
        stripeId: item.product.stripeId ? item.product.stripeId : '',
        description: JSON.stringify({
          presentation: item.product.presentation,
          age: item.product.age
        }),
        picture_url: item.product.url_image
      };

      finalProducts.push(productStripe);
    }
  });

  return finalProducts;
};

export const fillPaymentMPSubscription = (
  userData: SubmissionFormInterface | undefined,
  products: CartItem[],
  shippingData: shippingTypeForm,
  finalShippingData: shippingTypeForm,
  form: paymentForm,
  coupon: couponComplete | undefined,
  dataFormCheckOut: SubmissionFormInterface,
  prevDataSubscription: FormCreateSubscription,
  tokenCustomer: string,
  tokenCard: string,
  paymentValues: paymentData,
  couponPriceDiscount: number,
  referredCode: ReferenceCode | undefined,
  hubspotClientId: string,
  cellarId: string,
  countryId: string,
  cityId: string,
  originalShippingValue: number
// eslint-disable-next-line max-params
): FormCreateSubscription => {
  const shipppingNumber = prevDataSubscription.order_detail.metadata.details_payments.numberShipping ? prevDataSubscription.order_detail.metadata.details_payments.numberShipping : 0;
  const newShippingData = { ...finalShippingData, price: finalShippingData.price * shipppingNumber };
  const accesoriesPrice = calculateTotalAccesories(products, prevDataSubscription.subscription.percen_discount, prevDataSubscription.subscription);
  let valueDiscountShipping: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };

  if (coupon?.feature && originalShippingValue)
    valueDiscountShipping = calculateDiscountPriceShipping(coupon.feature, originalShippingValue);
  const paymentData: FormCreateSubscription = {
    ...prevDataSubscription,
    payer: {
      type: 'customer',
      id: tokenCustomer
    },
    token: tokenCard,
    transaction_amount: calculateWithDiscountSubscription(products, prevDataSubscription.subscription.percen_discount, prevDataSubscription.shippingPrice, prevDataSubscription.subscription, coupon, prevDataSubscription.order_detail.metadata.details_payments.numberShipping),
    coupon_detail: coupon ? coupon : undefined,
    cellar_id: cellarId ? cellarId : '',
    order_detail: {
      ...prevDataSubscription.order_detail,
      items: organiceProductsMP(products, newShippingData, coupon, undefined, couponPriceDiscount, valueDiscountShipping),
      payer: organiceBillingDetailsMP(userData, form, true),
      shipments: {
        cost: 0,
        mode: newShippingData.type,
        receiver_address: {
          apartment: `${userData?.apartment}`,
          city_name: `${userData?.city}`,
          country_name: `${userData?.country.value}`,
          floor: `${userData?.houseNumber}`,
          state_name: `${userData?.state.value}`,
          street_name: `${userData?.address}`,
          street_number: `${userData?.reference}`,
          zip_code: `${userData?.zip_code}`
        }
      },
      total_amount: calculateWithDiscountSubscription(products, prevDataSubscription.subscription.percen_discount, prevDataSubscription.shippingPrice, prevDataSubscription.subscription, coupon, prevDataSubscription.order_detail.metadata.details_payments.numberShipping),
      metadata: {
        ...prevDataSubscription.order_detail.metadata,
        details_payments: {
          deliveryPrice: newShippingData.price,
          onlyIva: (paymentValues.onlyIva * prevDataSubscription.shippingNumbers) < 0 ? 0 : paymentValues.onlyIva * prevDataSubscription.shippingNumbers,
          subTotalNoIva: paymentValues.subTotalNoIva * prevDataSubscription.shippingNumbers,
          totalPayment: calculateWithDiscountSubscription(products, prevDataSubscription.subscription.percen_discount, prevDataSubscription.shippingPrice, prevDataSubscription.subscription, coupon, prevDataSubscription.order_detail.metadata.details_payments.numberShipping),
          purchasePaymentRef: calculateWithDiscountSubscription(products, prevDataSubscription.subscription.percen_discount, prevDataSubscription.shippingPrice, prevDataSubscription.subscription, coupon),
          purchaseShippingRef: finalShippingData.price,
          numberShipping: shipppingNumber
        },
        shippingDetails: {
          name: `${(userData?.name ? userData.name : '')} ${(userData?.last_name ? userData.last_name : '')}`,
          phone: userData?.phone ? userData?.phone : '',
          address1: organiceAddress(userData),
          city: userData?.city ? userData?.city.label : '',
          region: userData?.state.value ? userData?.state.label : '',
          country: userData?.country ? userData?.country.label : '',
          cityValue: userData?.city.value ? userData?.city.value : '',
          regionvalue: userData?.state.value ? userData?.state.value : '',
          countryValue: userData?.country.value ? userData?.country.value : ''
        },
        customer_id: '',
        delivery_note: dataFormCheckOut.delivery_note ? dataFormCheckOut.delivery_note : '',
        billingDetails: organiceBillingDetails(userData, form, true),
        contactDetails: {
          email: userData?.email ? userData?.email : '',
          firstName: userData?.name ? userData?.name : '',
          lastName: userData?.last_name ? userData?.last_name : '',
          phoneNumber: userData?.phone ? userData?.phone : '',
        },
        couponId: coupon && {
          amount: roundToXDigits(couponPriceDiscount, 2),
          id: coupon.id,
          code: coupon.claimCode,
          internalCouponName: coupon.internalCoupon.name,
          internalCouponNumber: coupon.internalCoupon.code,
          ...coupon.feature && {
            discountShipping: (calculateDiscountPriceShipping(coupon.feature, originalShippingValue).valueDiscount) * prevDataSubscription.shippingNumbers
          }
        },
        referred: referredCode && {
          amount: roundToXDigits(calculateTotal(products, false) - getPriceDescountReferred(products, referredCode.reference_data), 2),
          code: referredCode.code,
          id: referredCode.id,
          email: referredCode.email
        },
        products,
        hubspot_client_id: hubspotClientId,
        accessoriesSubscrptionValue: accesoriesPrice === 0 ? undefined : accesoriesPrice,
        cellar_id: cellarId ? cellarId : ''
      },
      cellar_id: cellarId ? parseInt(cellarId, 10) : '',
      country_id: countryId ? countryId : '',
      city_id: cityId ? cityId : '',
    }

  };

  return paymentData;
};

export const getCardType = (cardNumber: string) => {
  // Eliminar cualquier espacio en blanco o guiones del número de tarjeta
  const cleanedCardNumber = cardNumber.replace(/[^\d]/g, '');

  // Validar el número de tarjeta usando expresiones regulares
  const cardRegex = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    dinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/
  };

  // Verificar qué tipo de tarjeta coincide con el número proporcionado
  if (cardRegex.visa.test(cleanedCardNumber)) {
    return {
      method: 'visa',
      type: 'credit_card'
    };
  } else if (cardRegex.mastercard.test(cleanedCardNumber)) {
    return {
      method: 'master',
      type: 'credit_card'
    };
  } else if (cardRegex.amex.test(cleanedCardNumber)) {
    return {
      method: 'amex',
      type: 'credit_card'
    };
  } else
    return {
      method: 'visa',
      type: 'credit_card'
    };
};

export const getCardCategory = (cardNumber: string) => {
  // Obtener los primeros dígitos del número de tarjeta
  const firstDigit = Number(cardNumber.charAt(0));
  const firstTwoDigits = Number(cardNumber.substr(0, 2));

  // Verificar si los dígitos corresponden a una tarjeta de débito o crédito
  if (firstDigit === 4) {
    return 'credit_card';
  } else if (firstTwoDigits === 50 || (firstTwoDigits >= 56 && firstTwoDigits <= 69)) {
    return 'credit_card';
  } else if (firstTwoDigits >= 51 && firstTwoDigits <= 55) {
    return 'debit_card';
  } else {
    return 'credit_card';
  }
};

export const calculateDiscountPriceShipping = (featureChoosed: couponComplete['feature'], originalShippingValue: number): shippingDiscountCoupon => {
  const finalPrice = (originalShippingValue - ((originalShippingValue * featureChoosed.percent_discount) / 100));
  const discountValue = (originalShippingValue * featureChoosed.percent_discount) / 100;

  return {
    finalPrice,
    originalValueShipping: originalShippingValue,
    percentDiscount: featureChoosed.percent_discount,
    valueDiscount: discountValue
  };
};

export const getCountryId = (country: PixieContries) => {
  switch (country) {
    case 'MEX':
      return 1;
    case 'USA':
      return 2;
    case 'COL':
      return 3;
    case 'PAN':
      return 4;
    default:
      return 1;
  }
};
