export const footer = {
  footerContactTitle: 'Contact Information',
  footerEmail: 'help@pixie.pet',
  address: 'Medellin Street, number 148, Colonia Roma Norte, Alcaldia Cuauthemoc, Zip Code 06700, Mexico City',
  footerLinksTitle: 'Resources',
  footerQuestions: 'FAQ',
  footerTerms: 'Terms and Conditions',
  footerPolicy: 'Data Policy',
  footerPaymentTitle: '100% Secure Payment',
  footerPaymentDescr: '',
};
