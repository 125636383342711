import { ChangeEvent, useState } from 'react';
import Loading from '../../../../components/layout/loading';

import orderService from '../../../../services/userService/orderService';
import { useAppContext, useForm } from '../../../../hooks';
import { TestimonialForm } from '../../../../interfaces/user/orders';
import ContainerPopupUser from '../../../../components/common/containerPopupUser';
import FormTestimonialSection from '../myTestimonials/formSection';

const CreateTestimonials = ({ goToBack, reloadData, type = 'desktop' }: UserTestimonialsProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const { toast, user } = useAppContext();
  const { form, handleFormChange, onSubmit, handleSelectChange } = useForm<TestimonialForm>({
    coment: '',
    image_url: '',
    language: '',
    name: user ? user.user.name : '',
    pet_name: '',
    pet_age: 0,
    petOptions: [
      { label: 'Meses', value: 'months' },
      { label: 'Años', value: 'years' },
    ],
    petType: { label: '', value: '' }
  }, () => handleValidate());

  const handleValidate = () => {
    saveFiles();
  };

  const handleChangeText = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCounter(target.value.length);
  };

  const saveFiles = () => {
    setLoading(true);
    if (file)
      orderService.uploadFileTestimonial(file, form).then(res => {
        saveData(res);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
      });
    else
      saveData('');
  };

  const saveData = (imgKey: string) => {
    setLoading(true);
    let typeMedia: 'video' | 'image' | '' = '';
    if (file)
      typeMedia = file.type.split('/')[0] as 'video' | 'image';
    orderService.createTestimonial(form, imgKey, typeMedia).then(res => {
      setLoading(false);
      toast.fire({
        icon: 'success',
        title: 'Testimonio creado correctamente',
      });
      reloadData();
      goToBack();
    }).catch(err => {
      setLoading(false);
    });
  };

  const handleSetFile = (file: File | null) => {
    setFile(file);
  };

  return (
    <>
      {type === 'desktop' ?
        <ContainerPopupUser>
          <FormTestimonialSection
            counter={counter}
            form={form}
            handleFormChange={handleFormChange}
            handleSelectChange={handleSelectChange}
            goToBack={goToBack}
            onSubmit={onSubmit}
            handleSetFile={handleSetFile}
            file={file}
            handleChangeText={handleChangeText}
            type={type}
          />
        </ContainerPopupUser>
        :
        <FormTestimonialSection
          counter={counter}
          form={form}
          handleFormChange={handleFormChange}
          handleSelectChange={handleSelectChange}
          goToBack={goToBack}
          onSubmit={onSubmit}
          handleSetFile={handleSetFile}
          file={file}
          handleChangeText={handleChangeText}
          type='mobile'
        />
      }
      {loading && <Loading/>}
    </>
  );
};

interface UserTestimonialsProps {
  goToBack: () => void;
  reloadData: () => void,
  type?: 'mobil' | 'desktop'
}

export default CreateTestimonials;
