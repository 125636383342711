import { useEffect, useState } from 'react';

import CouponApply from '../../../../../../../components/common/couponApply';
import SelectList from '../../../../../../../components/form/selectList';

import { editSubscriptionUserForm, subscriptionUserComplete } from '../../../../../../../interfaces/subscription';
import { couponComplete } from '../../../../../../../interfaces/coupon';
import { ReferenceCode } from '../../../../../../../interfaces/user/references';
import { shippingDiscountCoupon } from '../../../../../../../interfaces/payment';

const CouponSubscriptionSection = ({ form, subscription, setForm, setValues }: CouponSubscriptionSectionProps) => {
  const [coupon, setCoupon] = useState<couponComplete>();
  const [referredConfiguration, setReferredConfiguration] = useState<ReferenceCode>();

  useEffect(() => {
    if (coupon)
      setForm(old => ({ ...old, coupon_detail: coupon }));
  }, [coupon]);

  const deleteCoupons = (values: string[]) => {
    setCoupon(undefined);
    setValues(undefined, undefined);
    setForm(old => ({ ...old, coupon_detail: undefined }));
  };

  return (
    <>
      {form.couponId?.id ?
        <SelectList
          placeholder='Cupón'
          selectOptions={[{ value: form.couponId.id, label: form.couponId.code }]}
          setValues={deleteCoupons}
          title='Cupón'
          values={[form.couponId.id]}
          limitSelects={1}
          className='shadow-subcsription py-4'
          showDelete={true}
          languaje='en'
        />
        :
        <div className='bg-white pt-3 px-5 rounded-[20px] shadow-subcsription'>
          <CouponApply
            couponState={coupon}
            email={subscription.general_order.payer.email}
            setCouponState={setCoupon}
            disabledCoupon={false}
            setConfigurationReferred={setReferredConfiguration}
            referred={referredConfiguration}
            isSubscriptionEdit={true}
          />
        </div>
      }
    </>
  );
};

interface CouponSubscriptionSectionProps {
  form: editSubscriptionUserForm,
  subscription: subscriptionUserComplete,
  setForm: React.Dispatch<React.SetStateAction<editSubscriptionUserForm>>,
  setValues: (couponData: couponComplete | undefined, valueDiscountShippingIn: shippingDiscountCoupon | undefined) => void
}

export default CouponSubscriptionSection;
