import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import validator from 'validator';

import Button from '../../../components/common/button';
import Icon from '../../../components/common/icon';
import IconButton from '../../../components/common/iconButton';
import RadioField from '../../../components/form/radioField';
import TextArea from '../../../components/form/textArea';

import { useAppContext, useForm } from '../../../hooks';
import { formDisabledSubscription, getOptionsDisabledSubscription, subscriptionUserComplete } from '../../../interfaces/subscription';
import subscriptionService from '../../../services/subscriptionService';
import useValidator from '../../../hooks/useValidator';

const StatusSubscription = ({ setShowPopup, subscription, updateSwichStatus }: StatusSubscriptionProps) => {
  const { toast, updateContext } = useAppContext();
  const [optionsRadio, setOptionsRadio] = useState<getOptionsDisabledSubscription[]>();
  const [radioSelected, setRadioSelected] = useState('');
  const [counter, setCounter] = useState(0);

  const handlerClosePopup = () => {
    setShowPopup(false);
  };

  const { form, handleFormChange, handleRadioChange, onSubmit } = useForm<formDisabledSubscription>({
    description: '',
    subscription_disabled_type: '',
    subscription_id: subscription.id
  }, () => handlevalidateForm());

  useEffect(() => {
    getOptionsRadio();
  }, []);

  const { validatorBody, handlePutMessageError, resetValidator } = useValidator({
    subscription_disabled_type: { message: '' }
  });

  const getOptionsRadio = () => {
    subscriptionService.getOptionsDisabledSubscription().then(res => {
      const dataTypes = res.sort((a, b) => {
        if (a.key === 'other')
          return 1;
        if (b.key === 'other')
          return -1;

        return -1;
      });

      setOptionsRadio(dataTypes);
    }

    ).catch(() => {
      toast.fire({
        icon: 'error',
        title: 'Ocurrio un error obteniendo la informacion.',
      });
    });
  };

  const handlevalidateForm = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (validator.equals(form.subscription_disabled_type, '')) {
      handlePutMessageError('subscription_disabled_type', 'Debes seleccionar un tipo');
      error = true;
    }

    if (!error)
      handleAcceptDelete();
  };

  const handleAcceptDelete = () => {
    subscriptionService.editStatusSubscriptionUser(subscription.id, subscription.status === '1' ? 0 : 1)
      .then(res => {
        handleSaveSubscriptionDisabled();
        updateSwichStatus(res.status === '1');
      })
      .catch(() => {
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: 'Error editando la suscripción.',
        });
      });
  };

  const handleSaveSubscriptionDisabled = () => {
    subscriptionService.sendSubscriptionDisabled(form).then(res => {
      toast.fire({
        icon: 'success',
        title: 'Suscripcion editadá correctamente.',
      });
      updateContext(old => ({ ...old, reloadData: true }));
      handlerClosePopup();
    }).catch(() => {
      toast.fire({
        icon: 'error',
        title: 'Error guardando los datos de la desactivación.',
      });
    });
  };

  const handleChangeText = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCounter(target.value.length);
  };

  return (
    <div className='bg-sixth lg:w-[30rem] mx-3 rounded-[20px] py-4 px-3 lg:px-4 font-sanzBold'>
      <div className='flex justify-end'>
        <IconButton.mini
          name='close'
          onClick={handlerClosePopup}
          className='text-black'
          size='2xl'
          sizeContainer='w-5 h-5'
        />
      </div>
      <div className='flex flex-col gap-5 justify-center items-center mb-7 mt-8'>
        <Icon name='question_mark'
          className='bg-fourth text-white w-12 h-12 flex justify-center items-center'
          size='3xl'
        />
        <p>Nos gustaría saber por qué te vas...</p>
      </div>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col gap-3 text-sm lg:px-7'>
          {optionsRadio?.map(option =>
            <RadioField
              changeState={setRadioSelected}
              currentState={radioSelected}
              handleRadioChange={handleRadioChange}
              name='subscription_disabled_type'
              value={option.id}
              label={option.name}
              key={option.id}
            />
          )}
          <span className='text-primary pl-2 text-xs lg:text-base'>{validatorBody.subscription_disabled_type.message && validatorBody.subscription_disabled_type.message}</span>
          <TextArea
            handler={e => {
              handleFormChange(e);
              handleChangeText(e);
            }}
            name='description'
            value={form.description}
            className='font-subTitles '
            placeholder='Cuéntanos tus razones'
            fieldClassName='h-32'
            border='rounded-[20px]'
            required
            maxLength={400}
          />
          <p className={`text-xs text-right ${counter === 400 && 'text-primary'}`}>{counter}/400</p>
        </div>
        <div className='flex justify-center items-center mt-8 mb-6 gap-2'>
          <Button
            className='text-primary border border-primary text-base'
            padding='py-1 px-6'
            onClick={handlerClosePopup}
          >
            Cancelar
          </Button>
          <Button
            className='text-[#FAD7B1] bg-[#DF2F44] text-base'
            padding='py-1 px-6'
            type='submit'
          >
            Apagar suscripción
          </Button>
        </div>
      </form>
    </div>
  );
};

interface StatusSubscriptionProps {
  setShowPopup: Dispatch<SetStateAction<boolean>>,
  subscription: subscriptionUserComplete,
  updateSwichStatus: Dispatch<SetStateAction<boolean>>
}

export default StatusSubscription;
