import { trashIcon } from '../../../assets/vectors';
import Button from '../../common/button';
import IconButton from '../../common/iconButton';

const PopupDecision = ({ handleAccept, handleDeny, title, textAccept, textDeny }: PopupDecisionProps) => (
  <div className='absolute z-1000 flex justify-center -top-[10px]  -bottom-[10px] bg-[#000000b6] left-[0px] right-[0px] items-start pt-44'>
    <div className='bg-sixth w-auto rounded-3xl pb-8 mx-3 lg:mx-0'>
      <div className='w-full flex justify-end pb-6 pt-4 pr-6'>
        <IconButton
          name='close'
          size='2xl'
          className='absolute'
          onClick={handleDeny}
          shadow={false}
          sizeContainer='w-4 h-4'
        />
      </div>
      <div className='px-[29px] flex flex-col justify-center items-center'>
        <div className='flex justify-center items-center rounded-full w-[48px] h-[48px] bg-fourth mb-2'>
          <img src={trashIcon} className='w-[22px] h-[22px] brightness-200' />
        </div>
        <div className='font-sanzSemiBold mb-7 text-center'>
          <span>{title}</span>
        </div>
        <div className='flex justify-center items-center gap-2 font-sanzSemiBold h-[30px]'>
          <Button className='border border-primary text-primary rounded-[10px]' padding='px-8 py-[0.2rem]' onClick={handleDeny}>{textDeny}</Button>
          <Button className='bg-primary text-[#fad7b1] rounded-[10px]' padding='px-10 py-[0.2rem]' onClick={handleAccept}>{textAccept}</Button>
        </div>
      </div>
    </div>
  </div>
);

interface PopupDecisionProps {
  title: string,
  handleAccept: () => void,
  handleDeny: () => void,
  textDeny: string,
  textAccept: string
}

export default PopupDecision;
