import { useEffect, useState } from 'react';

export const useHastParams = () => {
  const [hash, setHast] = useState<any>();

  useEffect(() => getParams(), []);

  // Reset function
  const getParams = () => {
    if (window.location.hash) {
      const hastParams = window.location.hash.split('&');
      hastParams.forEach((item, index) => {
        const separaText = item.split('=');
        separaText[0].split('#');
        if (index === 0)
          separaText[0] = separaText[0].split('#')[1];

        setHast((old: any) => ({ ...old, [separaText[0]]: separaText[1] }));
      });
    }
  };

  return { hash };
};

export default useHastParams;
