export const myProfile = {
  profilePersonalInfo: 'Personal Information',
  profileName: 'Name',
  profilePhone: 'Phone',
  profileEmail: 'Email',
  profileReferredCode: 'Referred code',
  profileMyAddresses: 'My addresses',
  profileCards: 'My cards',
  profileEmptyAddress: 'No saved addresses',
  profileCard: 'Card',
  profileEditProfile: 'Edit profile',
  profileShareCode: 'Share Code',
  profileNewAddress: 'New address',
  profileEditAddress: 'Edit Address',
  profileAddressinput1: 'Name of the address: example home, mother\'s house',
  profileAddressinput2: 'Name of the street'
};
