import { useEffect, useState } from 'react';
import Icon from '../../common/icon';
import FloatPopup from '../../common/floatPopup';
import { useAppContext } from '../../../hooks';
import { LocationShipping } from '../../../interfaces/location';

const SelectStore = () => {
  const [showShippingOption, setShowShippingOption] = useState(true);
  const [city, setCity] = useState('');
  const { updateContext, locationShipping, cellarSelected, workCities } = useAppContext();

  useEffect(() => {
    const haspreviousData: LocationShipping | null = JSON.parse(localStorage.getItem('shippingLocation') as string);

    if (haspreviousData)
      setShowShippingOption(false);

    if (workCities)
      setCity(workCities.city.name);
  }, [locationShipping, cellarSelected, workCities]);

  const handleShowPopup = () => {
    updateContext(old => ({ ...old, showPopupShippingCity: true }));
  };

  const handleCloseLocation = () => {
    setShowShippingOption(false);
    localStorage.setItem('shippingLocation', JSON.stringify(locationShipping));
  };

  return (
    <div className='bg-primary text-white font-subTitles text-xs md:min-w-[9.9rem] rounded-[10px] pr-5 pl-2 cursor-pointer relative'>
      <div className='flex gap-4' onClick={handleShowPopup}>
        <div className='w-1/5'>
          <Icon
            name='pin_drop'
            size='3xl'
            type='outlined'
          />
        </div>
        <div className='flex flex-col w-4/5'>
          <p>Tu envío se hará para:</p>
          <p className='font-sanzBold'>{city}</p>
        </div>
      </div>
      {showShippingOption &&
        <FloatPopup
          title={
            <div>
              <p className='text-grayText'>¿Vas a realizar el envío para</p>
              <p className='text-primary'>Ciudad de México?</p>
            </div>
          }
          className='absolute z-1000 -bottom-56 -right-16 text-shadown-none px-6 floating-object'
          description={
            <div className='text-grayText text-sm font-sanzSemiBold'>
              <p>Te mostraremos productos disponibles para esta ciudad y tiempos de entrega más precisos.</p>
            </div>
          }
          textButtonAccept='Cambiar'
          textButtonDeny='Mantener'
          accept={handleShowPopup}
          deny={handleCloseLocation}
        />
      }
    </div>
  );
};

export default SelectStore;
