import { OrderComplete, OrderStripeComplete, Testimonial, TestimonialForm } from '../../../interfaces/user/orders';
import api from '../../axios';

export class OrderService {
  init() {}

  getOrderByUserMP = (order: string): Promise<OrderComplete[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/purchase_orders/mine?limit=100&lang=ES&order=${order}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getOrderByUserST = (order: string): Promise<OrderStripeComplete[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/purchase_orders/mine?limit=100&lang=EN&order=${order}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  deleteFavorite = (order:OrderComplete, language: string): Promise<string> => {
    const token = window.localStorage.getItem('token');

    const data = {
      id: order.id,
      lang: 'ES'
    };

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/purchase_orders/delete',
        headers: { Authorization: token ? token : '' }, data
      }).then(res => {
        if (language === 'en')
          resolve(res.data.message_en);
        else
          resolve(res.data.message);
      }).catch(error => {
        if (language === 'en')
          reject(error.response.data.message_en);
        else
          reject(error.response.data.message);
      }
      );
    });
  };

  deleteFavoriteST = (order:OrderStripeComplete, language: string): Promise<string> => {
    const token = window.localStorage.getItem('token');

    const data = {
      id: order.id,
      lang: 'EN'
    };

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/purchase_orders/delete',
        headers: { Authorization: token ? token : '' }, data
      }).then(res => {
        if (language === 'en')
          resolve(res.data.message_en);
        else
          resolve(res.data.message);
      }).catch(error => {
        if (language === 'en')
          reject(error.response.data.message_en);
        else
          reject(error.response.data.message);
      }
      );
    });
  };

  getTestimonialsByUser = (): Promise<Testimonial[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/testimonials/mines', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getTestimonials = (): Promise<Testimonial[]> => new Promise((resolve, reject) => {
    api.get('pixie-customers/api/testimonials/availables', {
    })
      .then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error?.response?.data?.message);
      }
      );
  });

  uploadFileTestimonial = (file: File, testimonialForm: TestimonialForm): Promise<string> => {
    const authorization = window.localStorage.getItem('token');
    const formData = new FormData();
    const newName = `petImg-${testimonialForm.pet_name.replace(' ', '-')}-${testimonialForm.name.replace(' ', '-')}`;
    formData.append('file', file, newName);
    formData.append('folder', 'testimonials');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/uploads',
        headers: {
          Authorization: authorization ? authorization : '',
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      })
        .then(response => {
          resolve(response.data.key);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  createTestimonial = (form: TestimonialForm, imgUrl: string, typeMedia: 'video' | 'image' | ''): Promise<TestimonialForm> => {
    const authorization = window.localStorage.getItem('token');

    const newData = {
      ...form,
      image_url: imgUrl ? `${process.env.REACT_APP_URL_S3}${imgUrl}` : '',
      language: 'ES',
      type_media: typeMedia,
      pet_age_type: form.petType.value
    };

    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/testimonials', newData, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };
}

export default new OrderService();

