import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { iconCalendar, iconFavorite, iconMyPets, iconPixieFood, iconUserDefault, rateReview } from '../../../assets/vectors';
import { useAppContext, useLogout } from '../../../hooks';
import { AllDataUser, editUser, User } from '../../../interfaces/user/user';
import profileService from '../../../services/userService/profileService';
import Button from '../../common/button';
import ItemSideBar from '../../common/itemSideBar';
import ProfileImage from '../../common/profileImage';

const SideBar = ({ className }: SideBarProps) => {
  const { pathname } = useLocation();
  const { user, updateContext, location } = useAppContext();
  const logout = useLogout();
  const { t } = useTranslation();

  const updateUser = async (file: FileList | null) => {
    const keyImage = await uploadPhoto(file);
    // Build data to update User
    const userData: editUser = {
      last_name: user?.user.last_name as string,
      name: user?.user.name as string,
      phone: user?.user.phone as string,
      img: process.env.REACT_APP_URL_S3 + keyImage
    };
    profileService.editPerson(userData, user ? user?.user.id : '').then(res => {
      if (file)
        updateContext(old => ({ ...old,
          user: { ...old.user as AllDataUser,
            user: { ...old.user?.user as User,
              img: URL.createObjectURL(file[0]) } } }));
    });
  };

  const uploadPhoto = async (file: FileList | null) => {
    let keyImg = '';
    if (file)
      await profileService.uploadProfileImage(file, user ? user.user.id : '').then(res => {
        keyImg = res;
      }
      ).catch(error => {
        console.log(error);
      });

    return keyImg;
  };

  return (
    <div className={`h-full bg-[#D9EEEF] py-24 flex flex-col items-center px-8 gap-9 lg:gap-14 lg:py-16 ${className && className}`}>
      <ProfileImage
        name={user?.user.name}
        handleChangeImg={(file: FileList | null) => {
          updateUser(file);
        }}
        id='profileImage'
        img={user?.user.img}
      />
      <div className='grid grid-cols-2 w-full gap-9 lg:grid-cols-1 lg:gap-8'>
        <ItemSideBar
          text={t('userMenuMyProfile')}
          imageURL={iconUserDefault}
          isActive={pathname.includes('/usuario/perfil')}
          navigateUrl='/usuario/perfil'
        />
        <ItemSideBar
          text={t('userMenuMyPets')}
          imageURL={iconMyPets}
          textClassName='px-2 leading-none'
          isActive={pathname.includes('/usuario/mascotas')}
          navigateUrl='/usuario/mascotas'
        />
        {location !== 'USA' &&
          <ItemSideBar
            text={t('userMenuSubscription')}
            imageURL={iconCalendar}
            navigateUrl='/usuario/suscripciones/historial'
            isActive={pathname.includes('/usuario/suscripciones/historial')}
          />
        }
        <ItemSideBar
          text={t('userMenuOrderHistory')}
          imageURL={iconPixieFood}
          textClassName='leading-none'
          navigateUrl='/usuario/ordenes/historial'
          isActive={pathname.includes('/usuario/ordenes/historial')}
        />
        <ItemSideBar
          text={t('userMenuFavorites')}
          imageURL={iconFavorite}
          navigateUrl='/usuario/productos/favoritos'
          isActive={pathname.includes('/usuario/productos/favoritos')}
          ClassNameContaninerImg='lg:p-2'
        />
        <ItemSideBar
          text={t('userMenuTestimonials')}
          imageURL={rateReview}
          navigateUrl='/usuario/testimonios'
          isActive={pathname.includes('/usuario/testimonios')}
          ClassNameContaninerImg='lg:p-2'
        />
      </div>
      <Button className='font-sanzSemiBold border border-[#4A4A4A] lg:mt-5' padding='px-14 py-3' onClick={logout}>
        {t('userMenuLogOut')}
      </Button>
    </div>
  );
};

interface SideBarProps {
  className?: string
}

export default SideBar;
