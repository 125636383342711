/* eslint-disable quotes */
export const catalogue = {
  catHeader: 'Catalog, pet food',
  catDescription: "Discover Pixie's Wet Food Selection: Browse our store for top-notch wet pet food. Give your pets the nutrition they deserve.",
  // Calc - Banner
  catBannerItem1: 'Better condition of',
  catBannerItem1_2: 'the coat and skin.',
  catBannerItem2: 'Helps digestion and ',
  catBannerItem2_2: ' gastrointestinal transit',
  catBannerItem3: 'Strengthens the immune system',

  // Calc - filters
  catFiltersTitle: 'Do you have a dog or cat?',
  catFiltersTitle2: 'Its your pet a puppy, ar adult or a senior?',
  catFilterDog: 'Dogs',
  catFilterCat: 'Cats',
  catFilterPuppies: 'Puppies',
  catFilterAdults: 'Adults',
  catFilterSenior: 'Senior',
  catCategoryAliments: 'Recipes',
  catCategorySnacks: 'Snacks',
  catCategoryAccessories: 'Accessories',
  catNotResults: 'There are no coincidences',
};
