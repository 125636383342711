import { trashIcon } from '../../../assets/vectors';
import Button from '../../common/button';
import Icon from '../../common/icon';
import SelectField, { SelectItem } from '../selectField';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import useForm from '../../../hooks/useForm';
import { useEffect, useState } from 'react';
import { Product } from '../../../interfaces/product';
import TextField from '../textField';
import { CartItem } from '../../../interfaces/basket';
import { capitalize } from '../../../helpers/capitalize';

const SelectListProduct = ({ title, placeholder, values, bgColor, className, setValues, selectOptions, listClassName, showDelete = true }: SelectListProps) => {
  const [hasError, setError] = useState(false);
  const [valuesLabel, setvaluesLabel] = useState<CartItem[]>(values);
  const { handleSelectChange, form, setForm } = useForm({
    selectOptions,
    selectOptionsSelect: [{ label: '', value: '' }],
    itemSelected: { label: '', value: '' },
  },
  () => handleSubmit);

  useEffect(() => {
    const selectOptionsTemp: SelectItem[] = selectOptions.map(opt => ({ label: capitalize(`${opt.name} ${opt.age}`), value: `${opt.id}` }));

    setForm(old => ({ ...old, selectOptionsSelect: selectOptionsTemp }));
  }, [selectOptions]);

  // Fill select and find values label
  useEffect(() => {
    setvaluesLabel([]);
    const selectFilter:Product[] = [];
    selectOptions.forEach(option => {
      const optionFind = values?.find(item => item.product.id === option.id);
      if (optionFind === undefined)
        selectFilter.push(option);
    });
    setForm(old => ({ ...old, selectOptions: selectFilter }));

    values?.forEach(val => {
      setvaluesLabel(old => ([...old, { product: val.product, quantity: val.quantity }]));
    });
  }, [selectOptions, values]);

  // Show icon error for 1/2 seconds
  useEffect(() => {
    if (hasError)
      setTimeout(() => {
        setError(false);
      }, 500);
    return () => {};
  }, [hasError]);

  // Reorder array form with dragtable
  const reorder = (list: CartItem[], startIndex:number, endIndex:number):CartItem[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const deleteItem = (list:CartItem[], index: number) => {
    const newValues = [...list];
    newValues?.splice(index, 1);
    console.log(newValues);
    setValues(newValues);
  };

  const handleSubmit = () => {
    const value = values.find(item => item.product.id.toString() === form.itemSelected.value);
    if (value || form.itemSelected.value === '')
      setError(true);
    else {
      const newValues = values ? [...values] : [];
      const findValue = selectOptions.find(item => item.id.toString() === form.itemSelected.value);
      if (findValue) {
        const newProduct:CartItem = { product: findValue, quantity: 1 };
        newValues.push(newProduct);
        setValues(newValues);
      }
    }
  };

  // Handle Drag
  const handleDragEnd = (res: DropResult) => {
    const { source, destination } = res;
    if (!destination)
      return;

    setValues(reorder(values ? values : [], source.index, destination.index));
  };

  const handleChangeQuantity = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: CartItem, index: number) => {
    const valueProduct = values[index];
    const newValues = [...values];
    newValues?.splice(index, 1);
    setValues([...newValues, { product: valueProduct.product, quantity: Number(target.value) }]);
  };

  return (
    <div className={`${bgColor ? bgColor : 'bg-white'}
    pt-2 px-5 rounded-[20px] flex flex-col gap-4
    ${className}
    `}>
      <span className='text-lg font-sanzBold'>{title}</span>
      <div className='flex gap-3 items-center'>
        <span className='w-1/6'>cantidad </span>
        <div className='relative w-5/6'>
          <SelectField
            name='itemSelected'
            onChange={handleSelectChange}
            options={form.selectOptionsSelect}
            value={form.itemSelected.value ? form.itemSelected : undefined}
            backgroundColor='#F0F6F6'
            borderRadius={true}
            placeholder={placeholder}
            paddingY='0.4rem'
            dropdownIndicatorColor='#33B5A9'

          />
          <Button
            className={`absolute rounded-full  w-[40px] h-[40px] flex justify-center items-center top-1 right-2
              ${hasError ? 'bg-redPixie' : 'bg-pixieLightBlue'}
            `}
            rounded={true}
            onClick={handleSubmit}
          > {hasError
              ?
              <Icon name='close' className='text-white animate__animated animate__flash' size='2xl'/>
              :
              <Icon name='arrow_forward' className='text-white' size='2xl'/>
            }
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={title}>{
          droppableProvider =>
            <ul {...droppableProvider.droppableProps}
              ref={droppableProvider.innerRef}
              className={`
              max-h-[180px] flex flex-col gap-3 overflow-y-auto scroll-blue relative
              ${listClassName && listClassName}
              `}>
              {values.map((res, index) => (
                <Draggable key={res.product.id} draggableId={`${res.product.id}`} index={index}>
                  {draggableProvider =>
                    <li
                      className='flex pb-2 pr-1 position-static-important'
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.draggableProps}
                      {...draggableProvider.dragHandleProps }
                    >
                      <div className='flex w-full'>
                        <TextField
                          handler={e => handleChangeQuantity(e, res, index)}
                          name={`${res.product.id}`}
                          type='number'
                          value={res.quantity}
                          min={0}
                          fieldClassName='bg-[#F0F6F6] input-hidden-spin text-center'
                          className='w-1/6 pr-3'
                        />
                        <div className='flex justify-between w-5/6 items-center  border-b border-pixieLightBlue '>
                          <div className='flex items-center gap-3 '>
                            <Icon name='drag_handle' className='text-gray-400'/>
                            <span>
                              {capitalize(`${res.product.name} ${res.product.age}`)}
                            </span>
                          </div>
                          {showDelete &&
                            <img src={trashIcon} className='text-lg cursor-pointer w-4 h-4' alt='trashIcon' onClick={() => deleteItem(valuesLabel, index)}/>
                          }
                        </div>
                      </div>
                    </li>
                  }
                </Draggable>
              ))}
              {droppableProvider.placeholder}
            </ul>
        }
        </Droppable>
      </DragDropContext>
    </div>
  );
};

interface SelectListProps {
  title: string,
  placeholder: string,
  setValues: (values:CartItem[]) => void,
  values: CartItem[],
  bgColor?: string,
  className?: string,
  listClassName?: string,
  selectOptions: Product[],
  showDelete?: boolean
}

export default SelectListProduct;
