import { calculateGrs, formatter, roundToXDigitsString } from '../../../helpers/productHelper';
import { capitalize } from '../../../helpers/capitalize';
import { CartItem } from '../../../interfaces/basket';
import { notImage } from '../../../assets/vectors';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../hooks';

const ItemShoppingCarMini = ({ item, location = 'MEX', discount = 1 }: ItemShoppingCarMiniProps) => {
  // Translate
  const {
    i18n: { language },
  } = useTranslation();
  const { isSubscriptionSelected } = useAppContext();
  const [value, setValue] = useState(item.quantity * item.product.price);

  useEffect(() => {
    if (isSubscriptionSelected)
      setValue((item.quantity * item.product.price) - (((item.quantity * item.product.price) * discount) / 100));
    else
      setValue(item.quantity * item.product.price);
  }, [discount, item]);

  return (
    <div className='flex pt-[0.8rem] pb-[1rem] lg:py-4'>
      <figure className='w-[22.33%] lg:w-[24%]'>
        {item.product.url_image ? (
          <img
            src={`${process.env.REACT_APP_URL_S3}${item.product.url_image}`}
            className='w-[60px] h-[70px] rounded-2xl object-cover lg:w-20 lg:h-[82px]'
          />
        ) : (
          <img src={notImage} className='w-[60px] h-[70px] rounded-2xl lg:w-20 lg:h-[82px]' />
        )}
      </figure>
      <div className='flex justify-between w-[78.67%] lg:w-[76%] lg:pt-3'>
        <div className='flex flex-col text-xs justify-between'>
          <div className='flex flex-col'>
            <span className='text-sm text-pixieLightBlue lg:font-sanzBold lg:text-base gt-product-buy'>
              {language === 'en' ? capitalize(`${item.product.name_en}`) : capitalize(item.product.name)}
            </span>
            {location === 'USA' && (
              <span className='text-sm text-pixieLightBlue font-sanzBold'>
                {(item.product.kind_pet.toLocaleLowerCase() === 'cat' || item.product.category === 'Snacks') ? (
                  <>({t('productBoxInfoX40')})</>
                ) : (
                  <>({t('productBoxInfoX20')})</>
                )}
              </span>
            )}
            <span className='font-sanzSemiBold lg:text-xs'>{calculateGrs(item)}</span>
          </div>
        </div>
        <div className='flex flex-col items-end lg:gap-[1px] lg:pr-1'>
          <span className='text-sm lg:font-sanzBold lg:text-base lg:leading-7 gt-product-buy-price'>
            {location === 'USA' ? (
              <>${formatter(item.quantity * item.product.price, 'USD')}</>
            ) : (
              <>${roundToXDigitsString(value, 2)}</>
            )}
          </span>
          <span className='font-sanzSemiBold text-xs gt-product-buy-quantity'>
            {item.quantity === 1 ? `${item.quantity} ${t('basketUnity')}` : `${item.quantity} ${t('basketUnits')}`}
          </span>
        </div>
      </div>
    </div>
  );
};

interface ItemShoppingCarMiniProps {
  item: CartItem;
  location?: string;
  discount?:number
}

export default ItemShoppingCarMini;
