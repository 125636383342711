import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '../../../components/common/icon';
import IconButton from '../../../components/common/iconButton';

import { catProfileDefault, dogProfileDefault } from '../../../assets/images';
import { CatIcon, DogIcon } from '../../../assets/vectors';
import { Pet } from '../../../interfaces/user/pets';

const types = [{ value: 'cat', label: 'Gato' }, { value: 'dog', label: 'Perro' }];
const typesEn = [{ value: 'cat', label: 'Cat' }, { value: 'dog', label: 'Dog' }];
const activityList = [{ value: 'low', label: 'Baja' }, { value: 'medium', label: 'Normal' }, { value: 'high', label: 'Alta' }];
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const monthEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octuber', 'November', 'December'];
const diseasesList = [{ value: 'hepatics', label: 'Hepática' }, { value: 'renal', label: 'Renal' }, { value: 'obesity', label: 'Obesidad' }, { value: 'alergies', label: 'Alergia' }, { value: 'sensitive_stomach', label: 'Estómago sensible' }, { value: 'other', label: 'Otras' }];

const PetProfileItem = ({ pet, setShowPopupEdit }:PetCartProps) => {
  const [age, setAge] = useState(0);
  const [dateBirth, setDateBirth] = useState('');
  const [diseases, setDiseases] = useState([]);
  const navigate = useNavigate();
  const { t, i18n: { language } } = useTranslation();

  useEffect(() => calculateAge(), [pet, language]);
  useEffect(() => setDiseases(JSON.parse(pet.diseases)), [pet]);

  const calculateAge = () => {
    const today = new Date();
    const birth = new Date(pet.date_birth);
    if (language === 'en')
      setDateBirth(monthEn[birth.getMonth()]);
    else
      setDateBirth(months[birth.getMonth()]);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birth.getDate()))
      age--;

    setAge(age);
  };

  const handleSeeList = () => {
    navigate('/usuario/mascotas');
  };

  return (
    <div className='pt-24 lg:pt-0 lg:pl-20'>
      <div className='relative rounded-[20px] flex flex-col justify-center items-center px-5 gap-5 pb-7 lg:gap-0 lg:shadow-md'
        style={{ backgroundColor: pet.color }}
      >
        <div
          className={`absolute -top-28 rounded-full w-[13.4rem] h-[13.4rem] flex justify-center items-center z-10  border-2 border-white lg:-top-0 lg:-left-28 lg:w-[15.4rem] lg:h-[15.4rem]
          ${pet.img ? 'pt-0' : 'pt-6'} ${pet.type === 'cat' ? 'bg-[#7AC5BE]' : 'bg-[#F7EBA8]'} `}
        >
          <img src={pet.img ? pet.img : pet.type === 'cat' ? catProfileDefault : dogProfileDefault} className={`w-full h-full ${pet.img ? 'rounded-full object-cover' : 'object-contain rounded-b-full '} `}/>
        </div>
        <div className='flex justify-between w-full pb-5 pt-10 lg:pb-0 lg:pt-3 lg:justify-end'>
          <IconButton
            name='arrow_back_ios'
            onClick={handleSeeList}
            className='text-white lg:hidden'
            shadow={false}
          />
          <IconButton
            name='edit'
            onClick={() => setShowPopupEdit(true)}
            className='bg-white text-pixieLightBlue'
            shadow={false}
            sizeContainer='w-9 h-9'
          />
        </div>
        <div className='lg:pl-36 w-full -top-4'>
          <p className='text-[30px] w-full text-center lg:text-left lg:text-[40px] lg:mb-10 lg:leading-none'>
            {pet.name}
          </p>
          <div className='grid grid-cols-3 w-full justify-center items-start gap-10 lg:justify-start lg:items-start lg:grid-cols-4 lg:gap-2'>
            <ItemProfile
              icon={pet.type === 'cat' ? CatIcon : DogIcon}
              text={`${language === 'en' ? typesEn.find(item => item.value === pet.type)?.label : types.find(item => item.value === pet.type)?.label}`}
              title={`${t('myPetSpecies')}:`}
            />
            <ItemProfile
              icon='pets'
              isIcon={true}
              text={`${age} ${language === 'en' ? 'years' : 'Años'}`}
              title={`${t('age')}:`}
            />
            <ItemProfile
              icon='scale'
              isIcon={true}
              text={`${pet.weight} kl`}
              title={`${t('weight')}:`}
            />
            <ItemProfile
              icon='sports_baseball'
              isIcon={true}
              text={`${activityList.find(item => item.value === pet.activity)?.label}`}
              title={`${t('PhysicalActivity')}:`}
            />
            <ItemProfile
              icon='cake'
              isIcon={true}
              text={dateBirth}
              title={`${t('myPetsBirthday')}:`}
              className='lg:hidden'
            />
            <ItemProfile
              icon='medical_services'
              isIcon={true}
              text=''
              title={`${t('myPetsDiseases')}:`}
              className={`${diseases.length === 0 ? 'hidden' : 'lg:hidden'}`}
            >
              <ul className='flex flex-col lg:flex-row'>
                {diseases.map((item, index) =>
                  <li key={index}>
                    {diseasesList.find(dis => dis.value === item)?.label},
                  </li>
                )}
              </ul>
            </ItemProfile>
          </div>
          <div className='mt-4 w-full lg:mb-3 hidden lg:flex'>
            <ItemProfile
              icon='cake'
              isIcon={true}
              text={dateBirth}
              title={`${t('myPetsBirthday')}:`}
              className='lg:w-1/4'
            />
            <ItemProfile
              icon='medical_services'
              isIcon={true}
              text=''
              title={`${t('myPetsDiseases')}:`}
              className={`${diseases.length === 0 && 'hidden '} lg:w-2/4`}
            >
              <ul className='flex flex-col lg:flex-row'>
                {diseases.map((item, index) =>
                  <li key={index}>
                    {diseasesList.find(dis => dis.value === item)?.label},
                  </li>
                )}
              </ul>
            </ItemProfile>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PetCartProps {
  pet:Pet,
  setShowPopupEdit: Dispatch<SetStateAction<boolean>>
}

const ItemProfile = ({ icon, text, className, title, isIcon = false, children }:ItemProfileProps) => (
  <div className={`${className && className} flex flex-col justify-center items-center w-full lg:items-start lg:flex-row lg:gap-2`}>
    <div className='lg:w-auto'>
      <div className='bg-[#F7EBA8] rounded-full w-10 h-10 p-[0.30rem] flex justify-center items-center lg:w-8 lg:h-8'>
        {isIcon
          ?
          <Icon name={icon} type='outlined' className=''/>
          :
          <img src={icon} className='w-full h-full brightness-0'/>
        }
      </div>
    </div>
    <div className='flex flex-col w-full lg:justify-start lg:items-start'>
      <span className='text-xs font-sanzSemiBold flex justify-center items-center text-center leading-none'>
        {title}
      </span>
      <span className='text-white font-sanzBold text-lg leading-none text-center'>
        {children
          ?
          <>{children}</>
          :
          <> {text}</>
        }
      </span>
    </div>
  </div>
);

interface ItemProfileProps {
  icon: string,
  text: string,
  className?: string,
  title: string,
  isIcon?: boolean,
  children?: JSX.Element
}

export default PetProfileItem;
