export const catalogue = {
  catHeader: 'Catalogo, alimento para mascotas',
  catDescription: '¡Descubre Pixie! Tienda especializada en comida húmeda para perros y gatos. Amplia selección de productos naturales y deliciosos.',
  // Calc - Banner
  catBannerItem1: 'Condición de piel ',
  catBannerItem1_2: 'y pelaje más suaves.',
  catBannerItem2: 'Ayuda a la digestión ',
  catBannerItem2_2: 'y tránsito gastrointestinal.',
  catBannerItem3: 'Fortalecimiento del sistema inmune.',

  // Calc - filters
  catFiltersTitle: 'Filtra según tu caso:',
  catFiltersTitle2: '¿Tu mascota un cachorro, un adulto o un senior?',
  catFilterDog: 'Perros',
  catFilterCat: 'Gatos',
  catFilterPuppies: 'Cachorros',
  catFilterAdults: 'Adultos',
  catFilterSenior: 'Senior',
  catCategoryAliments: 'Alimentos',
  catCategorySnacks: 'Snacks',
  catCategoryAccessories: 'Accesorios',
  catNotResults: 'No hay resultados',
};
