import { useState } from 'react';
import { AllDataUser } from '../interfaces/user/user';
import authService from '../services/authService';
import useLogout from './useLogout';

export const useAuth = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [user, setUser] = useState<AllDataUser | undefined>(undefined);

  const token = localStorage.getItem('token') || false;

  const handleCheck = async () => {
    try {
      if (!token) return false;
      let response = false;

      setIsChecking(true);

      // Implement check token service
      await authService.verifyToken(token)
        .then(res => {
          setUser(res);
          response = true;
          localStorage.setItem('token', res.token);
        })
        .catch(err => {
          localStorage.removeItem('token');
          useLogout();
          response = false;
        });

      return response;
    } catch (error: any) {
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  return { checkToken: handleCheck, old: token, isChecking, user };
};

export default useAuth;
