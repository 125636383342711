import TextField from '../../../../../../components/form/textField';
import { edituserAndAddressEcommerceValidate, editUserSubscription } from '../../../../../../interfaces/subscription';

const ClientDataSection = ({ form, handleFormChange, validatorBody }: ClientDatasectionProps) => (
  <div className='flex flex-col gap-6'>
    <div className='grid grid-cols-2 gap-4 text-sm'>
      <TextField
        handler={handleFormChange}
        fieldClassName='py-[0.95rem] bg-[#D9EEEF]'
        name='name'
        value={form.name}
        placeholder='Nombres'
        messageError={validatorBody.name.message}
      />
      <TextField
        handler={handleFormChange}
        fieldClassName='py-[0.95rem] bg-[#D9EEEF]'
        name='last_name'
        value={form.last_name}
        placeholder='Apellidos'
        messageError={validatorBody.last_name.message}
      />
      <TextField
        handler={handleFormChange}
        fieldClassName='py-[0.95rem] bg-[#D9EEEF]'
        name='email'
        value={form.email}
        placeholder='Correo electrónico'
        messageError={validatorBody.email.message}
      />
      <TextField
        handler={handleFormChange}
        fieldClassName='py-[0.95rem] bg-[#D9EEEF]'
        name='phone'
        value={form.phone}
        placeholder='Teléfono'
        messageError={validatorBody.phone.message}
      />
      <p className='text-base ml-7 mt-6'>
        <span className='font-sanzBold'>Medio de pago: </span>
        Master card
      </p>
    </div>
  </div>
);

interface ClientDatasectionProps {
  handleFormChange: ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  form: editUserSubscription,
  validatorBody: edituserAndAddressEcommerceValidate,
}

export default ClientDataSection;
