export const orderHistory = {
  orderHistoryTitle: 'Order history',
  orderHistoryOrder: 'sort by',
  orderHistoryDate: 'Date of purchase:',
  orderHistoryShipping: 'Shipping date:',
  orderhistoryProducts: 'Products:',
  orderHistoryAdress: 'Address',
  orderHistoryAgain: 'Repurchase',
  orderHistoryStatusShipping: 'No shipped'
};
