import { MultiValue, SingleValue } from 'react-select';
import Button from '../../../../../components/common/button';
import DragAndDrop from '../../../../../components/common/dragAndDropFiles';
import IconButton from '../../../../../components/common/iconButton';
import SelectField, { SelectItem } from '../../../../../components/form/selectField';
import TextArea from '../../../../../components/form/textArea';
import TextField from '../../../../../components/form/textField';
import { TestimonialForm } from '../../../../../interfaces/user/orders';
import { ChangeEvent, FormEvent } from 'react';

const FormTestimonialSection = ({ counter, form, goToBack, handleChangeText, handleFormChange, handleSelectChange, handleSetFile, onSubmit, file, type }: FormTestimonialSectionProps) => (
  <div className={`flex flex-col items-start font-subTitles px-4 lg:px-12 gap-5 lg:gap-10 lg:py-14 
    ${type === 'desktop' && 'bg-sixth rounded-[20px] w-full overflow-y-auto scroll-gray relative max-h-[80vh]'}
    ${type === 'mobile' && 'w-full'}
  `}>
    {type === 'desktop' &&
      <IconButton
        name='close'
        onClick={goToBack}
        className='absolute top-6 right-4'
        shadow={false}
        size='2xl'
      />
    }
    <div className='flex justify-between w-full items-center'>
      <h2 className='font-titles text-pixieLightBlue text-xl lg:text-[25px]'>{'Nueva reseña'}</h2>
    </div>
    <form
      onSubmit={onSubmit}
      className='flex flex-col w-full gap-5'
    >
      <div className='flex flex-col lg:flex-row w-full gap-4'>
        <TextField
          handler={handleFormChange}
          fieldClassName='py-[0.95rem] bg-[#fff]'
          name='name'
          value={form.name}
          placeholder='Tu nombre'
          className='w-full lg:w-1/2'
          required
        />
        <TextField
          handler={handleFormChange}
          fieldClassName='py-[0.95rem] bg-[#fff]'
          name='pet_name'
          value={form.pet_name}
          placeholder='Nombre de tu mascota'
          className='w-full lg:w-1/2'
          required
        />
      </div>
      <div className='flex flex-col lg:flex-row lg:w-[49%] gap-4'>
        <TextField
          handler={handleFormChange}
          fieldClassName='py-[0.95rem] bg-[#fff]'
          name='pet_age'
          value={form.pet_age === 0 ? '' : form.pet_age}
          type='number'
          placeholder='Edad de tu mascota'
          className='w-full lg:w-1/2'
          required
          min={0}
        />
        <SelectField
          name='petType'
          onChange={handleSelectChange}
          options={form.petOptions}
          value={form.petType.value ? form.petType : undefined}
          borderRadius={false}
          borderRadiuspx='999px'
          dropdownIndicatorColor='#33B5A9'
          className='w-full lg:w-1/2 font-subTitles text-sm'
          paddingY='0.7rem'
          placeholder='Años/Meses'
          colorPlaceholder='#9ca3b0'
          fontSize={type === 'desktop' ? '20px' : '16px'}
        />
      </div>
      <TextArea
        handler={e => {
          handleFormChange(e);
          handleChangeText(e);
        }}
        name='coment'
        fieldClassName='h-[140px] px-[22px] lg:py-4 lg:h-[190px]'
        value={form.coment}
        labelClassName='text-sm mb-2'
        placeholder={'Escribe tu reseña'}
        border='outline-none ring-0 transform transition-all border-0 ring-pixieLightBlue rounded-[20px]'
        maxLength={300}
        required
      />
      <p className={`text-sm text-right ${counter === 300 && 'text-primary'}`}>{counter}/300</p>
      <DragAndDrop
        setDataFile={handleSetFile}
        selectedFile={file}
      />
      <div className='flex justify-center lg:justify-end gap-2 text-base mt-8 font-sanzSemiBold'>
        <Button
          className='border border-primary text-primary'
          padding='px-8 py-1'
          onClick={goToBack}
        >
          Cancelar
        </Button>
        <Button
          className='bg-primary text-[#fad7b1]'
          padding='px-10 py-1'
          type='submit'
        >
          Enviar
        </Button>
      </div>
    </form>
  </div>
);

interface FormTestimonialSectionProps {
  goToBack: () => void;
  onSubmit: (e: FormEvent<Element>) => void;
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange: (selected: MultiValue<SelectItem> | SingleValue<SelectItem>, name: string) => void;
  form: TestimonialForm;
  handleChangeText: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  counter: number;
  handleSetFile: (file: File | null) => void;
  file: File | null;
  type: 'mobile' | 'desktop';

}
export default FormTestimonialSection;
