import { useEffect, useState } from 'react';
import moment from 'moment';
import { addDays, startOfDay, startOfMonth } from 'date-fns';

import RadioField from '../../../../../../components/form/radioField';
import SelectListProduct from '../../../../../../components/form/selectListProduct';
import TextCalendarField from '../../../../../../components/form/textCalendarField';

import { editSubscriptionUserForm, getSubscription, ShippingFrequencies, subscriptionUserComplete } from '../../../../../../interfaces/subscription';
import { Product } from '../../../../../../interfaces/product';
import subscriptionsServices from '../../../../../../services/subscriptionService';
import productService from '../../../../../../services/productService';
import { CartItem } from '../../../../../../interfaces/basket';

moment.updateLocale('es', {
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});

const LeftSectionEditSubscripcion = ({ subscription, form, handleRadioChange, setForm }: LeftSectionEditSubscripcionProp) => {
  const [shippingFrequencie, setShippingFrequencie] = useState<ShippingFrequencies[]>();
  const [subscriptions, setSubscriptions] = useState<getSubscription[]>();
  const [shippingFrequencieSelected, setShippingFrequencieSelected] = useState(form.shipping_frequencies_id);
  const [subscriptionSelected, setSubscriptionSelected] = useState(form.subscriptions_id);
  const [isModifyDay, setIsModifyDay] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [disabledDaysOfWeek, setDisabledDaysOfWeek] = useState([0, 1, 2, 3, 4, 5, 6]);

  const dueDate = startOfDay(new Date(form.dueDateRef));
  const dataCreated = moment(subscription.created_at);
  const toMonth = addDays(dueDate, 8);
  const fromMonth = startOfMonth(new Date(form.dueDateRef));

  const disabledDates = [];
  // eslint-disable-next-line no-unmodified-loop-condition
  for (const date = dueDate; date <= toMonth; date.setDate(date.getDate() + 1))
    if (disabledDaysOfWeek.includes(date.getDay()))
      disabledDates.push(new Date(date));

  const disabledDays = [
    { from: new Date(form.dueDateRef), to: new Date(1800) },
    ...disabledDates,
    { from: toMonth, to: new Date('2300-12-12') }
  ];

  useEffect(() => {
    getPreDataSelected();
    getAvailableDay();
  }, []);

  useEffect(() => {
    const subscriptionSelected = subscriptions?.find(sub => sub.id === form.subscriptions_id);
    if (subscriptionSelected)
      setForm(old => ({ ...old, subscriptions: subscriptionSelected }));
  }, [form.subscriptions_id]);

  useEffect(() => {
    const shippingFrequencies = shippingFrequencie?.find(ship => ship.id === form.shipping_frequencies_id);
    if (shippingFrequencies)
      setForm(old => ({ ...old, shipping_frequencies: shippingFrequencies }));
  }, [form.shipping_frequencies_id]);

  const getAvailableDay = () => {
    const city = subscription.general_order.metadata.shippingDetails.regionvalue;
    if (city === 'MX-QUE')
      setDisabledDaysOfWeek([0, 1, 2, 3, 5, 6, 7]);
    else if (city === 'MX-CMX' || city === 'MX-EDMX')
      setDisabledDaysOfWeek([0, 2, 4, 6]);
  };

  const getPreDataSelected = () => {
    // Get frequencies
    subscriptionsServices.getFrequencies()
      .then(res => {
        setShippingFrequencie(res);
      })
      .catch(error => {
        console.log(error);
      });
    // Get subscriptions
    subscriptionsServices.getSubscriptions()
      .then(res => {
        const subscriptionActive = res.filter(sub => sub.status === 1);
        setSubscriptions(subscriptionActive);
      }).catch(error => {
        console.log(error);
      });
    // Get products Mexico
    productService.getAllProducts(1, false)
      .then(res => {
        // Only active products and stock
        setProducts(res.products.filter(item => item.status === '1' && item.quantity > 0));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setValues = (values: CartItem[]) => {
    setForm(old => ({ ...old, products: values }));
  };

  return (
    <div className='flex flex-col text-sm gap-7'>
      <div className='flex flex-col gap-3'>
        <p>
          <span className='font-sanzBold'>Fecha creación: </span>
          {dataCreated.format('DD')} de {dataCreated.format('MMM')} {dataCreated.format('yy')}
        </p>
        <p>
          <span className='font-sanzBold'>Frecuencia entrega: </span>
          {form.shipping_frequencies.title}
        </p>
        <div className='flex gap-3 font-sanzSemiBold'>
          {shippingFrequencie?.sort((a, b) => a.value_days - b.value_days).map((ship, index) => (
            <RadioField
              key={`${ship.id}`}
              changeState={setShippingFrequencieSelected}
              currentState={shippingFrequencieSelected}
              handleRadioChange={handleRadioChange}
              name='shipping_frequencies_id'
              value={ship.id}
              className={`px-2 py-2 rounded-full bg-[#D9EEEF]
                ${index === 0 && 'w-3/12'}
                ${index === 1 && 'w-5/12'}
                ${index === 2 && 'w-4/12'}
              `}
              label={ship.title}
              iconCheckClassName='text-pixieLightBlue'
            />
          ))}
        </div>
      </div>

      <TextCalendarField
        nameField='calendar'
        placeholder='escoge el día'
        setValue={e => {
          setForm(old => ({ ...old, dueDate: moment(e).format('YYYY-MM-DD') }));
          setIsModifyDay(true);
        } }
        value={moment(form.dueDate).format('dddd')}
        toMonth={toMonth}
        fromMonth={fromMonth}
        disabledDays={disabledDays}
        textFielClassName='py-4 bg-[#D9EEEF]'
        border='rounded-full'
        className='w-2/3'
      />

      <div className='flex flex-col gap-6'>
        <p>
          <span className='font-sanzBold'>Frecuencia de pago: </span>
          {form.subscriptions.title}
        </p>
        <div className='flex gap-4 font-sanzSemiBold'>
          {subscriptions?.map(sub => (
            <RadioField
              key={sub.id}
              changeState={setSubscriptionSelected}
              currentState={subscriptionSelected}
              handleRadioChange={handleRadioChange}
              name='subscriptions_id'
              value={sub.id}
              className='px-2 py-3 rounded-full bg-[#D9EEEF]'
              label={sub.title}
              iconCheckClassName='text-pixieLightBlue'
            />
          ))}
        </div>
        {isModifyDay &&
          <div className='bg-[#D9EEEF] rounded-[20px] px-6 py-4 font-sanzBold'>
            Nota: Desde el siguiente debito recibirá su entrega todos los {form.dayOrder} {form.shipping_frequencies.title}, con una frecuencia de pago {form.subscriptions.title}
          </div>
        }
      </div>

      <SelectListProduct
        placeholder='Buscar producto'
        selectOptions={products}
        setValues={setValues}
        title='Detalle del pedido'
        values={form.products}
        className='shadow-subcsription py-4'
      />
    </div>
  );
};

interface LeftSectionEditSubscripcionProp {
  subscription: subscriptionUserComplete,
  form: editSubscriptionUserForm,
  handleRadioChange: (selected: string | number | boolean, name: string) => void,
  setForm: React.Dispatch<React.SetStateAction<editSubscriptionUserForm>>,
}

export default LeftSectionEditSubscripcion;
