import { quotesIcon } from '../../../assets/vectors';
import { capitalize } from '../../../helpers/capitalize';

const TestimonialComment = ({ confession, date, personName }: TestimonialCommentProps) => (
  <div className='flex flex-col gap-8 lg:w-3/5'>
    <p className='text-right font-sanzBold text-[13px] text-pixieLightBlue lg:text-lg'>{date}</p>
    <div className='lg:h-full lg:flex lg:justify-center lg:items-center'>
      <div className='relative mx-4 lg:flex lg:flex-col lg:justify-center lg:items-center w-full'>
        <img src={quotesIcon} className='w-6 h-6 absolute -left-3 -top-6 lg:-left-4 lg:-top-6 lg:w-10 lg:h-10'/>
        <img src={quotesIcon} className='w-6 h-6 absolute right-4 -bottom-7 lg:-right-0 lg:bottom-6 rotate-180 lg:w-10 lg:h-10'/>
        <p className='text-[13px] text-center md:text-base lg:text-xl lg:px-8 lg:leading-10 w-full'>
          {confession}
        </p>
        <p className='font-subTitles hidden text-lg mt-10 lg:block'>-{personName}-</p>
      </div>
    </div>
    <p className='font-subTitles text-[11px] text-center lg:hidden lg:text-lg capitalize'>-{capitalize(personName)}-</p>
  </div>
);

interface TestimonialCommentProps {
  personName: string,
  confession: string,
  date: string
}

export default TestimonialComment;
