export const myProfile = {
  profilePersonalInfo: 'Información personal',
  profileName: 'Nombre',
  profilePhone: 'Celular',
  profileEmail: 'Correo electrónico ',
  profileReferredCode: 'Código referido',
  profileMyAddresses: 'Mis direcciones',
  profileCards: 'Mis tarjetas',
  profileEmptyAddress: 'No hay direcciones guardadas',
  profileCard: 'Tarjeta',
  profileEditProfile: 'Editar perfil',
  profileShareCode: 'Compartir código',
  profileNewAddress: 'Nueva dirección',
  profileEditAddress: 'Editar dirección',
  profileAddressinput1: 'Nombre de la dirección: ejemplo casa, casa mamá',
  profileAddressinput2: 'Nombre de la calle'
};
