export const terms = {
  termsHeader: 'Terms and Conditions',
  termsDescription: 'Learn about our Terms and Conditions at Pixie. Transparency and trust for your shopping experience.',
  termsTitle: 'TÉRMINOS Y CONDICIONES (Términos del Servicio) SITIO WEB PIXIE MEXICO',

  // 1
  term1Title: 'Sección 1. GENERALIDADES (Información general, Aceptación y Elegibilidad)',
  term1Frag1: `Este sitio web es operado por SOMOS PIXIE SA de CV, con domicilio en Enrique Rebsamen, 725, Planta Alta,
    Oficina 4, Narvarte Poniente, Benito Juárez, 03020, Ciudad de México. En todo el sitio, los términos
    “Pixie”, “nosotros”, “nos” y “nuestro” se refieren a SOMOS PIXIE SA de CV. Los términos, “tu”, “usted”,
    “su” (en adelante también denominado como “El Cliente”) se refieren a cada persona o entidad que acceda al
    sitio web y utilice o compre los productos o servicios ofrecidos por PIXIE. El uso, productos y servicios
    que ofrece este sitio web, incluyendo toda la información, herramientas y servicios disponibles para el
    Cliente en este sitio, está condicionado a la aceptación de todos los términos, condiciones, políticas y
    notificaciones aquí establecidas, en caso de no aceptar las disposiciones de este acuerdo el Cliente no
    estará autorizado para utilizar los servicios de PIXIE.`,

  term1Frag2: `Al visitar nuestro sitio y/o comprarnos algo, El Cliente interactúa con nuestro &quot;Servicio&quot; y
    reconoce como vinculantes los siguientes términos y condiciones (denominados en lo sucesivo &quot;Términos
    del servicio&quot;, &quot;Términos&quot;), incluidos aquellos términos y condiciones adicionales y las
    políticas que se mencionan aquí y/o disponibles por medio de hipervínculos. Estos Términos del servicio se
    aplican a todos los usuarios del sitio, incluyendo de manera enunciativa más no limitativa los usuarios
    que son navegadores, proveedores, clientes, comerciantes y/o que aporten contenido.`,

  term1Frag3: `El Cliente debe leer estos Términos del servicio detenidamente antes de acceder o utilizar nuestra Sitio
    Web. Al acceder o utilizar cualquier parte del sitio, El Cliente acepta estos Términos del Servicio. Si no
    acepta la totalidad de los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni
    utilizar ningún servicio. Si estos Términos del servicio se considerasen una oferta, la aceptación se
    limita expresamente a los presentes Términos del servicio.`,

  term1Frag4: `Las nuevas funciones o herramientas que se agreguen a la Sitio Web actual también estarán sujetas a los
    Términos del servicio. Puede revisar la versión más reciente de los Términos del servicio en cualquier
    momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de
    los presentes Términos del servicio mediante la publicación de actualizaciones o cambios en nuestra Sitio
    Web. Es responsabilidad del Cliente revisar esta página periódicamente para ver los cambios. El uso del
    Sitio Web o el acceso de forma continuada después de la publicación de cualquier cambio constituye la
    aceptación de dichos cambios.`,

  term1Frag5: `Términos del sitio web: Al aceptar los presentes Términos del servicio, El Cliente declara que tiene la
    mayoría de edad en su territorio de residencia, y que nos ha dado su consentimiento para permitir que
    cualquiera de las personas menores que dependen de El Cliente utilice este sitio. Si se es menor de edad
    pero mayor de 13 años, se podrá usar el sitio bajo la supervisión de un padre o tutor legal.`,

  term1Frag6: `El Cliente no podrá utilizar nuestros productos con ningún propósito ilegal o no autorizado. Tampoco
    puedes, en el uso del Servicio, violar cualquier ley en tu jurisdicción (incluyendo pero no limitado a las
    leyes de derecho de autor). El incumplimiento o violación de cualquiera de estos Términos darán lugar al
    cese inmediato del Servicio. Nos reservamos el derecho de exigir un comprobante de la edad y/o aprobación
    escrita para el uso del sitio. No transmitirá ningún virus informático ni ningún código de naturaleza
    destructiva.`,

  term1Frag7: `El incumplimiento o violación de cualquiera de los Términos dará como resultado la rescisión inmediata de
    sus Servicios.`,

  // 2
  term2Title: 'Sección 2. CONDICIONES GENERALES',

  term2Frag1: `Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo
    y en cualquier momento.`,

  term2Frag2: `El Cliente comprende que su contenido (sin incluir la información de tu tarjeta de crédito), puede ser
    transferido sin encriptar e involucrar (a) transmisiones a través de varias redes; y (b) cambios para
    ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de
    tarjetas de crédito está siempre encriptada durante la transferencia a través de las redes. La información
    de la tarjeta de crédito siempre se cifra durante la transferencia a través de las redes conforme a los
    estándares de seguridad requeridos por la regulación aplicable y ofrecidos por los terceros que brindan el
    servicio de pasarela de pagos. Pixie no almacena datos de tarjetas de crédito.`,

  term2Frag3: `El Cliente acepta no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del
    Servicio, uso del Servicio, o acceso al Servicio o cualquier contacto en el sitio web a través del cual se
    presta el servicio, sin el expreso permiso por escrito de nuestra parte. Los títulos utilizados en este
    acuerdo se incluyen solo por conveniencia y no limita o afecta a estos Términos.`,

  // 3
  term3Title: 'Sección 3. Exactitud, exhaustividad y actualidad de la información',

  term3Frag1: `No nos hacemos responsables si la información disponible en este sitio no es exacta, completa o actual. El
    material en este sitio es provisto sólo para información general y no debe confiarse en ella o utilizarse
    como la única base para la toma de decisiones sin consultar primeramente, información más precisa,
    completa u oportuna. Cualquier dependencia en la materia de este sitio es bajo su propio riesgo.`,

  term3Frag2: `Este sitio puede contener cierta información histórica. La información histórica, no es necesariamente
    actual y es provista únicamente para tu referencia. Nos reservamos el derecho de modificar los contenidos
    de este sitio en cualquier momento, pero no tenemos obligación de actualizar cualquier información en
    nuestro sitio. Aceptas que es tu responsabilidad de monitorear los cambios en nuestro sitio.`,

  // 4
  term4Title: 'Sección 4. Modificaciones al servicio y precios',

  term4Frag1: 'Los precios de nuestros productos están sujetos a cambio sin previo aviso.',
  term4Frag2:
    'Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo.',
  term4Frag3:
    'No seremos responsables ante El Cliente ni ante ningún tercero por ninguna modificación, cambio de precio, suspensión o interrupción del Servicio.',

  // 5
  term5Title: 'Sección 5. SOBRE LOS PRODUCTOS Y SERVICIOS',

  term5Frag1: `Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web.
    Estos productos o servicios pueden tener cantidades limitadas y estar sujetas a devolución o cambio de
    acuerdo con nuestra política de devolución solamente.`,

  term5Frag2: 'Todos los productos están sujetos a disponibilidad.',

  term5Frag3: `Todas las imágenes son ilustrativas y podrán diferir del producto enviado en su color o presentación de
                empaque sin que se afecte el producto final. Estos legales cubren todas las publicaciones hechas en todos
                los medios.`,

  term5Frag4: `Promociones: Las promociones no son acumulables con otras promociones. Aplican hasta agotar existencias y
    están restringidas a una por persona. Las campañas y programas promocionales podrán tener un documento de
    términos y condiciones particular.`,

  term5Frag5: `Cupones: El uso de cupones es personal, solo un cupón por usuario. Cupón por orden. Cada cupón puede tener
    restricciones particulares que serán comunicadas en el banner.`,

  term5Frag6: `Nos reservamos el derecho, pero no estamos obligados, para limitar las ventas de nuestros productos o
    servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho basados en
    cada caso. Nos reservamos el derecho de limitar las cantidades de los productos o servicios que ofrecemos.
    Todas las descripciones de productos o precios de los productos están sujetos a cambios en cualquier
    momento sin previo aviso, a nuestra sola discreción. Nos reservamos el derecho de discontinuar cualquier
    producto en cualquier momento. Cualquier oferta de producto o servicio hecho en este sitio es nulo donde
    esté prohibido.`,

  term5Frag7: `PIXIE se compromete a proporcionar los mejores productos en cumplimiento con nuestros estándares de aseo,
    así como cumplir con la más alta seguridad de la salud y los requisitos reglamentarios aplicables
    relacionados. No garantizamos que la calidad de cualquier producto, servicio, información u otro material
    que El Cliente haya comprado u obtenido cumplirá con sus expectativas, o que cualquier error en el
    Servicio se corregirá.`,

  term5Frag8: `PIXIE ofrece una calculadora para obtener la ración sugerida para cada mascota dependiendo de unos
    factores predeterminados. Es responsabilidad del Cliente, verificar que las cantidades y tipo de alimento
    recomendadas y compradas para su mascota sean las apropiadas y cumplan con sus requerimientos
    nutricionales particulares. Si el Cliente identifica que su mascota tiene una reacción adversa a alguna de
    las dietas debe consultar con su veterinario de confianza.`,

  // 6
  term6Title: 'Sección 6. Sobre los Pagos:',

  term6Frag1: `El Cliente podrá pagar su compra con los medios ofrecidos por el Sitio Web que son contratados por un
    tercero prestador del servicio de pasarela de pagos. De esta forma, el tercero manejará directamente los
    datos del usuario referente al cobro por lo que el Tercero será directamente responsable de manejo de
    datos personales y cobros. Una vez recibido y confirmado el pago, se procesará la orden para su despacho.`,

  term6Frag2: `PIXIE no captura ninguna clase de información de los medios de pago electrónicos como tarjetas de
    Crédito/Débito transferencias electrónicas bancarias o demás.`,

  term6Frag3: `Toda aceptación de compra es sujeta a la condición de que PIXIE valide la transacción con los proveedores
    de pagos. PIXIE en consecuencia, para toda operación que se efectúe en el sitio web, la confirmación y/o
    validación y/o verificación por parte de PIXIE, será requisito para la formación del consentimiento. Para
    dar por validada la transacción, PIXIE podrá verificar la disponibilidad de los productos y podrá rechazar
    una compra en caso de que no exista stock disponible o existan problemas con la transacción. Dicha
    cancelación de compra será comunicada oportunamente al usuario vía correo electrónico, llamada telefónica
    o WhatsApp y, de ser el caso, procederá con el reembolso al cliente.`,

  term6Frag4: 'El Cliente está obligado a proveer datos actualizados y verídicos de pago.',

  // 7
  term7Title: 'Sección 7. SOBRE LA ENTREGA:',

  term7Frag1: `PIXIE realiza la entrega de los productos a través de un tercero, que serán compañías de transporte
    especializadas quienes serán los responsables por la entrega final del producto que derive de la
    contratación del servicio.`,

  term7Frag2: `Es responsabilidad del Cliente revisar los productos una vez recibidos y comprobar que no presenten
    defectos o daños, incluyendo revisar su correcta refrigeración.`,

  term7Frag3: `El Cliente se compromete a recibir el producto en la dirección de entrega señalada por El Cliente. La
    dirección de entrega diligenciada por El Cliente debe de ser clara, verídica, y específica. PIXIE no se
    hace responsable en el caso de que las compañías de transporte no cumplan con las instrucciones de entrega
    o no puedan entregar el producto por información errónea.`,

  term7Frag4: `En caso de que la dirección de entrega diligenciada por El Cliente sea errónea o poco clara, y afecte la
    entrega del pedido nos reservamos el derecho de cobrar una tarifa adicional en compensación.`,

  term7Frag5: `No somos responsables de cualquier acción u omisión de cualquiera de las empresas de transporte de
    terceros. Si se presenta algún problema con alguna de estas empresas de transporte de terceros, debe
    contactarnos e informarnos de la situación.`,

  term7Frag6: `El tiempo y horarios de entrega depende enteramente de la empresa transportadora, por lo no es posible que
    PIXIE garantice los horarios o tiempos de entrega.`,

  term7Frag7: `Estas entregas se pueden ver afectadas por otros factores no controlables como estado del tiempo y las
    vías, catástrofes, manifestaciones, accidentes, intervención o revisión por parte de entes de control,
    entre otros. En caso de que los productos no puedan ser entregados en la dirección de entrega diligenciada
    en consecuencia de las condiciones climáticas, de orden público, o cualquier situación por fuera de
    nuestro control, el pedido podrá ser cancelado o reprogramado por nosotros de manera unilateral sin que se
    considere como un incumplimiento.`,

  term7Frag8: `PIXIE no asumirá ni quedará en ningún tipo de deuda monetaria o de especie ante el cliente por demoras,
    entregas erradas o pérdidas, debido a errores de información proporcionados por el cliente, ni errores
    logísticos por parte de las empresas transportadoras.`,

  term7Frag9: `PIXIE podrá habilitar en el sitio web la opción de seleccionar una fecha de entrega de preferencia para el
    Cliente, en las zonas donde PIXIE tenga cobertura de entrega. En esos casos, PIXIE hará su mayor esfuerzo
    por gestionar la entrega a través del tercero contratado para tal fin en el día seleccionado. Sin embargo,
    PIXIE, no podrá garantizar el horario o entrega específica al depender del tercero transportador.`,

  term7Frag10: `PIXIE podrá habilitar la opción de entregas express o contrarreloj, siempre y cuando el proveedor del
    servicio de transporte tenga la opción y cobertura, para priorizar sus envíos y realizar la entrega en una
    franja de tiempo menor y determinada. Si bien PIXIE gestionará todo el proceso, la responsabilidad de
    entrega será del tercero contratado para este fin.`,

  term7Frag11: `Es responsabilidad del comprador estar presente el día y en el rango de la entrega prevista y previamente
    notificada por PIXIE, ya que al ser un producto perecedero y congelado, se recomienda no afectar la cadena
    de frío al permanecer sin congelación o refrigeración.`,

  term7Frag12: `El Cliente acepta que tendrá la responsabilidad respecto al producto, desde el momento de recepción en la
    ubicación que el Cliente especificó independientemente de quién firme el acuso de recepción. Por lo
    anterior, debe designar una dirección donde pueda garantizar la recepción y correcto almacenamiento del
    producto ya sea por el Cliente mismo o por un tercero designado para recibir físicamente el producto. En
    caso de que el Cliente no gestione esta recepción, PIXIE no asumirá ninguna responsabilidad respecto a
    daños que pueda afectar al producto.`,

  term7Frag13: `En los casos donde PIXIE ofrezca el beneficio de “Domicilios Gratis o Domicilios Incluidos”, El Cliente se
    compromete a recibir el producto en la dirección de entrega señalada por El Cliente. En el evento en que,
    por causas atribuibles al Cliente, “ej. no poder recibir el pedido en las horas indicadas para hacerlo”,
    nos reservamos el derecho de cobrar los intentos adicionales de entrega ya que no estarían incluidos
    dentro del beneficio.`,

  // 8
  term8Title: 'Sección 8. SOBRE RECOMENDACIONES DE USO, ALMACENAMIENTO Y DEVOLUCIONES DE LOS PRODUCTOS',

  term8Frag1: 'No aceptamos la devolución de ninguno de los productos perecederos ofrecidos por medio de esta página.',

  term8Frag2: `Las solicitudes de devolución para los productos no perecederos deben hacerse dentro de la primera semana
    luego de que se haya recibido el producto.`,

  term8Frag3: `No garantizamos aceptar las solicitudes de devolución que serán analizadas por el equipo de servicio al
    cliente.`,

  term8Frag4: `Todos nuestros productos deben de ser consumidos de manera previa a su fecha de vencimiento y conforme a
    las recomendaciones de uso.`,

  term8Frag5: `Si bien nuestros productos de alimentación están hechos con ingredientes aptos para el consumo humano, son
    de consumo exclusivo para mascotas.`,

  term8Frag6: 'Es responsabilidad del Cliente congelar/refrigerar el producto una vez recibido.',

  term8Frag7: 'El peso final del producto podrá variar dentro de los rangos establecidos por la regulación aplicable.',

  term8Frag8: `Recomendamos almacenar el producto congelado (idealmente -12°c / -18°c) para mantener la vida útil
    establecida, sin embargo, el producto podrá mantenerse en refrigeración mientras es consumido en el menor
    tiempo posible después de descongelado y abierto.`,

  term8Frag9: 'No es conveniente descongelar el producto y volverlo a congelar posteriormente.',

  term8Frag10: `Mantenemos las mejores prácticas de calidad, sin embargo especialmente por la forma de descongelado, la
    textura y el olor pueden variar sin que tenga alteraciones el producto, sin perjuicio de lo anterior, si
    presenta mal olor evidente por favor no suministrarlo a su mascota e informarnos de inmediato para iniciar
    el protocolo de calidad respectivo.`,

  term8Frag11: `En caso de daños ocasionados por el incumplimiento de las anteriores recomendaciones, PIXIE no se hará
    responsable de manera alguna.`,

  // 9
  term9Title: 'Sección 9. SOBRE EL SERVICIO DE SUSCRIPCIÓN',

  term9Frag1: `PIXIE podrá ofrecer un servicio de suscripción de sus productos. En caso de hacerlo, aplicarán los
    siguientes términos y condiciones.`,

  term9Frag2: `La SUSCRIPCIÓN es el suministro de manera periódica de productos de la marca PIXIE con una recurrencia
    determinada, de acuerdo con las condiciones definidas en este documento. El Cliente podrá seleccionar los
    productos y la frecuencia con que desea que sean entregados.`,

  term9Frag3: `La suscripción se pagará mediante la modalidad de débito automático recurrente (mensual, trimestral o
      semestralmente) a elección del CLIENTE, a través del tercero contratado para prestar el servicio de
      plataforma de pagos conforme a la sección de pagos dentro de este documento.`,

  term9Frag4: `Cuando realice la inscripción en el servicio de suscripción, El Cliente manifiesta estar de acuerdo con
    que PIXIE realice cargos periódicos y recurrentes según el plan escogido por el Cliente, sin necesidad de
    solicitar permisos individuales por cada cobro.`,

  term9Frag5: `La suscripción se entiende “continua”, por lo que solo terminará y se entenderá extinta cuando El Cliente
    o PIXIE la cancelen, por lo que se renovará automáticamente en cada ciclo de facturación a menos que una
    de las partes decida terminarlo.`,

  term9Frag6: `A menos que el Cliente cancele su suscripción, se entiende que autoriza a cobrarle la suscripción del
    siguiente ciclo de facturación a su Forma de pago.`,

  term9Frag7: `PIXIE podrá ofrecerle al cliente la frecuencia con la que quiere recibir los productos escogidos, la cual
    será indicada en el sitio web (ej. semanal, quincenal o mensual).`,

  term9Frag8: 'Las entregas subsiguientes a la primera serán coordinadas a partir de la elección inicial.',

  term9Frag9: `En caso de detectar que no se tenga la disponibilidad del producto seleccionado en la suscripción,
    realizaremos al menos dos (2) intentos de comunicación con el cliente vía telefónica, Whatsapp, correo
    electrónico o cualquier otro medio disponible para gestionar el cambio a su elección por productos de
    valor equivalente o si existiese algún sobrecosto por otros productos, este será asumido por el Cliente.`,

  // 10
  term10Title: 'Sección 10. CAMBIOS Y CANCELACIONES EN EL SERVICIO DE SUSCRIPCIÓN: ',

  term10Frag1: `El suscriptor podrá hacer solicitudes de cambio sobre frecuencia, productos, lugar de entrega, entre
    otros, que PIXIE se compromete a revisar y tratar de gestionar. Sin embargo, PIXIE se reservará el derecho
    de rechazar la solicitud de cambio al no poderlo ejecutar por cualquier motivo, sin que esto implique un
    incumplimiento por parte de PIXIE.`,

  term10Frag2: `El suscriptor podrá solicitar la cancelación su suscripción a través del Sitio Web en cualquier momento y
    continuará teniendo acceso a los productos hasta el final de su período de facturación. PIXIE se reserva
    el derecho, en la medida permitida por la ley aplicable, de no reembolsar los pagos ni otorgar reembolsos
    o créditos por los periodos de suscripción utilizados parcialmente. Si cancela su suscripción, su cuenta
    se cerrará automáticamente al final de su período de suscripción actual. Si desea cancelar y así evitar
    que se le haga el cobro en su próximo ciclo, debe cancelar la suscripción al menos siete (7) días hábiles
    antes de la próxima fecha de corte.`,

  term10Frag3: `El suscriptor podrá solicitar el cambio de algún producto de su suscripción, podrá realizarlo a través de
    las líneas de servicio al cliente indicadas en el sitio web al menos siete (7) días hábiles antes de su
    próxima fecha de renovación. En caso de solicitar modificaciones en plazos distintos, PIXIE se reservará
    el derecho de rechazar la solicitud de cambio al no poderlo ejecutar sin que esto implique un
    incumplimiento por parte de PIXIE.`,

  term10Frag4: `El suscriptor podrá solicitar un cambio en la dirección de entrega, deberá notificarlo a PIXIE a través de
    las líneas de servicio al cliente indicadas en el sitio web al menos siete (7) días hábiles antes de su
    próxima fecha de envío para verificar la posibilidad de cambio a partir de la cobertura que tenga PIXIE
    dentro del territorio. En caso de no poder realizar la entrega del pedido, PIXIE podrá analizar el caso
    puntual para buscar solución si es posible.`,

  term10Frag5: `El suscriptor podrá solicitar cambio en la fecha de entrega, deberá notificarlo a PIXIE a través de las
    líneas de servicio al cliente indicadas en el sitio web al menos siete (7) días hábiles antes de su
    próxima fecha de envío para indicarnos la fecha para la que desea reprogramar su pedido.`,

  term10Frag6: `Si es aceptada la modificación en la fecha de una de las entregas, esta modificación no alterará la fecha
    corte de la suscripción y la periodicidad por lo que no se verá afectada y continuará de acuerdo con la
    fecha seleccionada inicialmente ya que se asumirá como un cambio puntual.`,

  term10Frag7: `Los productos adquiridos a través del servicio de suscripción se sujetarán a las condiciones de despacho,
    tiempos de tránsito y zonas disponibles definidas por la empresa proveedora del servicio de transporte.`,

  term10Frag8: `En caso de que el cliente realice un pago por medios electrónicos y considere que ha sido objeto de
    fraude, o corresponda a una operación no solicitada, o el producto adquirido no sea recibido, o el
    producto entregado no corresponda a lo solicitado o sea defectuoso; podrá hacer una solicitud de reversión
    del dinero ante PIXIE que evaluará el caso y definirá si existe opción de compensación.`,

  term10Frag9: `Modificación de Precios de Productos en Suscripción: PIXIE podrá modificar los precios de los productos
    ofrecidos en las suscripciones. PIXIE podrá informar la actualización de precios a través del listado de
    precios en su sitio web, correo electrónico o cualquier otro medio que escoja para tal fin. Si el
    SUSCRIPTOR decide no aceptar ese cambio, tendrá que manifestar por medio escrito o a través de la página
    web, la cancelación de su suscripción que parará en el siguiente corte de suscripción.`,
  term10Frag10: `Pixie podrá ofrecer regalos como parte de tu suscripción en determinadas entregas. Sin embargo, 
    se establece que esta práctica no implica que Pixie esté obligado a otorgar regalos de forma permanente. 
    La disponibilidad de los regalos está sujeta a la discreción de Pixie y puede variar en función de diversos factores, 
    incluyendo disponibilidad de inventario, promociones específicas y otras consideraciones comerciales. Pixie se reserva 
    el derecho de modificar o cancelar esta oferta de regalos en cualquier momento y sin previo aviso. Al aceptar estos 
    términos y condiciones, reconoces y aceptas que los regalos son ofrecidos por Pixie como un beneficio adicional y que 
    su otorgamiento no implica una obligación permanente por parte de Pixie.`,

  // 11
  term11Title: 'Sección 11. ACEPTACION DE LOS PEDIDOS ',

  term11Frag1: `PIXIE se reserva el derecho de rechazar cualquier pedido que realice en el Sitio Web. Podrá a su exclusivo
    criterio, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas
    restricciones pueden incluir pedidos realizados con la misma cuenta de cliente, la misma tarjeta de
    crédito o pedidos que usen la misma dirección de facturación o de envío.`,

  term11Frag2: `En el caso de que realicemos un cambio o cancelemos un pedido, intentaremos notificarle vía correo
    electrónico o la dirección de facturación / número de teléfono proporcionados en el momento en que se
    realizó el pedido.`,

  term11Frag3: `Nos reservamos el derecho de limitar o prohibir los pedidos que, a nuestra entera discreción, parezcan
    haber sido realizados por comerciantes, revendedores o distribuidores no autorizados. En caso de ser
    comerciante, revendedor o distribuidor no autorizado, y estar interesado en la comercialización de
    nuestros productos, debe comunicarse por medio del correo electrónico al contacto provisto en estos
    Términos y Condiciones.`,

  // 12
  term12Title: 'Sección 12. EXACTITUD DE LA FACTURACIÓN Y DE LA INFORMACIÓN DE LA CUENTA',

  term12Frag1: `El Cliente acepta suministrar información completa y precisa de la compra y cuenta actual, para todas las
    compras realizadas en nuestra tienda. El Cliente acepta actualizar rápidamente su cuenta y demás
    informaciones, entre ellas, su dirección de correo electrónico, los números de tarjeta de crédito y las
    fechas de vencimiento, y dirección de entrega de productos, para que podamos completar sus transacciones y
    contactarlo según sea necesario.`,

  // 13
  term13Title: 'Sección 13. HERRAMIENTAS OPCIONALES',

  term13Frag1: `Podemos proporcionarle al Cliente acceso a herramientas de terceros que no supervisamos, ni tenemos ningún
    control sobre ellas, ni tampoco contribuimos.`,

  term13Frag2: `El Cliente reconoce y acepta que brindamos acceso a dichas herramientas &quot;tal como se encuentran&quot;
    y &quot;según disponibilidad&quot; sin garantías, representaciones ni condiciones de ningún tipo y sin
    ningún tipo de respaldo. No tendremos ninguna responsabilidad como consecuencia del uso que haga de
    herramientas opcionales de terceros o en relación con ellas.`,

  term13Frag3: `Cualquier uso que haga de las herramientas opcionales ofrecidas a través del sitio es por su cuenta y
    riesgo, y debe asegurarse de estar familiarizado con los términos según los cuales los proveedores
    externos relevantes suministran dichas herramientas y aprobarlos.`,

  term13Frag4: `También podemos, en el futuro, ofrecer nuevos servicios o funciones a través del sitio web (incluido el
      lanzamiento de nuevas herramientas y recursos). Estas nuevas funciones o servicios también estarán sujetos
      a los presentes Términos de servicio.`,

  // 14
  term14Title: 'Sección 14. ENLACES DE TERCEROS',

  term14Frag1: `Algunos contenidos, productos y servicios disponibles a través de nuestro Servicio pueden incluir recursos
    de terceros.`,

  term14Frag2: `Los enlaces de terceros en este sitio pueden dirigirlo a páginas web de terceros que no están afiliados a
    nosotros. No somos responsables de examinar o evaluar el contenido o la exactitud, ni garantizamos ni
    asumiremos ninguna obligación ni responsabilidad por los recursos o páginas web de terceros, ni por ningún
    otro material, producto o servicio de terceros.`,

  term14Frag3: `No somos responsables de ningún daño o perjuicio relacionado con la compra o el uso de bienes, servicios,
    recursos, contenido o cualquier otra transacción realizada en conexión con sitios web de terceros. El
    Cliente debe revisar cuidadosamente las políticas y prácticas de terceros, y asegúrese de comprenderlas
    antes de participar en cualquier transacción. Las quejas, reclamos, inquietudes o preguntas referentes a
    productos de terceros deben dirigirse a estos.`,

  // 15
  term15Title: 'Sección 15. COMENTARIOS DE LOS USUARIOS, OPINIONES Y OTRAS COMUNICACIONES',

  term15Frag1: `Si, a petición nuestra, El Cliente envía ciertas comunicaciones específicas (por ejemplo, participaciones
      en un concurso) o, sin una solicitud nuestra, envía ideas creativas, sugerencias, propuestas, planes u
      otros materiales, ya sea online, por correo electrónico, por correo postal, o de otro modo (denominado en
      lo sucesivo y de manera colectiva, &apos;comentarios&apos;), El Cliente acepta que podemos, en cualquier
      momento, sin restricción: editar, copiar, publicar, distribuir, traducir y usar en cualquier medio
      cualquier comentario que El Cliente nos envíe. No tenemos ni tendremos ninguna obligación (1) de mantener
      ningún comentario de manera confidencial; (2) pagar alguna compensación por cualquier comentario; o (3)
      responder a cualquier comentario.`,

  term15Frag2: `Podemos, pero no tenemos la obligación de monitorear, editar o eliminar contenido que a nuestra entera
    discreción determinemos que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornográfico,
    obsceno u objetable, o que infrinja la propiedad intelectual de cualquiera de las partes o de los
    presentes Términos del servicio.`,

  term15Frag3: `El Cliente acepta que sus comentarios no infringirán ningún derecho de terceros, incluidos los derechos de
    autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad. Además, acepta
    que sus comentarios no contendrán material difamatorio, ilegítimo, abusivo u obsceno, ni contendrán ningún
    virus informático ni otro software dañino que pueda afectar de cualquier manera el funcionamiento del
    Servicio o de cualquier sitio web relacionado. No puede utilizar una dirección de correo electrónico
    falsa, simular ser otra persona o engañarnos o engañar a terceros sobre el origen de los comentarios. El
    Cliente es el único responsable de los comentarios que realice y de su exactitud. No asumimos ninguna
    responsabilidad ni ninguna obligación por los comentarios publicados por El Cliente o por un tercero.`,

  // 16
  term16Title: 'Sección 16. INFORMACIÓN PERSONAL Y REGISTRO',

  term16Frag1: `El envío de la información personal que haga a través de la tienda se rige por nuestra Política de
    privacidad. Puede consultarla en nuestro Sitio Web, www.pixie.pet.`,

  term16Frag2: `Registro del Cliente: La creación de perfil de cliente, registro y generación la contraseña del cliente
    podrá ser requisito indispensable para la adquisición de productos y servicios ofrecidos en nuestro sitio
    web, especialmente en la modalidad de suscripción.`,

  term16Frag3: `Se entenderán como conocidos y aceptados los Términos y Condiciones de PIXIE por el sólo hecho del
    registro.`,

  term16Frag4: `Para realizar efectivamente la compra, el cliente tendrá que suministrar toda la información
    correspondiente y solicitada por el sitio web para el correcto cumplimiento de las entregas, compras,
    servicios, entre otros que pueda ofrecer el sitio web.`,

  term16Frag5: `El Cliente al ingresar sus datos declara que los mismos corresponden a información veraz y vigente, PIXIE
                SAS no se hace responsable por la certeza de los datos personales otorgados por los Clientes.`,

  term16Frag6: `Cada Cliente solamente podrá ser titular de una cuenta o registro en el sitio web, por lo que PIXIE se
                encuentra facultado para que en caso de evidenciar un uso fraudulento o de no poder confirmar los datos
                del cliente y/o contrario a la buena fe podrá suspender temporalmente o eliminar esas cuentas o registros
                y en caso en que así lo decida iniciar las acciones legales que haya a lugar.`,

  term16Frag7: `El registro y las transacciones por parte de los Clientes en el Sitio Web deberán ser realizados por
                mayores de edad exclusivamente o por menores de edad con la debida autorización de sus padres o
                acudientes.`,

  term16Frag8: `La información de registro de los Clientes será recolectada, almacenada y utilizada por PIXIE, utilizando
                los estándares de seguridad a nuestro alcance para impedir cualquier adulteración, pérdida, consulta, uso
                o acceso no autorizado o fraudulento por parte de terceros y garantizando los derechos de los titulares de
                los datos.`,

  // 17
  term17Title: 'Sección 17. ERRORES, INEXACTITUDES Y OMISIONES',

  term17Frag1: `Puede haber información en nuestro sitio o en el Servicio que, ocasionalmente, contenga errores
                tipográficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios,
                promociones, ofertas, cargos por envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos
                el derecho de corregir cualquier error, inexactitud u omisión, de cambiar o actualizar información, o
                cancelar pedidos si alguna información en el Servicio o en cualquier Sitio Web relacionada es inexacta en
                cualquier momento sin previo aviso (incluso después de haber enviado su pedido).`,

  term17Frag2: `PIXIE velará por actualizar, modificar o aclarar la información en el Servicio o en cualquier sitio web
                relacionado, incluyendo de manera enunciativa pero no limitativa, la información de precios, siempre que
                sea necesario. Ninguna actualización especificada o fecha de actualización aplicada en el Servicio o en
                cualquier sitio web relacionado debe tomarse para indicar que toda la información en el Servicio o en
                cualquier sitio web relacionado se modificó o actualizó.`,

  // 18
  term18Title: 'Sección 18. PROPIEDAD INTELECTUAL',

  term18Frag1: `Todos los servicios, productos y demás elementos del sitio web objeto de protección de propiedad
                intelectual o industrial, incluyendo su contenido, que incluye todos los derechos de Propiedad Intelectual
                sobre los productos y servicios, y los cambios, modificaciones o correcciones relacionadas, son propiedad
                de PIXIE y de sus afiliadas y/o licenciantes, y son protegidas ante uso no autorizado copia o diseminación
                conforme a las leyes aplicables de derecho de autor y propiedad industrial, así como los Tratados
                Internacionales a este respecto. PIXIE, se reserva todos los derechos respecto a los productos y
                servicios, que no sean expresamente otorgados en este Acuerdo.`,

  term18Frag2: `De ninguna forma se podrá interpretar como una licencia para el usuario, a usar cualquier elemento del
                sitio web o los productos y servicios o, directamente o mediante terceros, excepto: (i) por aquello
                expresamente permitido en este Acuerdo; o (ii) que cuente con el consentimiento previo y por escrito de
                PIXIE o aquel tercero que detente la titularidad sobre cierta parte del contenido de PIXIE.`,

  // 19
  term19Title: 'Sección 19. USOS PROHIBIDOS',

  term19Frag1: `Además de las prohibiciones establecidas en los Términos del servicio, se le prohíbe utilizar el sitio o
    su contenido (a) para cualquier propósito ilegal; (b) para solicitar a otros que realicen o participen en
    cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local
    internacional; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de
    propiedad intelectual de otros; (e) acosar, abusar, insultar, dañar, difamar, calumniar, denigrar,
    intimidar o discriminar por motivos de género, orientación sexual, religión, etnia, raza, edad,
    nacionalidad o discapacidad; (f) enviar información falsa o engañosa; (g) cargar o transmitir virus o
    cualquier otro tipo de código dañino que afecte o pueda afectar a la funcionalidad o el funcionamiento del
    Servicio o de cualquier sitio web relacionado, de otros sitios web o de Internet; (h) recopilar o rastrear
    la información personal de otros; (i) enviar correo no deseado, phishing, pharm, pretexto, spider,
    rastrear o extraer; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir o eludir las
    funciones de seguridad del Servicio o de cualquier sitio web relacionado, o de otros sitios web o de
    Internet. Nos reservamos el derecho de dar por terminado su uso del Servicio o de cualquier sitio web
    relacionado por infringir cualquiera de los usos prohibidos.`,

  // 20
  term20Title: 'Sección 20. DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD',

  term20Frag1: `No garantizamos, representamos ni aseguramos que el uso que haga de nuestro servicio será sin
                interrupciones, oportuno, seguro o sin errores.`,

  term20Frag2:
    'No garantizamos que los resultados que se puedan obtener del uso del servicio sean exactos o confiables.',

  term20Frag3: `El Cliente acepta que periódicamente, podamos eliminar el servicio por lapsos de tiempo indefinidos o
                cancelar el servicio en cualquier momento, sin notificarle.`,

  term20Frag4: `El Cliente acepta expresamente que su uso del servicio o la imposibilidad de utilizarlo corre por su
                riesgo. El servicio y todos los productos y servicios que se le entregan a través del servicio (salvo que
                así lo especifiquemos nosotros) se ofrecen &quot;tal como están&quot; y &quot;según disponibilidad&quot;
                para su uso, sin ninguna representación, garantía o condición de ningún tipo, ya sea expresa o implícita,
                entre las que se incluyen todas las garantías implícitas o condiciones de comerciabilidad, calidad
                comercial, idoneidad para un propósito particular, durabilidad, título y no violación.`,

  term20Frag5: `En ningún caso PIXIE, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas,
                pasantes, proveedores, proveedores de servicios o licenciantes serán responsables de cualquier lesión,
                pérdida, reclamo o cualquier daño directo, indirecto, incidental, punitivo, especial o consecuente de
                cualquier tipo, incluyendo de manera enunciativa más no limitativa; la pérdida de beneficios, pérdida de
                ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo o daños similares, ya sea por
                contrato, perjuicio (incluida la negligencia), responsabilidad estricta o de otro tipo, que surjan del uso
                que haga de cualquiera de los servicios o de cualquier producto adquirido por medio del servicio, o para
                cualquier otro reclamo relacionado de alguna manera con su uso del servicio o de cualquier producto,
                incluidos, entre otros, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de
                cualquier tipo en el que se haya incurrido como resultado del uso del servicio o de cualquier contenido (o
                producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se informa de su
                posibilidad. Nuestra responsabilidad se limitará a la extensión máxima permitida por la ley.`,

  // 21
  term21Title: 'Sección 21. INDEMNIZACIÓN',

  term21Frag1: `El Cliente acepta indemnizar, defender y mantener indemne a PIXIE y a nuestra empresa matriz,
    subsidiarias, afiliadas, asociados, funcionarios, directores, agentes, contratistas, licenciantes,
    proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, de cualquier reclamo o
    demanda, incluidos los honorarios razonables de abogados, en los que un tercero haya incurrido debido a su
    incumplimiento de los presentes Términos del servicio o de los documentos que incorporan como referencia o
    que surjan por su incumplimiento de los mismos, o por la violación de cualquier ley o derechos de un
    tercero que haga.`,

  // 22
  term22Title: 'Sección 22. DIVISIBILIDAD',

  term22Frag1: `En caso de que se determine que alguna disposición de los presentes Términos del servicio sea ilegal, nula
                o inaplicable, dicha disposición será, no obstante, ejecutable en la medida en que lo permita la
                legislación aplicable, y la parte inaplicable se considerará separada de los presentes Términos del
                servicio, sin que dicha determinación afecte a la validez y aplicabilidad de las demás disposiciones.`,

  // 23
  term23Title: 'Sección 23. RESCISIÓN',

  term23Frag1: `Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de rescisión seguirán
                vigentes después de la rescisión de este contrato a todos los efectos.`,

  term23Frag2: `Estos Términos del servicio se encuentran vigentes a menos que El Cliente o nosotros los rescindamos.
                Puede rescindir los presentes Términos del servicio en cualquier momento al notificarnos que ya no desea
                utilizar nuestros Servicios o cuando cese de utilizar nuestro sitio.`,

  term23Frag3: `Si a nuestro juicio El Cliente incumple, o sospechamos que ha incumplido con cualquier término o
                disposición de los presentes Términos del servicio, podemos rescindir igualmente este acuerdo en cualquier
                momento sin previo aviso y El Cliente seguirá siendo responsable de todos los importes adeudados, hasta la
                fecha de rescisión inclusive; y/o en consecuencia podemos denegarle el acceso a nuestros Servicios (o a
                parte de ellos).`,

  // 24
  term24Title: 'Sección 24. ACUERDO COMPLETO',

  term24Frag1: `El hecho de que no ejerzamos o hagamos valer algún derecho o disposición de los presentes Términos del
                Servicio no constituirá una renuncia a dicho derecho o disposición.`,

  term24Frag2: `Estos Términos de servicio y cualquier política o regla de funcionamiento que hayamos publicado en este
                sitio o con respecto al Servicio constituye el acuerdo y el entendimiento completo entre El Cliente y
                nosotros, y rigen su uso del Servicio, sustituyendo a cualquier acuerdo, comunicación o propuesta anterior
                o contemporánea, ya sea oral o escrita, entre El Cliente y nosotros (incluyendo de manera enunciativa más
                no limitativa, las versiones anteriores de los Términos del servicio).`,

  term24Frag3: `Cualquier ambigüedad en la interpretación de los presentes Términos del servicio no se interpretará en
                contra de la parte redactora.`,

  // 25
  term25Title: 'Sección 25. Ley aplicable y solución de conflictos',

  term25Frag1: `Para la interpretación y cumplimiento de los presentes términos y condiciones, las partes se someten a la
                jurisdicción de México. En caso de conflicto, PIXIE y el Cliente acuerdan someterse a la jurisdicción
                exclusiva de los tribunales ubicados en la Ciudad de México para resolver cualquier disputa que surja del
                Acuerdo, su acceso o uso del Sitio o los Servicios.`,

  // 26
  term26Title: 'Sección 26. CAMBIOS EN LOS TÉRMINOS DEL SERVICIO',

  term26Frag1:
    'Puede revisar la versión más reciente de los Términos del servicio en cualquier momento en esta página.',

  term26Frag2: `Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o sustituir cualquier parte
                de los presentes Términos del servicio mediante la publicación de actualizaciones y cambios en nuestro
                sitio web. Es su responsabilidad consultar nuestro sitio web periódicamente para ver los cambios. El uso
                que haga de nuestro Sitio Web o del Servicio o su acceso a cualquiera de estos de forma continua después
                de la publicación de cualquier cambio en los presentes Términos del servicio, constituye la aceptación de
                dichos cambios.`,

  // 27
  term27Title: 'Sección 27. Información de contacto',

  term27Frag1: 'Preguntas acerca de los Términos de Servicio deben ser enviadas a los siguientes medios de contacto:',

  term27Frag2: '(+52) 55 1307 0792',

  term27Frag3: 'soporte@pixie.pet',

  // Last update
  termsLastUpdt: 'Última Actualización:',
  termsLastUpdtDate: '8 de septiembre de 2022',
};
