import { useEffect, useState } from 'react';
import { mexicanStates, postalCode } from '../../../../../@fake/statesFake';
import { getCities, getPostalCode } from '../../../../../helpers/formCheckoutHelper';
import { useAppContext, useForm } from '../../../../../hooks';
import useValidator from '../../../../../hooks/useValidator';
import { addressObject } from '../../../../../interfaces/checkout';
import { edituserAndAddressEcommerceValidate, editUserSubscription, subscriptionUserComplete } from '../../../../../interfaces/subscription';
import checkOutService from '../../../../../services/checkOutService';
import validator from 'validator';
import subscriptionService from '../../../../../services/subscriptionService';
import WarningSubscription from '../../../../../components/common/warningSubscription';
import Button from '../../../../../components/common/button';
import ClientDataSection from './clientDataSection';
import ShippingDataSection from './shippingDataUser';
import { SelectItem } from '../../../../../components/form/selectField';
import PopupDecisionUser from '../../../../../components/layout/popupDecisionUser';

const EditAddressAndUser = ({ subscription, setClosePopup }: EditAddressAndUserProps) => {
  const addressComplete: addressObject = JSON.parse(subscription.general_order.metadata.shippingDetails.address1);
  const { toast, updateContext } = useAppContext();
  const [showConfirmChange, setShowConfirmChange] = useState(false);
  const { form, handleFormChange, setForm, onSubmit, handleSelectChange } = useForm<editUserSubscription>({
    address: `${addressComplete.address}`,
    apartament: `${addressComplete.apartament}`,
    cities: getCities(mexicanStates.findIndex(item => item.value === subscription.general_order.metadata.shippingDetails.regionvalue) === -1 ? '' : mexicanStates[mexicanStates.findIndex(item => item.value === subscription.general_order.metadata.shippingDetails.regionvalue)].value),
    city: { value: subscription.general_order?.metadata.shippingDetails.cityValue ? subscription.general_order?.metadata.shippingDetails.cityValue : subscription.general_order?.metadata.shippingDetails.city, label: subscription.general_order?.metadata.shippingDetails.city },
    colony: `${addressComplete.colony}`,
    deliveryDate: '',
    houseNumber: `${addressComplete.houseNumber}`,
    reference: `${addressComplete.reference}`,
    state: mexicanStates.findIndex(item => item.value === subscription.general_order.metadata.shippingDetails.regionvalue) === -1 ? { label: '', value: '' } : mexicanStates[mexicanStates.findIndex(item => item.value === subscription.general_order.metadata.shippingDetails.regionvalue)],
    states: mexicanStates,
    stateST: subscription.general_order.metadata.shippingDetails.region,
    status: '',
    zipCode: { value: addressComplete.zipCode ? addressComplete.zipCode : '', label: addressComplete.zipCode ? addressComplete.zipCode : '' },
    zipCodes: getPostalCode(subscription.general_order.metadata.shippingDetails.cityValue),
    countries: [],
    country: { label: '', value: '' },

    email: subscription.user_information.email,
    last_name: subscription.user_information.last_name,
    name: subscription.user_information.name,
    phone: subscription.user_information.phone,
  }, () => handleValidate());

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator<edituserAndAddressEcommerceValidate>({
    apartment: { message: '' },
    city: { message: '' },
    colony: { message: '' },
    country: { message: '' },
    delegation: { message: '' },
    houseNumber: { message: '' },
    reference: { message: '' },
    state: { message: '' },
    zip_code: { message: '' },
    last_name: { message: '' },
    name: { message: '' },
    phone: { message: '' },
    email: { message: '' },
  });

  useEffect(() => getCountries(), []);

  const getCountries = () => {
    checkOutService.getCountries()
      .then(res => {
        const selects: SelectItem[] = res.map(country => ({
          label: country.name,
          value: `${country.id}`
        }));
        const findCountry = selects.findIndex(item => item.value === subscription.general_order.metadata.shippingDetails.countryValue);
        setForm(old => ({ ...old, countries: selects, country: findCountry > -1 ? selects[findCountry] : old.country }));
      }).catch(error => {
        console.log(error);
      });
  };

  const handleValidate = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    // User Validators

    if (!validator.isNumeric(form.phone)) {
      handlePutMessageError('phone', 'Solo se pueden escribir números');
      error = true;
    }

    if (!validator.isLength(form.phone, { min: 10, max: 10 })) {
      handlePutMessageError('phone', 'El celular solo debe tener 10 digitos');
      error = true;
    }

    if (!validator.isAlpha(form.name, 'es-ES', { ignore: ' ' })) {
      handlePutMessageError('name', 'Solo se debe escribir letras');
      error = true;
    }

    if (!validator.isAlpha(form.last_name, 'es-ES', { ignore: ' ' })) {
      handlePutMessageError('last_name', 'Solo se debe escribir letras');
      error = true;
    }

    if (!validator.isEmail(form.email)) {
      handlePutMessageError('email', 'Debe de ser un email');
      error = true;
    }

    // Address validators

    if (!validator.isLength(form.houseNumber, { min: 1 })) {
      handlePutMessageError('houseNumber', 'El número de la casa debe ser mayor o igual a 1 caracter');
      error = true;
    }

    if (!validator.isLength(form.apartament, { min: 1 }) && form.apartament.length > 0) {
      handlePutMessageError('apartament', 'El número de apartamento debe ser mayor a 1 carácter');
      error = true;
    }

    if (!validator.isLength(form.reference, { min: 1 }) && form.reference.length > 0) {
      handlePutMessageError('reference', 'la referencia debe ser mayor a 1 caracteres');
      error = true;
    }

    if (!validator.isLength(form.colony, { min: 4 })) {
      handlePutMessageError('colony', 'La colonia debe contener mas de 4 caracteres');
      error = true;
    }

    if (validator.equals(form.state.value, '')) {
      handlePutMessageError('state', 'Se debe seleccionar un estado');
      error = true;
    }

    if (validator.equals(form.city.value, '')) {
      handlePutMessageError('city', 'Se debe seleccionar una ciudad');
      error = true;
    }

    if (!postalCode.includes(form.zipCode?.value)) {
      handlePutMessageError('zip_code', 'El codigo postal no se encuentra en nuestra lista');
      error = true;
    }

    if (!error)
      setShowConfirmChange(true);
  };

  const handleSubmit = () => {
    subscriptionService.editSubscriptionUserinformation(subscription.id, form, subscription)
      .then(res => {
        toast.fire({
          icon: 'success',
          title: 'Información editada correctamente.',
        });
        setShowConfirmChange(false);
        updateContext(old => ({ ...old, reloadData: true }));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const closeAll = () => {
    setClosePopup();
  };

  const handleClosePopup = () => {
    setShowConfirmChange(false);
  };

  return (
    <form className={`flex flex-col max-h-[80vh] lg:w-[43rem] lg2:w-[52rem] xl2:w-[58rem] pb-12
    relative gap-6`}
    onSubmit={onSubmit}
    >
      <div className='bg-white shadow-xl rounded-b-[20px] px-7 pt-4 pb-16'>

        <WarningSubscription/>

        <div className='flex flex-col gap-20'>
          {/* Clien form */}
          <ClientDataSection
            handleFormChange={handleFormChange}
            form={form}
            validatorBody={validatorBody}
          />

          {/* shipping form */}
          <ShippingDataSection
            handleFormChange={handleFormChange}
            handleSelectChange={handleSelectChange}
            form={form}
            validatorBody={validatorBody}
            setForm={setForm}
            countries={form.countries as SelectItem[]}
            subscription={subscription}
          />
        </div>
      </div>

      {showConfirmChange &&
        <PopupDecisionUser
          handleAccept={handleSubmit}
          handleDeny={handleClosePopup}
          textAccept='Cambiar'
          textDeny='Cancelar'
          type='question'
          titleHTML={
            <div className='flex flex-col'>
              <p>¿Estás seguro que quieres cambiar la dirección para</p>
              <p>recibir tu pedido en: </p>
              <p>{form.country?.label}, {form.state.label}, {form.city.label}, {form.colony}, {form.address}-{form.houseNumber}-{form.apartament}?</p>
            </div>
          }
        />
      }
      <div className='flex justify-end gap-2 text-base mt-8 font-sanzSemiBold h-[31px]'>
        <Button className='border border-primary text-primary' padding='px-8 py-1' onClick={closeAll}>Cancelar</Button>
        <Button className='bg-primary text-[#fad7b1]' padding='px-7 py-1' type='submit'>Guardar</Button>
      </div>
    </form>
  );
};

interface EditAddressAndUserProps {
  subscription: subscriptionUserComplete,
  setClosePopup: () => void
}

export default EditAddressAndUser;
