import { useEffect, useState } from 'react';

import ResumenSuscriptionSection from '../../../../../../components/common/suscriptionResumen';
import CouponSubscriptionSection from './couponSubscriptionSection';

import { calculateDiscountPriceShipping, getPriceDescount } from '../../../../../../helpers/paymentHelper';
import { calculateDiscountPrice, calculateIvaProduct, calculateTotal, calculateWithDiscountSubscription, getPriceDiscountCouponSubscription, roundToXDigits } from '../../../../../../helpers/productHelper';
import { editSubscriptionUserForm, subscriptionUserComplete } from '../../../../../../interfaces/subscription';
import { couponComplete } from '../../../../../../interfaces/coupon';
import { shippingDiscountCoupon } from '../../../../../../interfaces/payment';
import { workCities } from '../../../../../../interfaces/store';

const RightSectionEditSubscripcion = ({ form, setForm, subscription, workCitieData }: RightSectionEditSubscripcionProps) => {
  const [discount, setDiscount] = useState<number>(calculateIvaProduct(form.products));
  const [couponPrice, setCouponPrice] = useState<number>(0);
  const [subtotal, setSubtotal] = useState(calculateDiscountPrice(calculateTotal(form.products, false), form.coupon_detail, form.subscriptions.percen_discount, discount));
  const [valueDiscountShipping, setValueDiscountShipping] = useState<shippingDiscountCoupon>({
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  });

  console.log(workCitieData);
  // Calculate the subtotal
  useEffect(() => {
    setSubtotal(calculateDiscountPrice(calculateTotal(form.products, false), form.coupon_detail, form.subscriptions.percen_discount, discount));
  }, [form.products, couponPrice, discount, form.coupon_detail]);

  // Calculate the discount coupon
  useEffect(() => {
    if (form.coupon_detail) {
      let valueDiscountShippingIn: shippingDiscountCoupon = {
        finalPrice: 0,
        originalValueShipping: 0,
        percentDiscount: 0,
        valueDiscount: 0
      };
      if (form?.coupon_detail?.feature) {
        valueDiscountShippingIn = calculateDiscountPriceShipping(form.coupon_detail.feature, form.shippingType.price);
        setValueDiscountShipping(valueDiscountShippingIn);
        // Subtract the porcent discount feature
        setForm(old => ({ ...old, shippingType: {
          price: valueDiscountShippingIn.originalValueShipping,
          type: valueDiscountShippingIn.finalPrice === 0 ? 'gratis' : 'estandar'
        } }));
      }

      if (form.coupon_detail.couponType.key === 'percent')
        setCouponPrice(-roundToXDigits((getPriceDescount(form.products, form.coupon_detail, valueDiscountShippingIn) - calculateTotal(form.products, false)), 2));
      else
        setCouponPrice(form.coupon_detail.discount);

      setValues(form.coupon_detail, valueDiscountShippingIn);
      return () => {};
    }
  }, [form.coupon_detail]);

  console.log(form);
  console.log(subscription);

  // Calculate subscription discount
  useEffect(() => {
    const value = roundToXDigits((calculateWithDiscountSubscription(form.products, form.subscriptions.percen_discount, { price: 0, type: 'gratis' }, form.subscriptions, form.coupon_detail, undefined, valueDiscountShipping)) * 0.16, 2);
    setDiscount(value);
    setForm(old => ({ ...old, details_payments: { ...old.details_payments, onlyIva: value * old.numberShipping } }));
  }, [form.subscriptions, form.products, form.coupon_detail, valueDiscountShipping]);

  // Save the subtotal without iva
  useEffect(() => {
    setForm(old => ({ ...old, details_payments: { ...old.details_payments, subTotalNoIva: subtotal * old.numberShipping } }));
  }, [subtotal]);

  const setValues = (couponData: couponComplete | undefined, valueDiscountShippingIn: shippingDiscountCoupon | undefined) => {
    if (couponData && valueDiscountShippingIn)
      setForm(old => ({ ...old,
        couponsSelect: [couponData.id],
        coupon_detail: couponData,
        couponId: couponData && {
          amount: (roundToXDigits(getPriceDiscountCouponSubscription(form.products, form.subscriptions.percen_discount, couponData, valueDiscountShippingIn), 2)),
          id: couponData.id,
          code: couponData.claimCode,
          internalCouponName: couponData.internalCoupon.name,
          internalCouponNumber: couponData.internalCoupon.code,
          ...couponData.feature && {
            discountShipping: (calculateDiscountPriceShipping(couponData.feature, 90).valueDiscount) * form.numberShipping
          }
        },
      }));
    else {
      setForm(old => ({
        ...old,
        couponsSelect: [],
        coupon_detail: undefined,
        couponId: {
          amount: 0,
          id: '',
          code: '',
          internalCouponName: '',
          internalCouponNumber: ''
        },
      }));
      setValueDiscountShipping({
        finalPrice: 0,
        originalValueShipping: 0,
        percentDiscount: 0,
        valueDiscount: 0
      });
    }
  };

  return (
    <div className='flex flex-col text-sm gap-6'>
      <ResumenSuscriptionSection
        form={form}
        setForm={setForm}
        subscription={subscription}
        valueDiscountShipping={valueDiscountShipping}
        workCitieData={workCitieData}
      />
      <CouponSubscriptionSection
        form={form}
        subscription={subscription}
        setForm={setForm}
        setValues={setValues}
      />
    </div>
  );
};

interface RightSectionEditSubscripcionProps {
  form: editSubscriptionUserForm,
  setForm: React.Dispatch<React.SetStateAction<editSubscriptionUserForm>>,
  subscription: subscriptionUserComplete,
  workCitieData?: workCities
}

export default RightSectionEditSubscripcion;
