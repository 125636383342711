import { useRef, useState } from 'react';
import IconButton from '../iconButton';

type VideoPlayerProps = {
  src: string;
  classNameVideo?: string;
};

function VideoPlayer({ src, classNameVideo }: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [pause, setPause] = useState(true);
  const [showButtonStop, setShowButtonStop] = useState(false);

  const handleChangePlayer = () => {
    if (pause)
      videoRef.current?.play();
    else
      videoRef.current?.pause();

    setPause(!pause);
  };

  return (
    <div className='relative'
      onMouseEnter={pause ? () => {} : () => setShowButtonStop(true)}
      onMouseLeave={pause ? () => {} : () => setShowButtonStop(false)}
    >
      <video ref={videoRef} src={src} className={classNameVideo}/>
      <div className='flex justify-center items-center absolute top-[38%] left-[38%]'>
        {(!pause && showButtonStop) &&
          <IconButton
            name={'pause'}
            onClick={handleChangePlayer}
            shadow={false}
            classNameIcon='text-white'
            size='3xl'
            className='rounded-full p-1 bg-pixieLightBlue'
            sizeContainer='w-16 h-16'
          />
        }
        {pause &&
          <IconButton
            name={'play_arrow'}
            onClick={handleChangePlayer}
            shadow={false}
            classNameIcon='text-white'
            size='3xl'
            className='rounded-full p-1 bg-pixieLightBlue'
            sizeContainer='w-16 h-16'
          />
        }
      </div>
    </div>
  );
}

export default VideoPlayer;
