import { Navigate } from 'react-router-dom';

import { useAppContext } from '../hooks';
import Loading from '../components/layout/loading';
import { useEffect, useState } from 'react';

const Protected = ({ children }: ProtectedProps) => {
  const { isLoadingPreData, isAuthenticated } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoadingPreData)
      setLoading(false);
  }, [isLoadingPreData]);

  return (
    <>
      {loading ?
        <Loading/>
        :
        <>
          {isAuthenticated
            ?
            children
            :
            <Navigate to='/' replace />
          }
        </>
      }
    </>
  );
};

interface ProtectedProps {
  children: JSX.Element;
}

export default Protected;
