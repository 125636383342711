export const products = {
  productsSoonButton: 'Próximamente',
  productsAddButton: 'Agregar a la bolsa',
  productsCalcText: 'Haz click aquí para conocer la ración indicada.',
  productsCalcButton: 'Calculadora',
  productsNutrTitle: 'Ingredientes y nutrición',
  productsInfoTitle: 'Beneficios y características:',
  productsInfoTable: 'Tabla consumo en gramos diarios:',
  productsInfoTableButton: 'Click para ampliar',
  productsInfoPuppiesRange: 'Etapa: Cachorros de 2 a 12 meses',
  productsInfoAdultsRange: 'Etapa: Adultos de 1 a 6 años',
  productsInfoSeniorRange: 'Etapa: Seniors de 7 años en adelante',
  productsInfoComp: 'Composición garantizada MH / MS',
  productsInfoCons: 'Conservación del producto',
  productsInfoGrams: 'gramos por día',
  productsInfoIngr: 'Instrucciones de alimentación',
  productX20: 'Caja x20 rollos',
  productX202: '( Hasta 5 diferentes sabores )',
  productX20Button: 'Armar',
  productBoxInfoX20: 'Caja x20 unidades',
  productBoxInfoX40: 'Caja x40 unidades',
  productSalesText: 'Licencia de venta',
  productBread: 'Catálogo',
  productBreadHome: 'Home',
  successAdd: 'Producto agregado al carrito',
  productsNoMore: 'No hay más unidades de este producto'
};

export const shoppingCar = {
  carTitle: 'RESUMEN DE TU PEDIDO',
  carBasketButton: 'Ver canasta',
  carShoppingButton: 'Seguir comprando',
};
