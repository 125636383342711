export const shipping = {
  shippingtitle: 'Envíos',
  shippinginfotitle: 'Informacion de envíos',
  shippingInfoPart1: 'Envío estándar con entrega hasta 3-7 días hábiles.',
  shippingInfoPart2: 'Envío gratis en compras mayores a $750',
  shippingback: 'Volver a información',
  shippingContinuebutton: 'Seguir con envíos',
  shippingOrderNote: 'Agrega una nota a tu pedido',
  shippingFreeShipment: 'Gratis',
  shippingStandardShipping: 'Envío estándar'
};
