import { defaultImageConfessions } from '../../../assets/images';
import VideoPlayer from '../videoPlayer';

export const TestimonialImage = ({ image, name, years, typeMedia, ageType, className }: TestimonialImageProps) => (
  <div className={`bg-white pt-3 flex flex-col justify-center items-center gap-4 pb-4 shadow-confessions-cart lg:px-4 lg:w-2/5 font-titles ${className ? className : 'px-2 mx-6'}`}>
    {(typeMedia === 'video' && image)
      ?
      <VideoPlayer src={image} classNameVideo='w-[16rem] h-[16rem] md:w-[18.5rem] md:h-[18.5rem] lg:w-[21.5rem] lg:h-[21.5rem] object-cover'/>
      :
      <img src={(image && image !== '') ? image : defaultImageConfessions} className='w-[16rem] h-[16rem] md:w-[18.5rem] md:h-[18.5rem] lg:w-[21.5rem] lg:h-[21.5rem]'/>
    }
    <div className='flex flex-col justify-center items-center gap-2'>
      <p>{name}</p>
      <p className='text-xs'> {years} {ageType === 'years' ? 'años' : 'meses'}</p>
    </div>
  </div>
);

interface TestimonialImageProps {
  image?: string
  name: string,
  years: number,
  typeMedia: 'video' | 'image',
  ageType: 'years' | 'months',
  className?: string
}

export default TestimonialImage;
