const TabNavigation = ({ changeState, isActive, text, className, value }: TabNavigationProps) => (
  <div className={`text-pixieLightBlue ${className && className} px-4 py-1 font-sanzSemiBold ${isActive ? 'bg-[#D9EEEF]' : 'bg-white'} shadow-sm cursor-pointer`}
    onClick={() => changeState(value)}
  >
    {text}
  </div>
);

interface TabNavigationProps {
  changeState : (value: number) => void,
  isActive: boolean,
  text: string,
  value: number,
  className?: string
}

export default TabNavigation;
