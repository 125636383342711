import { CreatePet, Pet } from '../../../interfaces/user/pets';
import api from '../../axios';

export class PetsService {
  init() {}

  getPetsByUser = (): Promise<Pet[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/pets', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  createPet = (form: CreatePet, imgUrl: string): Promise<Pet> => {
    const authorization = window.localStorage.getItem('token');
    // Validate Image profile
    let imgUrlTemp = `${process.env.REACT_APP_URL_S3}${imgUrl}`;
    if (imgUrl === '')
      imgUrlTemp = form.imgShow;
    const newData = {
      ...form,
      type: form.type.value,
      activity: form.activity.value,
      img: imgUrlTemp,
      diseases: JSON.stringify(form.diseases),
      gen: form.gen.value
    };

    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/pets', newData, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editPet = (form: CreatePet, imgUrl: string, idPet: string): Promise<Pet> => {
    const authorization = window.localStorage.getItem('token');
    // Validate Image profile
    let imgUrlTemp = `${process.env.REACT_APP_URL_S3}${imgUrl}`;
    if (imgUrl === '')
      imgUrlTemp = form.imgShow;
    // Organice Data
    const newData = {
      ...form,
      type: form.type.value,
      activity: form.activity.value,
      img: imgUrlTemp,
      diseases: JSON.stringify(form.diseases),
      gen: form.gen.value
    };

    return new Promise((resolve, reject) => {
      api.put(`pixie-customers/api/pets/${idPet}`, newData, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  uploadImagePet = (file: FileList, petData: CreatePet, idUser:string): Promise<string> => {
    const authorization = window.localStorage.getItem('token');
    const formData = new FormData();
    const newName = `imagePet-${petData.name.replaceAll(' ', '-')}-${idUser}`;
    formData.append('file', file[0], newName);

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-customers/api/uploads',
        headers: {
          Authorization: authorization ? authorization : '',
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      })
        .then(response => {
          resolve(response.data.key);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };
}

export default new PetsService();

