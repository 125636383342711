const Table = ({ titles, children, className }: TableProps) => (
  <table className={`border-collapse border border-pixieLightBlue table-auto text-sm w-full h-full ${className}`} id='tableData'>
    <thead>
      <tr>
        {titles.map((title, index) =>
          <th key={`title-${index}`} className='bg-pixieLightBlue py-2 text-white font-sanzSemiBold sticky top-0 z-10'>{title}</th>
        )}
      </tr>
    </thead>
    <tbody className='text-black'>
      { children }
    </tbody>
  </table>
);

interface TableProps {
  titles: string[],
  children: JSX.Element,
  className?: string
}

export default Table;
