export const home = {
  homeHeader: 'Alimento para perros y gatos - comida húmeda',
  homeDescription: 'Pixie, el alimento húmedo 100% natural para perros y gatos. Calidad y nutrición en una dieta completa. Dale a tus mascotas la alimentación que se merecen.',
  homeCanonical: 'https://mx.pixie.pet/',
  // Home - Cards
  homeBannerText1: 'Comida',
  homeBannerText2: 'verdadera',
  homeBannerText3: 'Para un amor',
  homeBannerText4: 'verdadero',
  homeBannerText5: 'Alimento natural para todos los días',
  homeCardsTitle: 'Tu amor de 4 patas debería de comer igual de saludable que toda tu familia.',

  card1Title: 'DIRECTO A TU CASA:',
  card1Descr: 'Nosotros te llevamos la comida fresca y cuando la necesites hasta la puerta de tu casa.',

  card2Title: 'MENOS Y MEJORES HECES: ',
  card2Descr:
    'Por su alta digestibilidad los perros y gatos absorben de mejor forma los nutrientes por lo que recogerás menos popós.',

  card3Title: 'COMIDA REAL HORNEADA',
  card3Descr:
    'Cumpliendo nuestro propósito de generar bienestar aseguramos que solo usamos los mejores ingredientes de grado humano dentro del rollo.',

  card4Title: 'DESARROLLADA POR NUTRIÓLOGOS VETERINARIOS:',
  card4Descr: 'Sin conservantes y con los nutrientes necesarios para una dieta diaria balanceada y saludable.',

  card5Title: 'VARIEDAD DE PRODUCTOS: ',
  card5Descr:
    'Cada dieta y cada ingrediente, está pensada para cada tamaño, edad, condición física y condición médica.',

  // Home - Products
  homeProductsTitle: 'Nuestros Productos',
  homeCatalogueButton: 'Ver catálogo',

  // Home - Calculator
  homeCalcFrag1: '¿Cuál es el Pixie ideal',
  homeCalcFrag2: 'para mi perrito o',
  homeCalcFrag3: 'michi?',
  homeCalcButton: 'Calcúlalo aquí',
  loading: 'Cargando',
  deny: 'Cancelar',
  update: 'Actualizar'
};
