import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';

import Router from './router';
import 'react-day-picker/dist/style.css';

import { AppContext, appProvider } from './context';

export const App = () => {
  // Updatable Provider
  const [provider, setProvider] = useState(appProvider);
  const currentUrl = window.location.href;

  if (!navigator.geolocation) {
    console.log('tu navegador no tiene opcion de geolocalización');
    throw new Error('Tu navegador no tiene geolocalizacion');
  }

  useEffect(() => {
    if (currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_US as string))
      // ReactGA.initialize('G-GJMBHRDPX0');
      // ReactGA.pageview(window.location.pathname + window.location.search);
      TagManager.initialize({ gtmId: 'GTM-KJT7G4Q' });
    else
      TagManager.initialize({ gtmId: 'GTM-PDHDXJH' });
  }, []);

  useEffect(() => {
    const siteId = 3596500;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }, []);

  // Auth check
  useEffect(() => {
    if (!provider) return;

    localStorage.setItem('auth', JSON.stringify(provider.isAuthenticated));

    return () => { };
  }, [provider]);

  useEffect(() => {
    if (!provider) return;
    if (provider.products.length < 1) return;

    localStorage.setItem('shoppingCart', JSON.stringify(provider.products));

    return () => { };
  }, [provider.products]);

  return (
    <AppContext.Provider value={{ ...provider, updateContext: setProvider }}>
      <Router />
    </AppContext.Provider>
  );
};

export default App;
