export const socialMediaLinks = {
  facebook: {
    MEX: 'https://www.facebook.com/Pixiemx-104482775594223/',
    USA: 'https://www.facebook.com/Pixieus-101708272541954',
  },
  instagram: {
    USA: 'https://www.instagram.com/pixie.us/',
    MEX: 'https://www.instagram.com/pixie.mex/?igshid=YmMyMTA2M2Y%3D'
  }
};

