import { warning } from '../../../assets/vectors';

const WarningSubscription = () => (
  <div className='border border-primary flex text-primary rounded-[20px] py-4 text-base justify-center items-center gap-4 mx-10 my-6'>
    <img src={warning}/>
    <div className='font-sanzSemiBold'>
      <p>
        Los cambios que hagas en esta sección se verán reflejados en el próximo débito.
      </p>
      <p>
        Ten en cuenta que el débito se realizará el día de tu última entrega
      </p>
    </div>
  </div>
);

export default WarningSubscription;
