import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Select, { MultiValue, SingleValue, components as Comps } from 'react-select';

export const SelectField = ({
  name,
  value,
  options,
  onChange,
  label,
  className = '',
  placeholder,
  border,
  borderRadius,
  borderRadiuspx,
  borderColor,
  messageError,
  paddingY,
  dropdownIndicatorColor,
  isMulti = false,
  disabled = false,
  colorText,
  isClearable = false,
  backgroundColor,
  colorPlaceholder,
  fontSize,
  colorSelectsText,
  backgroundColorOption,
  classNameSelect,
  height,
  closeMenu,
  noOpenMenu = false,
  isMenuOpenChange,
  onOpenMenuShippingCity,
  maxHeightOptions
}: SelectFieldProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    if (noOpenMenu) return;
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    if (noOpenMenu) return;
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpenChange)
      isMenuOpenChange(isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    if (closeMenu)
      setIsMenuOpen(false);
  }, [closeMenu]);

  return (
    <div className={'w-full flex flex-col items-start ' + className} onClick={onOpenMenuShippingCity}>
      {label && <h2 className='pl-6 mb-3 text-lg font-medium'>{label}</h2>}
      <Select
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
          Input: ({ ...rest }) => <Comps.Input {...rest} autoComplete='nope' />,
        }}
        theme={old => ({
          ...old,
          colors: {
            ...old.colors,
            primary: dropdownIndicatorColor ? dropdownIndicatorColor : '#33B5A9',
            primary25: dropdownIndicatorColor ? dropdownIndicatorColor : '#33b5a9b0',
          },
        })}
        isMulti={isMulti}
        className={`w-full ${classNameSelect}`}
        classNames={{ menuList: _ => 'customScroll' }}
        name={name}
        value={value}
        options={options}
        onChange={newValue => onChange(newValue, name)}
        isClearable={isClearable}
        isDisabled={disabled}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        menuIsOpen={isMenuOpen}
        styles={{
          dropdownIndicator: base => ({
            ...base,
            color: dropdownIndicatorColor ? dropdownIndicatorColor : '#DF2F44',
          }),
          control: base => ({
            ...base,
            minHeight: height ? height : 40,
            border: border ? '' : 'none',
            borderRadius: borderRadius ? '9999px' : (borderRadiuspx ? borderRadiuspx : ''),
            /// backgroundColor: backgroundColor ? backgroundColor : 'white',
            backgroundColor: disabled ? '#DBDBDB' : backgroundColor ? backgroundColor : 'white',
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.65 : 1,
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: paddingY ? paddingY : '',
            paddingTop: paddingY ? paddingY : '',
          }),
          menu: base => ({
            ...base,
            borderRadius: '15px',
            overflow: 'hidden',
            color: colorSelectsText ? colorSelectsText : 'black',
            scrollbarWidth: 'thin',
            scrollbarColor: '#007bff transparent',
            '::-webkit-scrollbar': {
              width: '8px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#007bff'
            }

          }),
          singleValue: base => ({
            ...base,
            color: colorText ? colorText : 'black',
          }),
          placeholder: base => ({
            ...base,
            color: colorPlaceholder ? colorPlaceholder : '#616161',
            fontSize: fontSize ? fontSize : '14px',
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? backgroundColorOption ? backgroundColorOption : dropdownIndicatorColor : '',
            color: state.isFocused ? 'white' : 'black',
            '&:hover': {
              backgroundColor: backgroundColorOption ? backgroundColorOption : dropdownIndicatorColor,
              color: 'white'
            },
          }),
          menuList: base => ({
            ...base,
            maxHeight: maxHeightOptions ? maxHeightOptions : base.maxHeight,
            zIndex: 20000
          }),
        }}
      />
      {messageError && <p className='text-primary pl-2 text-xs lg:text-base'>{messageError}</p>}
    </div>
  );
};

interface SelectFieldProps {
  name: string;
  isMulti?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  value?: SelectItem | SelectItem[];
  border?: boolean;
  borderRadius?: boolean;
  borderRadiuspx?: string;
  borderColor?: string;
  paddingY?: string;
  colorPlaceholder? : string;
  options: SelectItem[];
  onChange: (selected: MultiValue<SelectItem> | SingleValue<SelectItem>, name: string) => void;
  messageError?: string;
  dropdownIndicatorColor?: string;
  backgroundColor? : string;
  colorText?: string;
  isClearable?: boolean,
  fontSize? : string;
  disabled?: boolean;
  colorSelectsText?: string,
  backgroundColorOption?: string,
  classNameSelect?: string,
  height?: string,
  isMenuOpenChange?: Dispatch<SetStateAction<boolean>>,
  closeMenu?: boolean;
  noOpenMenu?: boolean;
  onOpenMenuShippingCity?: () => void;
  maxHeightOptions?: string
}

export interface SelectItem {
  label: string;
  value: string;
}

export default SelectField;
