export const orderHistory = {
  orderHistoryTitle: 'Historial pedidos',
  orderHistoryOrder: 'Organizar por',
  orderHistoryDate: 'Fecha de compra:',
  orderHistoryShipping: 'Fecha de envío:',
  orderHistoryProducts: 'Productos:',
  orderHistoryAdress: 'Dirección:',
  orderHistoryAgain: 'Volver a comprar',
  orderHistoryStatusShipping: 'No enviado'
};
