import { useState } from 'react';

import ContainerPopupUser from '../../../../components/common/containerPopupUser';
import TabNavigation from '../../../../components/common/tabsNavigation';
import EditAddressAndUser from './editAddressAndName';
import EditDatesSubscriptionSection from './editDatesSection';
import EditSubscriptionData from './editSubscriptionData';

import { subscriptionUserComplete } from '../../../../interfaces/subscription';

const tabs = ['Datos cliente y envío', 'Datos pedido', 'Fechas entrega'];

const EditSubscription = ({ subscription, setClosePopup }: EditSubscriptionProps) => {
  const [tabSelected, setTabSelected] = useState(0);
  const changeState = (value: number) => {
    setTabSelected(value);
  };

  return (
    <ContainerPopupUser>
      <div className='flex flex-col items-start py-3 bg-sixth rounded-[20px] w-full overflow-y-auto  scroll-gray font-subTitles'>
        <h2 className='font-titles text-pixieLightBlue text-[25px] mx-12'>Editar datos cliente y envío</h2>
        <h3 className='font-titles text-primary text-lg mx-12'>Suscripción #{subscription.transactions_id}</h3>
        <div className='flex mt-8 flex-col mx-12'>
          <div className='flex'>
            {tabs.map((tab, index) => (
              <TabNavigation
                changeState={changeState}
                isActive={tabSelected === index }
                text={tab}
                key={`${tab}-${index}`}
                value={index}
                className={`${index === 0 && 'rounded-tl-[10px]'} bg-white text-lg`}
              />
            ))}
          </div>
          {tabSelected === 0 &&
            <EditAddressAndUser
              subscription={subscription}
              setClosePopup={setClosePopup}
            />
          }

          {tabSelected === 1 &&
            <EditSubscriptionData
              subscription={subscription}
              setClosePopup={setClosePopup}
            />
          }
          {tabSelected === 2 &&
            <EditDatesSubscriptionSection
              closePopup={setClosePopup}
              subscription={subscription}
            />
          }
        </div>
      </div>
    </ContainerPopupUser>
  );
};

interface EditSubscriptionProps {
  subscription: subscriptionUserComplete,
  setClosePopup: () => void
}

export default EditSubscription;
