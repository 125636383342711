export const createCombo = {
  comboTitleStep1: 'Choose the number of recipes',
  comboTitleStep2: 'Choose your recipes',
  comboTitleStep3: 'Check your combo',
  comboTitleStep4: 'Your combo includes',
  comboSelectPlaceholder: 'Choose your recipes',
  comboBread: 'Catalogue',
  comboBread2: 'Create combo',
  comboError: 'You must order at least 20 units or your combo must be in multiples of 20.',
  comboButtonAdd: 'Add to cart',
  comboHeader: 'Choose your box with the favorite products for your pet',
  comboDescription: 'Pet Box: Curate a special collection of your pets favorite products. The perfect treat for your furry companion!',
};
