export const payment = {
  paymentBillingTitle: 'Billing',
  paymentBillingSame: 'I do not want an invoice',
  paymentBillingDifferent: 'Bill my name',
  paymentBillingBack: 'Back to delivery',
  paymentBillingPayment: 'Payment',
  paymentRResultSuccess: 'Your payment has been successful!',
  paymentRResultFailed: 'oops! Your payment has been declined',
  paymentRReturnHome: 'Back to home',
  paymentRReturnBaket: 'Back to the basket',
  paymentRPaymentInformation: 'Payment information',
  paymentRPaymentValue: 'Order total',
  paymentRDate: 'Payment date & time',
  paymentRNumTrans: 'Order number'
};
