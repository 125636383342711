import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeaderUser from '../../../components/common/headerUser';
import PageUser from '../../../components/layout/pageUser';
import ContainerPopupUser from '../../../components/common/containerPopupUser';
import FormEditCreatePets from '../myPets/FormEditCreatePets';
import PetProfileItem from './PetItemSection';

import { iconMyPets } from '../../../assets/vectors';
import { useAppContext } from '../../../hooks';

const PetProfile = () => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const { petSelected } = useAppContext();
  const navigate = useNavigate();

  return (
    <PageUser
      className='bg-[#D9EEEF] lg:bg-sixth'
      classNameChildren=' relative flex flex-col justify-center items-center pt-5 relative lg:pr-[5rem] lg:pl-[6rem]'
    >
      <HeaderUser
        imageURL={iconMyPets}
        title={t('myPets')}
        classNameArrow='w-1/6'
        classNameTitle='w-5/6'
        showButtonback={true}
      />
      {/* history */}
      <div className='w-full text-[13px] font-subTitles text-pixieLightBlue hidden lg:block'>
        <span
          onClick={() => navigate('/usuario/mascotas')}
          className='cursor-pointer hover:text-fourth'
        >{t('myPets')} </span> &gt; <span>{t('profile')} {petSelected?.name}</span>
      </div>

      <div className='bg-sixth lg:bg-transparent w-full rounded-t-[20px] py-7 flex flex-col gap-8 lg:py-5'>
        {petSelected &&
         <PetProfileItem
           pet={petSelected}
           setShowPopupEdit={setShowEdit}
         />
        }
      </div>
      {showEdit && petSelected &&
        <ContainerPopupUser>
          <FormEditCreatePets
            setShowPopup={setShowEdit}
            reloadData={() => {}}
            pet={petSelected}
          />
        </ContainerPopupUser>
      }
    </PageUser>
  );
};

export default PetProfile;
