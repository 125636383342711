import moment from 'moment';

import TestimonialComment from '../../../../components/common/testimonialComment';
import TestimonialImage from '../../../../components/common/testimonialImage';

import { Testimonial } from '../../../../interfaces/user/orders';

const MyTestimonials = ({ testimonials }: UserMyTestimonialsProps) => (
  <div className='w-full flex flex-col gap-4'>
    {testimonials.map(test => (
      <div key={test.id} className='w-full my-4 flex flex-col gap-8 lg:gap-20 lg:flex-row font-titles'>
        <TestimonialImage
          image={test.image_url}
          name={test.pet_name}
          years={test.pet_age}
          ageType={test.pet_age_type}
          typeMedia={test.type_media}
          className='px-0'
        />
        <TestimonialComment
          confession={test.coment}
          date={moment(test.created_at).format('DD MMMM YYYY')}
          personName={test.name}
        />
      </div>
    ))}
  </div>
);

interface UserMyTestimonialsProps {
  testimonials: Testimonial[];
}

export default MyTestimonials;
