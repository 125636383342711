import { initSendDataSubscription } from '../@fake/suscriptionFake';
import { AppContextTypeUser } from './indexUser';

export const appProviderUser: AppContextTypeUser = {
  // Auth
  isAuthenticated: false,
  user: undefined,
  petSelected: undefined,
  isSelectProductSubscripiton: false,
  isSubscriptionSelected: false,
  subscriptionData: initSendDataSubscription,
  subscriptionsOptions: [],
  minPriceSubscription: 0,
  referred: '',
  reloadData: false,
  isLoadingPreData: false,
};
