/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';

import Button from '../../../components/common/button';
import HeaderUser from '../../../components/common/headerUser';
import PageUser from '../../../components/layout/pageUser';
import SubscriptionsSection from './SubscriptionSection';

import { iconPixieFood } from '../../../assets/vectors';
import { useAppContext } from '../../../hooks';
import { subscriptionUserComplete } from '../../../interfaces/subscription';
import subscriptionService from '../../../services/subscriptionService';

const SubscriptionHistory = () => {
  const [subscriptions, setSubscriptions] = useState<subscriptionUserComplete[]>();
  const { reloadData, updateContext } = useAppContext();
  const [tabSelected, setTabSelected] = useState(1);

  useEffect(() => {
    getDataSubscriptions();
  }, []);

  useEffect(() => {
    if (reloadData)
      getDataSubscriptions();
  }, [reloadData]);

  const getDataSubscriptions = () => {
    subscriptionService.getMySubscriptions()
      .then(res => {
        setSubscriptions(res);
      })
      .catch();
    updateContext(old => ({ ...old, reloadData: false }));
  };

  return (
    <PageUser
      className='bg-[#D9EEEF] lg:bg-sixth'
      classNameChildren=' relative flex flex-col justify-center items-center pt-5 relative lg:pr-[5rem] lg:pl-[6rem] lg:bg-sixth'
    >
      <div className='flex justify-between w-full'>
        <HeaderUser
          imageURL={iconPixieFood}
          title='Suscripción'
          classNameArrow='w-1/6'
          classNameTitle='w-5/6'
        />
      </div>

      <div className='bg-sixth lg:bg-transparent w-full rounded-t-[20px] py-7 flex flex-col lg:py-5 px-5 lg:px-0'>
        <div className='flex justify-center items-center gap-6 w-full lg:justify-start mb-8 lg:gap-5'>
          <Button
            className={` ${tabSelected === 1 ? 'bg-pixieLightBlue text-white' : 'text-pixieLightBlue bg-[#D9EEEF]'}  gap-3  shadow-xl font-sanzBold text-lg`}
            rounded={true}
            padding='py-2 px-14'
            onClick={() => setTabSelected(1)}
          >
            Activa
          </Button>
          <Button
            className={`${tabSelected === 2 ? 'bg-pixieLightBlue text-white' : 'text-pixieLightBlue bg-[#D9EEEF]'} gap-3 shadow-xl font-sanzBold text-lg`}
            rounded={true}
            padding='py-2 px-14'
            onClick={() => setTabSelected(2)}
          >
            Historial
          </Button>
        </div>
        {tabSelected === 1 &&
          subscriptions?.map(item => {
            if (item.status === '1')
              return <SubscriptionsSection key={item.id} subscription={item} />;
          })
        }
        {tabSelected === 2 &&
          subscriptions?.map(item => {
            if (item.status === '0')
              return <SubscriptionsSection key={item.id} subscription={item} />;
          })
        }
      </div>
    </PageUser>
  );
};

export default SubscriptionHistory;
