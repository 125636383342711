import { useTranslation } from 'react-i18next';
import { notImage } from '../../../assets/vectors';
import { capitalize } from '../../../helpers/capitalize';
import { CartItem } from '../../../interfaces/basket';

const ProductCartMiniInfo = ({ product }:ProductCartMiniInfoProps) => {
  const { i18n: { language } } = useTranslation();
  return (
    <div className='pb-3 bg-white rounded-[10px] shadow-product-cart flex flex-col justify-center items-center my-6 max-h-48'>
      <div className='overflow-hidden rounded-md '>
        {product.product.url_image === '' ? (
          <img src={notImage} className='w-24 h-24 md:w-[15.7rem] md:h-[8.8rem]' />
        ) : (
          <img
            src={`${process.env.REACT_APP_URL_S3}${product.product.url_image}`}
            className='w-full h-36 md:w-[15.7rem] md:h-[8.3rem] object-contain right-0 transform transition-all duration-200'
          />
        )}
      </div>
      <div className='lg:px-5'>
        <div className='flex justify-center items-center text-center text-sm text-primary'>
          <p>{language === 'en' ? capitalize(`${product.product.name_en}`) : capitalize(product.product.name)}</p>
        </div>
        <div className='flex gap-2 justify-center items-center text-sm font-sanzBold'>
          <span>${product.product.price}</span>
          <span className='text-xs'>{product.product.presentation}</span>
        </div>
      </div>
    </div>
  );
};

interface ProductCartMiniInfoProps {
  product: CartItem
}

export default ProductCartMiniInfo;
