import Button from '../button';
import Icon from '../icon';

const FloatPopup = ({ accept, deny, description, title, textButtonAccept, textButtonDeny, className }: FloatPopupProps) => (
  <div className={`bg-[#D9EEEF] rounded-[20px] md:min-w-[18.3rem]
    ${className}
  `}>
    <div className='absolute -top-5 z-1000 rotate-[148deg] left-[45%]'>
      <Icon
        name='play_arrow'
        className='text-[#D9EEEF]'
        size='3xl'
      />
    </div>
    <div className='text-center flex flex-col gap-3 py-4 relative'>
      <div className='text-sm font-titles'>
        {title}
      </div>
      <div>{description}</div>
      <div className='flex gap-4 justify-center items-center pt-2 font-sanzBold'>
        {(textButtonDeny && deny) &&
          <Button onClick={deny} className='border border-primary text-primary text-base' padding='py-1 px-8'>
            {textButtonDeny}
          </Button>
        }
        {(textButtonAccept && accept) &&
          <Button onClick={accept} className='bg-primary text-[#FAD7B1] text-base' padding='py-1 px-8'>
            {textButtonAccept}
          </Button>
        }
      </div>
    </div>
  </div>
);

interface FloatPopupProps {
  textButtonDeny?: string,
  textButtonAccept?: string,
  accept?: () => void,
  deny?: () => void,
  title: JSX.Element,
  description?: JSX.Element,
  className?: string
}

export default FloatPopup;
