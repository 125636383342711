import { useEffect, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';

import SelectField, { SelectItem } from '../../../../../../components/form/selectField';
import TextField from '../../../../../../components/form/textField';

import { getCities } from '../../../../../../helpers/formCheckoutHelper';
import { edituserAndAddressEcommerceValidate, editUserSubscription, subscriptionUserComplete } from '../../../../../../interfaces/subscription';
import { Address } from '../../../../../../interfaces/user/user';
import profileService from '../../../../../../services/userService/profileService';
import { useAppContext } from '../../../../../../hooks';

const ShippingDataSection = ({ subscription, countries, form, handleFormChange, handleSelectChange, setForm, validatorBody }: ShippingDataSectionProps) => {
  const [addressSaved, setAddressSaved] = useState<Address[]>([]);
  const [addressSavedSelected, setAddressSavedSelected] = useState<SelectItem[]>([]);
  const { user } = useAppContext();

  useEffect(() => getDataAddress(), [user?.token]);

  const getDataAddress = () => {
    profileService.getAddressByUser(user?.token ? user.token : '')
      .then(res => {
        setAddressSaved(res);
        const selects:SelectItem[] = [];
        res.forEach(item => {
          selects.push({ label: `${item.address}, ${item.street}`, value: item.id });
        });

        setAddressSavedSelected(selects);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleChangeDataAddressSaved = (selected: SingleValue<SelectItem>) => {
    const dataAddress = addressSaved?.find(item => item.id === selected?.value);
    if (dataAddress) {
      const { street, floor_number, colony, home_number, ref, zip_code, state, city, country_id } = dataAddress;
      const finIndexState = form.states.findIndex(item => item.value === state);
      const citiesTemp = getCities(form.states[finIndexState].value);
      const findCity = citiesTemp.findIndex(item => item.value === city);
      const findCountry = form.countries?.findIndex(item => item.value === country_id) as number;

      setForm(old => ({ ...old,
        address: street,
        apartment: floor_number,
        colony, houseNumber: home_number,
        reference: ref,
        zip_code: { label: zip_code, value: zip_code },
        state: finIndexState > -1 ? form.states[finIndexState] : { value: '', label: '' },
        city: findCity > -1 ? citiesTemp[findCity] : { value: '', label: '' },
        country: findCountry > -1 ? countries[findCountry] : { value: '', label: '' },
      }));
    }
  };

  return (
    <div className='flex flex-col gap-6'>
      <span className='font-titles text-pixieLightBlue text-left text-base'>Datos de envio</span>
      <div className='font-subTitles text-sm flex flex-col gap-4'>
        <SelectField
          placeholder='Direcciones guardadas'
          name='addressSaved'
          className='lg:w-1/2 font-sanzSemiBold text-black'
          options={addressSavedSelected}
          onChange={e => handleChangeDataAddressSaved(e as SingleValue<SelectItem>)}
          borderColor='#000'
          paddingY='0.43rem'
          backgroundColor='#D9EEEF'
          borderRadius={true}
          dropdownIndicatorColor='#33B5A9'
        />
        <TextField
          handler={handleFormChange}
          name='address'
          value={form.address}
          placeholder='Nombre de la calle*'
          fieldClassName='py-4 bg-[#D9EEEF]'
          border='rounded-[20px]'
          required
        />
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <TextField
            handler={handleFormChange}
            name='houseNumber'
            value={form.houseNumber}
            placeholder='Número de Condominio, Casa o Edificio*'
            fieldClassName='py-4 bg-[#D9EEEF]'
            border='rounded-[20px]'
            className='lg:w-1/2'
            messageError={validatorBody.houseNumber.message}
            required
          />
          <TextField
            handler={handleFormChange}
            name='apartament'
            value={form.apartament}
            placeholder='Número Interior ( Ej: Piso, Oficina, Dpto) '
            fieldClassName='py-4 bg-[#D9EEEF]'
            border='rounded-[20px]'
            className='lg:w-1/2'
            messageError={validatorBody.apartment.message}
          />
        </div>
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <TextField
            handler={handleFormChange}
            name='reference'
            value={form.reference}
            placeholder='Entre calles (Referencias)'
            fieldClassName='py-4 bg-[#D9EEEF]'
            border='rounded-[20px]'
            className='lg:w-1/2'
            messageError={validatorBody.reference.message}
          />
          <TextField
            handler={handleFormChange}
            name='colony'
            value={form.colony}
            placeholder='Código Postal*'
            fieldClassName='py-4 bg-[#D9EEEF]'
            border='rounded-[20px]'
            className='lg:w-1/2'
            required
            messageError={validatorBody.colony.message}
          />
        </div>
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <SelectField
            name='country'
            onChange={handleSelectChange}
            options={countries}
            value={form.country}
            borderRadius={true}
            placeholder='País*'
            paddingY='8px'
            className='lg:w-1/2'
            backgroundColor='#D9EEEF'
            messageError={validatorBody.country.message}
            dropdownIndicatorColor='#33B5A9'
          />
          <SelectField
            name='state'
            onChange={handleSelectChange}
            options={form.states}
            value={form.state}
            borderRadius={true}
            placeholder='Estado* '
            paddingY='8px'
            className='lg:w-1/2'
            backgroundColor='#D9EEEF'
            messageError={validatorBody.state.message}
            dropdownIndicatorColor='#33B5A9'
          />
        </div>
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <SelectField
            name='city'
            onChange={handleSelectChange}
            options={form.cities.sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })}
            value={form.city}
            borderRadius={true}
            placeholder='Ciudad*'
            paddingY='8px'
            className='lg:w-1/2'
            backgroundColor='#D9EEEF'
            messageError={validatorBody.city.message}
            dropdownIndicatorColor='#33B5A9'
          />
          <SelectField
            name='zipCode'
            onChange={handleSelectChange}
            options={form.zipCodes.sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })}
            value={form.zipCode}
            borderRadius={true}
            placeholder='Código Postal*'
            paddingY='8px'
            className='lg:w-1/2'
            backgroundColor='#D9EEEF'
            messageError={validatorBody.zip_code.message}
            dropdownIndicatorColor='#33B5A9'
          />
        </div>
      </div>
    </div>
  );
};

interface ShippingDataSectionProps {
  subscription: subscriptionUserComplete,
  handleFormChange: ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  handleSelectChange: (selected: MultiValue<SelectItem> | SingleValue<SelectItem>, name: string) => void,
  form: editUserSubscription,
  validatorBody: edituserAndAddressEcommerceValidate,
  setForm: React.Dispatch<React.SetStateAction<editUserSubscription>>,
  countries: SelectItem[],
}

export default ShippingDataSection;
