/* eslint-disable quotes */
export const myPets = {
  myPets: 'My pets',
  myPetsBackground: 'Choose a background color',
  myPetFormName: "What is your pet's name?",
  mypetFormSpecie: 'Species of your pet',
  myPetFormBorn: 'When was your pet born?',
  myPetFormWeight: 'Weight',
  myPetFormActivity: 'Activity',
  myPetFormAllergies: 'Allergies or diseases',
  myPetFormYes: 'Yes',
  myPetFormCancel: 'Cancel',
  myPetFormLoading: 'Loading...',
  myPetFormUpdate: 'Update',
  myPetFormCreate: 'Create',
  myPetNewPet: 'New pet',
  myPetEdit: 'Edit Pet',
  profile: 'Profile',
  dog: 'Dog',
  cat: 'Xat',
  years: 'Years',
  year: 'Year',
  myPetsSeeProfile: 'See profile',
  myPetSpecies: 'Species',
  age: 'Age',
  weight: 'Weight',
  PhysicalActivity: 'Physical Activity',
  myPetsBirthday: 'Birthday',
  myPetsDiseases: 'Diseases and allergies.'
};
