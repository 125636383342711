import IconButton from '../../common/iconButton';
import TextField from '../textField';
import { useState } from 'react';
import { DayPicker, ClassNames, Matcher } from 'react-day-picker';
import es from 'date-fns/locale/es';
import styles from 'react-day-picker/dist/style.module.css';
import Button from '../../common/button';
import moment from 'moment';

const css = `
  .my-selected:not([disabled]) {
    font-weight: bold;
    border: 2px solid #33B5A9;
  }
  .my-selected:hover:not([disabled]) {
    border-color: #33B5A9;
    color: #33B5A9;
  }
  .my-today {
    font-weight: bold;
    color: black;
  }
`;

const TextCalendarField = ({ placeholder, value, setValue, nameField, className, messageError, textFielClassName, colorIconCalendar, disabled, label, labelClassName, required, limitToday = false, toYear, captionLayout, fromMonth, toMonth, disabledDays, border }: TextCalendarFieldProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [daySelected] = useState(new Date(value));

  const classNames: ClassNames = {
    ...styles,
    head: 'bg-pixieLightBlue text-white',
    caption_label: 'text-pixieLightBlue mr-5 text-lg font-sanzSemiBold capitalize',
    nav: 'text-pixieLightBlue',
    caption_dropdowns: 'flex mb-3 mt-1 mx-2',
    dropdown_icon: 'w-full flex justify-center items-center'
  };

  return (
    <div className={`${className ? className : 'w-full'} flex flex-col`}>
      {label && <label className={`${labelClassName ? labelClassName : 'pl-6 mb-3 text-lg font-medium'}`}>{label}</label>}
      <div className='w-full flex relative'>
        <TextField
          name='orderDate'
          handler={() => {}}
          value={value}
          placeholder={placeholder}
          fieldClassName={`${textFielClassName ? textFielClassName : 'py-[0.35rem] px-[1.40rem]'}`}
          border={border ? border : 'rounded-[20px]'}
          className='w-full'
          disabled={disabled}
          required={required}
        />
        <div className='flex items-center px-2 w-1/6 absolute right-0 top-3'>
          <IconButton name='calendar_month' size='lg' sizeContainer='w-6' className={`${colorIconCalendar ? `text-${colorIconCalendar}` : 'text-pixieLightBlue'}`} onClick={() => setShowCalendar(!showCalendar)} shadow={false} typeButton='button' />
        </div>
        {messageError && <p className='text-primary pl-2 text-xs lg:text-base'>{messageError}</p>}
        { showCalendar &&
        <>
          <style>{css}</style>
          <div className='absolute z-20 top-8 right-0'>
            <DayPicker
              locale={es}
              mode='single'
              fromYear={1900}
              toYear={toYear ? toYear : Number.parseInt(moment().format('YYYY'), 10)}
              selected={daySelected}
              onSelect={(date: Date | undefined) => {
                setValue(date, nameField);
                setShowCalendar(false);
              }}
              className='bg-white rounded-3xl p-2 shadow-product-cart'
              classNames={classNames}
              toDate={limitToday ? new Date() : undefined}
              captionLayout={captionLayout ? captionLayout : 'buttons'}
              fromMonth={fromMonth}
              toMonth={toMonth}
              modifiersClassNames={{
                selected: 'my-selected',
                today: 'my-today'
              }}
              disabled={disabledDays}
              footer={ <div className='flex justify-end'><Button className='mb-1 mr-1 text-white px-2 py-2 rounded-xl bg-pixieLightBlue' onClick={() => setShowCalendar(!showCalendar)}>Cerrar</Button></div>}
            />
          </div>
        </>
        }
      </div>
    </div>
  );
};

interface TextCalendarFieldProps{
  placeholder:string,
  value: string,
  setValue: (date: Date | undefined, nameField: string) => void,
  nameField: string,
  className?: string,
  messageError?: string;
  textFielClassName?: string;
  colorIconCalendar?: 'primary' | 'redPixie'
  disabled?: boolean;
  label?: string;
  labelClassName?:string;
  required?: boolean;
  limitToday? : boolean,
  toYear?: number,
  captionLayout?: 'buttons' | 'dropdown',
  fromMonth?: Date,
  toMonth?:Date,
  disabledDays?: Matcher | Matcher[],
  border?: string

}

export default TextCalendarField;
