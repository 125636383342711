export const coupon = {
  couponCodePlaceholder: 'Ingresa el código de tu cupón o referido',
  couponApply: 'Aplicar',
  couponRemove: 'Remover',
  couponError1: '* El cupon o el referido no fue encontrado',
  couponError2: '* Este código ya fue redimido',
  couponError3: '* Este código ya no esta disponible',
  couponError4: '* Debes registrarte para validar el cupon',
  couponError5: '* Se debe ingresar un correo',
  couponError6: '* El usuario exedió los usos de este cupon',
  couponError7: '* Ya has utilizado este codigo de referido',
  couponError8: '* No puedes usar tu mismo codígo de referido',
  couponError9: '* El codigo solo es para suscripción',
  couponError10: '* El codigo es para compra normal',
};
