import { getSelectRoles, LoginForm, recoveryPassword, RegisterForm, validateAccount } from '../../interfaces/auth';
import { AllDataUser } from '../../interfaces/user/user';
import api from '../axios';

export class AuthService {
  init() {}

  getAllRoles = (): Promise<getSelectRoles[]> =>
    new Promise((resolve, reject) => {
      api.get('pixie-customers/api/users/roles').then(res => {
        resolve(res.data.data);
      }).catch(err => {
        reject(err.response.data.message);
      });
    });

  sendDataRegister = (formRegister: RegisterForm, location: string): Promise<string> => {
    const newFormRegister = {
      ...formRegister,
      role_id: formRegister.role_id.value,
      gender: 'male'
    };

    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/users', newFormRegister).then(res => {
        if (location === 'en')
          resolve(res.data.message_en);
        else
          resolve(res.data.message);
      }).catch(err => {
        if (location === 'en')
          reject(err.response.data.message_en);
        else
          reject(err.response.data.message);
      });
    });
  };

  validateUser = (formValidate: validateAccount, language: string): Promise<AllDataUser> =>
    new Promise((resolve, reject) => {
      api.post('pixie-customers/api/users/verify-email', formValidate).then(res => {
        if (language === 'en')
          resolve(res.data);
        else
          resolve(res.data);
      }).catch(err => {
        if (language === 'en')
          reject(err.response.data.message_en);
        else
          reject(err.response.data.message);
      });
    });

  login = (formLogin: LoginForm, language: string): Promise<AllDataUser> =>
    new Promise((resolve, reject) => {
      api.post('pixie-customers/api/users/login', formLogin).then(res => {
        resolve(res.data);
      }).catch(err => {
        if (language === 'en')
          reject(err.response.data.message_en);
        else
          reject(err.response.data.message);
      });
    });

  verifyToken = (token: string): Promise<AllDataUser> =>
    new Promise((resolve, reject) => {
      api.get('pixie-customers/api/users', {
        headers: {
          Authorization: token
        }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err.message);
      });
    });

  loginSocialMedia = (token: string, language: string): Promise<AllDataUser> =>

    new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-customers/api/users/checking-social',
        headers: { Authorization: token } })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          if (language === 'en')
            reject(error.response.data.message_en);
          else
            reject(error.response.data.message);
        }
        );
    });

  recoveryPassword = (form: recoveryPassword, language: string): Promise <any> => {
    const dataSend = {
      email: form.email,
      redirectUrl: `${process.env.REACT_APP_URL_BASE}autenticacion/resetear`,
      language: language.toUpperCase()
    };
    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/users/recover-password', dataSend)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          if (language === 'en')
            reject(error.response.data.message_en);
          else
            reject(error.response.data.message);
        }
        );
    });
  };

  changePassword = (password: string, token: string, language: string) =>
    new Promise((resolve, reject) => {
      api.put('pixie-customers/api/users/reset-password', password, {
        headers: {
          authorization: token
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          if (language === 'en')
            reject(error.response.data.message_en);
          else
            reject(error.response.data.message);
        }
        );
    });
}

export default new AuthService();
