export const login = {
  loginHasAccount: '¿No tienes una cuenta?',
  loginSignUp: 'Regístrate',
  loginTitle: '¡HOLA!',
  login: 'Ingresar',
  loginSocialMedia: 'Ingresar con ',
  LoginsignIn: 'Ingresa a tu cuenta',
  LoginImagenText: '¿Sabías que la nariz de los perros equivale a su huella dactilar?',
  loginEmail: 'Correo electrónico',
  loginPassword: 'Contraseña',
  loginRemember: 'Recordarme',
  loginRecoverPassword: 'Olvidé mi contraseña',
  loginAlsoText: 'O también puedes'
};

export const register = {
  registerHasAccount: '¿Ya tienes una cuenta?',
  registerSignUp: 'Iniciar sesión',
  registerTitle: '¡Comencemos!',
  registeName: 'Nombre',
  registeLastName: 'Apellido',
  registeEmail: 'Correo electrónico',
  registerPhone: 'Celular',
  registerBornDate: 'Fecha de nacimiento YYYY-MM-DD',
  registerGender: 'Genéro',
  registerPassword: 'Contraseña',
  registerConfirmPassword: 'Confirmar contraseña',
  registerCreate: 'Crear'
};

export const recovery = {
  recoveryTitle: 'Olvidaste tu contraseña?',
  recoveryDescription: 'Ingresa tu correo electrónico y te enviaremos instrucciones para recuperarla.',
  recoverySend: 'Enviar',
  recoveryReturn: 'Volver',
  recoverySenEmail: '¡Hemos enviado un correo para que recuperes tu contraseña!'
};

export const verifyCode = {
  verifyTitle: 'Ingresa el código de verificación',
  verifyDescription: 'Introduce el código de 6 dígitos que enviamos a tu correo',
  verifyDetectedCode: 'Detectamos que tienes un codigo de activación pendiente, si no es asi has ',
  verifyDetectedCode2: 'Click aquí'
};

