import { useEffect, useState } from 'react';
import IconButton from '../iconButton';
import moment from 'moment';
import { useAppContext } from '../../../hooks';
import subscriptionService from '../../../services/subscriptionService';
import { SubscriptionOrders, subscriptionUserComplete } from '../../../interfaces/subscription';
import PopupDecisionUser from '../../layout/popupDecisionUser';
import SelectDayActive from '../calendarWithDaysActive/SelectDaySection';

const ItemTableDateSubscription = ({ order, subscription }: ItemTableDateSubscriptionProps) => {
  const [date, setDate] = useState<Date>();
  const { toast, updateContext } = useAppContext();
  const [dateOld, setDateOld] = useState<Date>();
  const [showConfirmChange, setShowConfirmChange] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateMoment, setDateMoment] = useState(moment(order.delivery_date));

  useEffect(() => {
    const initialDate = new Date(order.delivery_date);
    initialDate.setDate(initialDate.getDate() + 1);
    setDate(initialDate);
    setDateMoment(moment(initialDate));
  }, [order]);

  const handleAcceptChange = () => {
    setShowConfirmChange(false);
    setDate(dateOld);
    subscriptionService.editDateOrden(order, dateMoment.format('YYYY-MM-DD'))
      .then(() => {
        toast.fire({
          icon: 'success',
          title: 'Fecha editada correctamente.',
        });
        updateContext(old => ({ ...old, reloadData: true }));
        setShowCalendar(false);
      })
      .catch(() => {
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: 'Ocurrio un error.',
        });
      });
  };

  const handleChangeDate = (date: Date | undefined) => {
    const dateConvert = moment(date);
    setDateOld(date);
    setDateMoment(dateConvert);
    setShowConfirmChange(true);
  };

  const handleClosePopup = () => {
    setShowConfirmChange(false);
  };

  return (
    <tr className='bg-white text-center text-[10px] lg:text-[10px] lg2:text-xs xl2:text-sm'>
      <td className='border border-[D9D9D9] text-center py-2 pl-5'>{order.purchases_orders.internal_order_id}</td>
      <td className='border border-[D9D9D9] capitalize pl-2'>{dateMoment.format('YYYY')}-{dateMoment.format('MM')}-{dateMoment.format('DD')}</td>
      <td className='border border-[D9D9D9] pl-2'>{order.purchases_orders.purchase_order_status.key}</td>
      <td className='border border-[D9D9D9] relative'>
        <IconButton name='calendar_month'
          className='text-pixieLightBlue flex justify-center items-center w-full'
          onClick={() => setShowCalendar(!showCalendar)}
          shadow={false}
          sizeContainer='w-full'
        />
        {showConfirmChange &&
          <PopupDecisionUser
            handleAccept={handleAcceptChange}
            handleDeny={handleClosePopup}
            textAccept='Cambiar'
            textDeny='Cancelar'
            title='¿Estás seguro que quieres cambiar la fecha de entrega?'
            type='question'
          />
        }
        {showCalendar &&
        <div className='absolute z-20 top-6 right-0'>
          <SelectDayActive
            closeCalendar={setShowCalendar}
            date={date}
            handleSelectDay={handleChangeDate}
            subscription={subscription}
          />
        </div>
        }
      </td>
    </tr>
  );
};

interface ItemTableDateSubscriptionProps {
  order: SubscriptionOrders,
  subscription: subscriptionUserComplete
}
export default ItemTableDateSubscription;
