import { useEffect, useState } from 'react';

import ItemTableDateSubscription from '../../../../../../components/common/itemTableDateSubscription';
import Table from '../../../../../../components/common/table';

import { SubscriptionOrders, subscriptionUserComplete } from '../../../../../../interfaces/subscription';
import subscriptionService from '../../../../../../services/subscriptionService';

const titlesTable = ['Número orden', 'Fecha', 'Estado'];
const TableDatesSubscription = ({ subscription, showEdit = false, hiddenTitle = true }: TableDatesSubscriptionProps) => {
  const [datesOrders, setdatesOrders] = useState<SubscriptionOrders[]>();
  const [titlesTables, setTitlesTables] = useState(titlesTable);

  useEffect(() => getDataDates(), []);

  useEffect(() => {
    if (showEdit)
      setTitlesTables([...titlesTable, ' ']);
  }, [showEdit]);

  const getDataDates = () => {
    subscriptionService.getordersBySubscription(subscription.id)
      .then(res => {
        setdatesOrders(res);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className='flex flex-col'>
      {!hiddenTitle &&
        <div>
          <p className='font-titles'>Débito Nº 1</p>
          <p>{datesOrders && (datesOrders.length > 0 && datesOrders[0].created_at.split('T')[0])}</p>
        </div>
      }

      {/* Table */}
      <Table titles={titlesTables} className='lg:text-xs lg2:text-base mt-3'>
        <>
          {datesOrders?.map(order => (
            <ItemTableDateSubscription
              key={order.id}
              order={order}
              subscription={subscription}
            />)
          )}
        </>
      </Table>

    </div>
  );
};

interface TableDatesSubscriptionProps {
  subscription: subscriptionUserComplete,
  showEdit?: boolean,
  hiddenTitle?: boolean
}

export default TableDatesSubscription;
