import { trashIcon } from '../../../assets/vectors';
import Button from '../../common/button';
import Icon from '../../common/icon';
import SelectField, { SelectItem } from '../selectField';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import useForm from '../../../hooks/useForm';
import { useEffect, useState } from 'react';
import { infoSelectSPandEn } from '../../../interfaces/product';

const SelectList = ({ title,
  placeholder,
  values,
  bgColor,
  className,
  setValues,
  selectOptions,
  languaje,
  dataSPandEn,
  listClassName,
  showDelete = true,
  limitSelects,
  disabled
}: SelectListProps) => {
  const [hasError, setError] = useState(false);
  const [valuesLabel, setvaluesLabel] = useState<SelectItem[]>([]);
  const { handleSelectChange, form, setForm } = useForm({
    selectOptions,
    itemSelected: { label: '', value: '' }
  },
  () => handleSubmit);

  // Fill select and find values label
  useEffect(() => {
    setvaluesLabel([]);
    const selectFilter:SelectItem[] = [];
    selectOptions.forEach(option => {
      const optionFind = values?.find(item => item.toLocaleLowerCase() === option.value.toLocaleLowerCase());
      if (optionFind === undefined)
        selectFilter.push({
          label: option.label,
          value: option.value
        });
    });
    setForm(old => ({ ...old, selectOptions: selectFilter }));

    values?.forEach(val => {
      const findOptionSelect = selectOptions?.find(item => item.value.toLocaleLowerCase() === val.toLocaleLowerCase());
      if (findOptionSelect !== undefined)
        setvaluesLabel(old => ([...old, findOptionSelect]));
    });
  }, [selectOptions, values]);

  // Show icon error for 1/2 seconds
  useEffect(() => {
    if (hasError)
      setTimeout(() => {
        setError(false);
      }, 500);
    return () => {};
  }, [hasError]);

  // Reorder array form with dragtable
  const reorder = (list: string[], startIndex:number, endIndex:number):string[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const deleteItem = (list:SelectItem[], index: number) => {
    const newValues = [...list];
    newValues?.splice(index, 1);
    setValues(newValues.map(item => item.value));
  };

  const handleSubmit = () => {
    const value = values?.find(item => item.toLocaleLowerCase() === form.itemSelected.value.toLocaleLowerCase());
    // Validate if values has limit selected
    if (limitSelects)
      if (limitSelects === values?.length)
        return setError(true);
    // If not has a limitSelected continue normal
    if (value || form.itemSelected.value === '')
      setError(true);
    else {
      const newValues = values ? [...values] : [];
      newValues.push(form.itemSelected.value);
      setValues(newValues);
    }
  };

  // Handle Drag
  const handleDragEnd = (res: DropResult) => {
    const { source, destination } = res;
    if (!destination)
      return;

    setValues(reorder(values ? values : [], source.index, destination.index));
  };

  return (
    <div className={`${bgColor ? bgColor : 'bg-white'}
    pt-2 px-5 rounded-[20px] flex flex-col gap-4
    ${className}
    `}>
      <span className='text-lg font-sanzBold'>{title}</span>
      {languaje !== 'en' &&
      <div className='relative'>
        <SelectField
          name='itemSelected'
          onChange={handleSelectChange}
          options={form.selectOptions}
          value={form.itemSelected.value ? form.itemSelected : undefined}
          backgroundColor='#F0F6F6'
          borderRadius={true}
          placeholder={placeholder}
          paddingY='0.4rem'
          disabled={disabled}
        />
        <Button
          className={`absolute rounded-full  w-[40px] h-[40px] flex justify-center items-center top-1 right-2
            ${hasError ? 'bg-redPixie' : 'bg-pixieLightBlue'}
          `}
          rounded={true}
          onClick={handleSubmit}
        > {hasError
            ?
            <Icon name='close' className='text-white animate__animated animate__flash' size='2xl'/>
            :
            <Icon name='arrow_forward' className='text-white' size='2xl'/>
          }
        </Button>
      </div>
      }
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={title}>{
          droppableProvider =>
            <ul {...droppableProvider.droppableProps}
              ref={droppableProvider.innerRef}
              className={`
              max-h-[180px] flex flex-col gap-3 overflow-y-auto scroll-blue relative
              ${listClassName && listClassName}
              `}>
              {valuesLabel?.map((res, index) => (
                <Draggable key={res.value} draggableId={res.value} index={index}>
                  {draggableProvider =>
                    <li
                      className='pl-2 border-b border-pixieLightBlue flex justify-between pb-2 pr-1 position-static-important items-center'
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.draggableProps}
                      {...draggableProvider.dragHandleProps }
                    >
                      <div className='flex items-center gap-3'>
                        <Icon name='drag_handle' className='text-gray-400'/>
                        <span>{ languaje === 'en' ?
                          dataSPandEn?.find(item => item.key === res.value) ? dataSPandEn?.find(item => item.key === res.value)?.name_en : res.label
                          :
                          res.label
                        }</span>
                      </div>
                      {showDelete &&
                        <img src={trashIcon} className='text-lg cursor-pointer w-4 h-4' alt='trashIcon' onClick={() => deleteItem(valuesLabel, index)}/>
                      }
                    </li>
                  }
                </Draggable>
              ))}
              {droppableProvider.placeholder}
            </ul>
        }
        </Droppable>
      </DragDropContext>
    </div>
  );
};

interface SelectListProps {
  title: string,
  placeholder: string,
  setValues: (values:string[]) => void,
  values?: string[] | null,
  bgColor?: string,
  className?: string,
  listClassName?: string,
  selectOptions: SelectItem[],
  languaje?: 'es' | 'en',
  dataSPandEn?: infoSelectSPandEn[],
  showDelete?: boolean,
  limitSelects?: number,
  disabled?:boolean
}

export default SelectList;
