import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { addDays, startOfDay, startOfMonth } from 'date-fns';
import { Button, ClassNames, DayPicker } from 'react-day-picker';
import es from 'date-fns/locale/es';
import styles from 'react-day-picker/dist/style.module.css';
import { subscriptionUserComplete } from '../../../interfaces/subscription';
moment.updateLocale('es', {
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});

const SelectDayActive = ({ handleSelectDay, closeCalendar, date, subscription }:SelectDaySectionProps) => {
  const today = startOfDay(new Date());
  const toMonth = addDays(today, 30);
  const fromMonth = startOfMonth(new Date());
  const [disabledDaysOfWeek, setDisabledDaysOfWeek] = useState([0, 1, 2, 3, 4, 5, 6]);
  // Disabled Today
  const todayDisabled = new Date(today);

  const classNames: ClassNames = {
    ...styles,
    head: 'bg-pixieLightBlue text-white',
    caption_label: 'text-pixieLightBlue text-lg font-sanzSemiBold capitalize',
    nav: 'text-pixieLightBlue',
  };

  // Disabled days
  const endDate = new Date(); // Fecha de fin (dentro de 30 días)
  endDate.setDate(endDate.getDate() + 50);

  const disabledDates = [];
  // eslint-disable-next-line no-unmodified-loop-condition
  for (const date = today; date <= endDate; date.setDate(date.getDate() + 1))
    if (disabledDaysOfWeek.includes(date.getDay()))
      disabledDates.push(new Date(date));

  const disabledDays = [
    { from: todayDisabled, to: new Date(1800) },
    ...disabledDates
  ];

  useEffect(() => {
    getAvailableDay();
  }, []);

  const getAvailableDay = () => {
    const city = subscription.general_order.metadata.shippingDetails.regionvalue;
    if (city === 'MX-QUE')
      setDisabledDaysOfWeek([0, 1, 2, 3, 5, 6, 7]);
    else if (city === 'MX-CMX' || city === 'MX-EDMX')
      setDisabledDaysOfWeek([0, 2, 4, 6]);
  };

  return (
    <DayPicker
      locale={es}
      mode='single'
      toMonth={toMonth}
      fromMonth={fromMonth}
      disabled={disabledDays}
      selected={date}
      onSelect={(date: Date | undefined) => handleSelectDay(date)}
      className='bg-white rounded-3xl p-2 z-50'
      classNames={classNames}
      defaultMonth={date}
      modifiersClassNames={{
        selected: 'my-selected',
        today: 'my-today'
      }}
      footer={
        <div className='flex justify-end'>
          <Button className='mb-1 mr-1 text-white px-2 py-2 rounded-xl bg-primary' onClick={() => closeCalendar(false)}>
            Cerrar
          </Button>
        </div>}
    />
  );
};

interface SelectDaySectionProps {
  handleSelectDay: (date: Date | undefined) => void,
  closeCalendar: Dispatch<SetStateAction<boolean>>,
  date: Date | undefined,
  subscription: subscriptionUserComplete
}

export default SelectDayActive;
