/* eslint-disable complexity */
/* eslint-disable key-spacing */
import { useState, Dispatch, SetStateAction } from 'react';
import validator from 'validator';
import { useTranslation } from 'react-i18next';

import IconButton from '../../../components/common/iconButton';
import ProfileImage from '../../../components/common/profileImage';
import TextField from '../../../components/form/textField';
import SelectField from '../../../components/form/selectField';
import RadioField from '../../../components/form/radioField';
import CheckField from '../../../components/form/checkField';
import Button from '../../../components/common/button';
import TextCalendarField from '../../../components/form/textCalendarField';

import { useAppContext, useForm } from '../../../hooks';
import useValidator from '../../../hooks/useValidator';
import { CreatePet, CreatePetValidate, Pet } from '../../../interfaces/user/pets';
import petsService from '../../../services/userService/petsService';

const gen = [{ value: 'male', label: 'Macho' }, { label: 'female', value: 'Hembra' }];
const types = [{ value: 'cat', label: 'Gato' }, { value: 'dog', label: 'Perro' }];
const typesEn = [{ value: 'cat', label: 'Cat' }, { value: 'dog', label: 'Dog' }];

const activityList = [
  { value: 'low', label: 'Baja' },
  { value: 'medium', label: 'Normal' },
  { value: 'high', label: 'Alta' }
];
const activityListEnglish = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Normal' },
  { value: 'high', label: 'High' }
];
const FormEditCreatePets = ({ pet, reloadData, setShowPopup }: FormEditCreatePetsProps) => {
  const [allergiesST, setAllergiesST] = useState(pet ? JSON.parse(pet.diseases).length > 0 : false);
  const [loading, setLoading] = useState(false);
  const { user, toast, updateContext } = useAppContext();
  const { t, i18n: { language } } = useTranslation();
  const { form, handleFormChange, handleSelectChange, onSubmit, setForm, handleRadioChange, handleChangeDate } = useForm<CreatePet>({
    date_birth: pet ? pet.date_birth : '',
    color: '#FE9F60',
    diseases: pet ? JSON.parse(pet.diseases) : [],
    gen: pet ? { value: pet.gen, label: `${gen.find(item => item.value === pet.gen)}` } : { value: 'male', label: 'Macho' },
    genders: gen,
    img: {},
    imgShow: pet ? pet.img : '',
    name: pet ? pet.name : '',
    type: pet ? { value: pet.type, label: `${types.find(item => item.value === pet.type)?.label}` } : { label: '', value: '' },
    types: language === 'es' ? types : typesEn,
    weight: pet ? pet.weight : 0,
    activityList : language === 'en' ? activityListEnglish : activityList,
    activity: pet ? { value: pet.activity, label: `${activityList.find(item => item.value === pet.activity)?.label}` } : { label: '', value: '' }
  }, () => handleValidForm());

  const { handlePutMessageError, resetValidator, validatorBody } = useValidator<CreatePetValidate>({
    date_birth: { message: '' },
    gen: { message: '' },
    img: { message: '' },
    name: { message: '' },
    type: { message: '' },
    weight: { message: '' },
    activity: { message: '' }
  });

  const handleValidForm = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (!validator.isLength(form.name, { min: 1 })) {
      handlePutMessageError('name', 'El nombre de la mascota debe ser mayor o igual a 1 caracter');
      error = true;
    }

    if (validator.equals(form.type.value, '')) {
      handlePutMessageError('type', 'Se debe seleccionar un tipo de mascota');
      error = true;
    }

    if (!validator.isDate(form.date_birth, {
      format: 'YYYY-MM-DD'
    })) {
      handlePutMessageError('date_birth', 'El formato del campo debe ser YYYY-MM-DD');
      error = true;
    }

    if (form.weight < 0) {
      handlePutMessageError('weight', 'El peso debe seer mayor a 0');
      error = true;
    }

    if (validator.equals(form.activity.value, '')) {
      handlePutMessageError('activity', 'Se debe seleccionar un tipo de actividad');
      error = true;
    }

    if (!error)
      handleSubmit();
  };

  const handlerClosePopup = () => {
    setShowPopup(false);
  };

  const changeColor = (color: string) => {
    setForm(old => ({ ...old, color }));
  };

  const handleSetDiseases = (name:string) => {
    if (form.diseases.includes(name)) {
      const diseases = form.diseases.filter(item => item !== name);
      setForm(old => ({ ...old, diseases }));
    } else
      setForm(old => ({ ...old, diseases: [...old.diseases, name] }));
  };

  const handleSubmit = async () => {
    // Upload files
    const uploadFileSuccesfull = await handleUploadFile();
    setLoading(true);

    if (pet)
      petsService.editPet(form, uploadFileSuccesfull, pet.id).then(res => {
        toast.fire({
          icon: 'success',
          title: 'Mascota actualizada correctamente',
        });
        setLoading(false);
        handlerClosePopup();
        updateContext(old => ({ ...old, petSelected: { ...res, img: uploadFileSuccesfull === '' ? res.img : URL.createObjectURL(form.img[0]) } }));
      }).catch(err => {
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: err,
        });
        setLoading(false);
      });
    else
      petsService.createPet(form, uploadFileSuccesfull)
        .then(res => {
          toast.fire({
            icon: 'success',
            title: 'Mascota creada correctamente',
          });
          setLoading(false);
          handlerClosePopup();
          reloadData();
        }).catch(err => {
          toast.fire({
            icon: 'error',
            title: err,
          });
          setLoading(false);
        });
  };

  const handleUploadFile = async () => {
    let imgUrl = '';
    if (form.img.length > 0)
      await petsService.uploadImagePet(form.img, form, user ? user.user.id : '').then(res => {
        imgUrl = res;
      }
      ).catch(error => {
        console.log(error);
      });

    return imgUrl;
  };

  return (
    <div className='bg-sixth w-full mx-3 rounded-[20px] py-3 px-3 lg:py-8 lg:px-14'>
      <div className='flex justify-end'>
        <IconButton.mini
          name='close'
          onClick={handlerClosePopup}
          className='text-black lg:hidden'
          size='2xl'
          sizeContainer='w-5 h-5'
        />
      </div>
      <h3 className='text-pixieLightBlue text-left text-lg mb-4 lg:text-[25px]'>{pet ? t('myPetEdit') : t('myPetNewPet')}</h3>
      <form onSubmit={onSubmit} className='font-subTitles text-sm flex flex-col gap-4'>
        <div className='flex flex-col lg:flex-row lg:justify-center lg:items-center lg:gap-6'>
          <ProfileImage
            showName={false}
            handleChangeImg={(file: FileList | null) => {
              setForm(old => ({ ...old, img: file }));
            }}
            id='profilePet'
            img={form.img[0] ? URL.createObjectURL(form.img[0]) : form.imgShow ? form.imgShow : ''}
          />
          <div className='flex flex-col justify-center items-center gap-4'>
            <span>{t('myPetsBackground')}</span>
            <div className='grid grid-cols-4 gap-4'>
              <SelectColorItem
                color='#FE9F60'
                handleChangeColor={() => changeColor('#FE9F60')}
                formColorSelected={form.color}
              />
              <SelectColorItem
                color='#A7C694'
                handleChangeColor={() => changeColor('#A7C694')}
                formColorSelected={form.color}
              />
              <SelectColorItem
                color='#F9D864'
                handleChangeColor={() => changeColor('#F9D864')}
                formColorSelected={form.color}
              />
              <SelectColorItem
                color='#eb91bc'
                handleChangeColor={() => changeColor('#eb91bc')}
                formColorSelected={form.color}
              />
            </div>
          </div>
        </div>
        <TextField
          handler={handleFormChange}
          name='name'
          value={form.name}
          placeholder={t('myPetFormName')}
          fieldClassName='py-4'
          border='rounded-[20px]'
          messageError={validatorBody.name.message}
          required
        />
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <SelectField
            name='type'
            onChange={handleSelectChange}
            options={language === 'en' ? typesEn : types}
            value={form.type.value ? form.type : undefined}
            borderRadius={false}
            borderRadiuspx='20px'
            dropdownIndicatorColor='#33B5A9'
            placeholder={t('mypetFormSpecie')}
            paddingY='8px'
            className='lg:w-1/2'
            messageError={validatorBody.type.message}
          />
          <TextCalendarField
            nameField='date_birth'
            placeholder={t('myPetFormBorn')}
            setValue={handleChangeDate}
            textFielClassName='py-4'
            value={form.date_birth}
            messageError={validatorBody.date_birth.message}
            className='lg:w-1/2'
            limitToday={true}
            captionLayout='dropdown'
            required
          />
        </div>
        <div className='w-full flex flex-col gap-4 lg:flex-row lg:gap-3'>
          <TextField
            handler={handleFormChange}
            name='weight'
            value={form.weight === 0 ? '' : form.weight}
            placeholder={t('myPetFormWeight')}
            fieldClassName='py-4 input-hidden-spin'
            border='rounded-[20px]'
            className='lg:w-1/2'
            messageError={validatorBody.weight.message}
            type='number'
            min={0}
            required
          />
          <SelectField
            name='activity'
            onChange={handleSelectChange}
            options={language === 'es' ? activityList : activityListEnglish }
            value={form.activity.value ? form.activity : undefined}
            borderRadius={false}
            borderRadiuspx='20px'
            dropdownIndicatorColor='#33B5A9'
            placeholder={t('myPetFormActivity')}
            className='lg:w-1/2'
            paddingY='8px'
            messageError={validatorBody.activity.message}
          />
        </div>
        <label>{t('myPetFormAllergies')}</label>
        <div className='flex gap-5'>
          <RadioField
            label={t('myPetFormYes')}
            changeState={setAllergiesST}
            currentState={allergiesST}
            name='hasAllergies'
            handleRadioChange={handleRadioChange}
            value={true}
          />
          <RadioField
            label=' No'
            changeState={setAllergiesST}
            currentState={allergiesST}
            name='hasAllergies'
            handleRadioChange={handleRadioChange}
            value={false}
          />
        </div>
        {allergiesST && (
          <div className='flex md:gap-14 mb-10'>
            <div className='flex flex-col w-1/2 gap-2 lg:w-auto'>
              <CheckField
                onClick={() => handleSetDiseases('hepatics')}
                label={t('calcFormLabelHepatic')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1'
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('hepatics')}
              />
              <CheckField
                onClick={() => handleSetDiseases('renal')}
                label={t('calcFormLabelRenal')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 '
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('renal')}
              />
              <CheckField
                onClick={() => handleSetDiseases('obesity')}
                label={t('calcFormLabelObesity')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1'
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('obesity')}
              />
            </div>
            <div className='flex flex-col w-1/2 gap-2 lg:w-auto'>
              <CheckField
                onClick={() => handleSetDiseases('alergies')}
                label={t('calcFormLabelAlergy')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('alergies')}
              />
              <CheckField
                onClick={() => handleSetDiseases('sensitive_stomach')}
                label={t('calcFormLabelStomach')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('sensitive_stomach')}
              />
              <CheckField
                onClick={() => handleSetDiseases('other')}
                label={t('calcFormLabelOther')}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={form.diseases.includes('other')}
              />
            </div>
          </div>
        )}
        <div className='flex justify-center items-center gap-3 font-sanzBold my-6 lg:justify-end lg:mt-6 lg:mb-0 lg:text-base'>
          <Button
            className='border border-primary text-primary'
            padding='px-7 py-2 lg:py-1'
            onClick={handlerClosePopup}
          >
            {t('myPetFormCancel')}
          </Button>
          <Button
            className='bg-primary text-[#FAD7B1]'
            padding='px-12 py-2 lg:py-1'
            type='submit'
            disable={loading}
          >
            {loading ?
              <>
                {t('myPetFormLoading')}..
              </>
              :
              <>
                {pet ? t('myPetFormUpdate') : t('myPetFormCreate')}
              </>
            }
          </Button>
        </div>
      </form>
    </div>
  );
};

interface FormEditCreatePetsProps {
  pet?: Pet,
  setShowPopup: Dispatch<SetStateAction<boolean>>,
  reloadData: () => void,
}

/// color component
const SelectColorItem = ({ color, handleChangeColor, formColorSelected }: selectColorItemProps) => (
  <div
    className={`w-[48px] h-[48px] rounded-full cursor-pointer ${formColorSelected === color && 'border-2 border-pixieLightBlue'}`}
    style={{ backgroundColor: color }}
    onClick={handleChangeColor}
  >
  </div>
);

interface selectColorItemProps {
  color: string,
  handleChangeColor: () => void,
  formColorSelected: string
}

export default FormEditCreatePets;
