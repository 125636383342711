export const basket = {
  baskHeader: 'Basket',
  baskDescription: 'Check your Pixie basket. Selected products for the well-being of your pet. Ready to finish!',
  baskTitle: 'Your cart',
  baskCanonical: 'https://us.pixie.pet/canasta',
  baskResumeOrder: 'ORDER SUMMARY',
  baskResumeOrderSubscription: 'ORDER SUMMARY (SUBSCRIPTION)',
  baskTaxes: 'Taxes',
  baskShipping: 'Shipping',
  baskSubscription: 'Subscription discount',
  baskSubscriptionnNumber: 'Number of shipments',
  basketContinueShopping: 'Continue shopping',
  basketNextStep: 'Next',
  basketSteps1: 'Basket',
  basketSteps2: 'Information',
  basketSteps3: 'Shipment',
  basketSteps4: 'Payment',
  basketDiscount: 'Discount',
  basketDiscountReferred: 'Referral discount',
  basketUnity: 'unit',
  basketUnits: 'units',
  basketEmail: 'Enter email',
  basketTextTaxes: 'To calculate',
  basketValidateEmail: 'You must enter an email.',
  basketTotalText: 'Total estimated',
  basketTaxes: 'Taxes',
  basketRecommended: 'Your furry friend may love it too'
};
