import { colombiaFlag, mexicoFlag, usaFlag } from '../assets/vectors';
import { PixieContries } from '../helpers/pixieApp';

export const selectCountry: {
  name: PixieContries;
  icon: string;
  countryNumber: number;
}[] = [
  { name: 'MEX', icon: mexicoFlag, countryNumber: 1 },
  { name: 'USA', icon: usaFlag, countryNumber: 2 },
  { name: 'COL', icon: colombiaFlag, countryNumber: 3 },
  // { name: 'PAN', icon: panamaFlag, countryNumber: 4 }
];
