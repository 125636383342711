export const ItemOrderUser = ({ text, title }:itemOrderProps) => (
  <div>
    <label>{title}</label>
    <span className='font-subTitles'> {text}</span>
  </div>
);

interface itemOrderProps {
  title: string,
  text?: string | number
}
